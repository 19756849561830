import React, { useEffect, useState } from 'react';
import Loading from '../../icons/Loading';
import { ConfirmButton } from '../../util/ConfirmButton';
import { Input } from '../../util/Dropdown';
import getInfoFromServer, { postInfoToServer } from '../../util/util';

export default function UpdateContainers(props)
{
    const [loading, setLoading] = useState(false);
    const [lastModified, setLastModified] = useState(null);
    const [updateNewItems, setUpdateNewItems] = useState(false);

    useEffect(() => {
        const start = async () => {
            const response = await getInfoFromServer('edit/GetLastCDNUpdateDate');

            setLastModified(response.lastModified);
        }

        start();
    }, []);

    const onUpdate = async () => {
        setLoading(true);
        const response = await postInfoToServer('edit/UpdateCDN', { updateNewItems: updateNewItems });

        setLastModified(response.lastModified);
        setLoading(false);
    }

    const onCheck = (value) => {
        setUpdateNewItems(value);
    }

    if (loading) return <Loading />

    return <>
        <h3>Update CDN Containers</h3>
        <div>Last Modified: {lastModified}</div>
        <Input.div type="checkbox" onChange={onCheck} value={updateNewItems}>
            Update New Items:
        </Input.div>
        <ConfirmButton onConfirm={onUpdate} msg="Are you sure you want to update all the CDN containers?">Update</ConfirmButton>
    </>
}