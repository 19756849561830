import React from 'react';
import EmailLink from '../icons/EmailLink';
import { Note, Policy, Section } from './Terms';

export function ShippingPolicy(props) {
    return <div className="terms-page">
        <h3>Shipping Policy</h3>
        <Policy>
            <Section.Top>
                <p>
                    We make every effort to process and ship your order as quickly as possible.
                    Please understand we are humans working with human hands and human brains doing the best we can!
                </p>
            </Section.Top>
            <Section name="Shipping fee">
                <p>
                    For a limited time, shipping is FREE and only available in the United.
                    Please email for prices if you would like international
                    shipping <EmailLink/>
                </p>
            </Section>
            <Section name="Estimate time of arrival">
                <p>
                    We process orders on business days which are Monday through Friday, 9-5pm MST, except for holidays observed by the Post Office: New Year's Day, Presidents' Day, Memorial Day, Independence Day, Labor Day, Thanksgiving, and Christmas.
                </p>
                <p>
                    To estimate when you can expect your order, please refer to the time frames below:
                </p>
                <p>
                    * Digital Downloads- after purchase an instant download will be available for you to download and save to your computer. You will also receive the digital file via email within 2 business days.
                </p>
                <p>
                    * Paper Prints- 8x10 & 11x14:5-8 days, 16x20:7-12 days
                </p>
                <p>
                    * Canvas Prints- 7-21 days (larger canvases take the longest)
                </p>
                <Note>
                    <ul>
                        <li>In peak season or bad weather, your package may arrive later than the expected delivery times. We're so sorry for this inconvenience and hope that you can understand we have no control over this.</li>

                        <li>The above time frame is only applied for orders to the US Contiguous States.</li>
                    </ul>
                </Note>
            </Section>
            <Section name="Track order">
                <p>
                    You will receive a confirmation email with a tracking link so that you can follow your order all the way home! Please allow 5-7 days for the carrier to scan your package into their system.
                </p>
                <p>
                    If you attempt to track your package and there is no information available that just means the carrier has not processed your parcel yet. When the parcel is scanned into the system, tracking events will populate on the tracking page.
                </p>
                <Note>
                    <p>The tractor page updates in real-time as the status of the shipment changes. Possible statuses are:</p>

                    <ul>
                        <li>Confirmed - An order was created, but it has not been shipped or fulfilled yet.</li>

                        <li>On its way - The order was marked as fulfilled or partially fulfilled, and the shipment is in transit.</li>

                        <li>Out for delivery - The shipment arrived in your area and will be delivered to you soon.</li>

                        <li>Delivered - The order was marked as delivered by the carrier.</li>

                        <li>Attempted delivery - The carrier attempted to deliver the shipment to the destination address but was unsuccessful.</li>
                    </ul>
                </Note>
            </Section>
            <Section name="Change shipping address">
                <p>
                    You can change the shipping address of your order within 2 hours after placing the order.
                </p>
                <p>
                    To change the shipping address, please email the correct address to <EmailLink/>.
                </p>
                <p>
                    Note:
                    We are only able to make changes to your order if it has not yet entered the production queue.
                </p>
            </Section>
            <Section name="Report a delivery issue">
                <p>
                    If it is over your estimated arrival date or there is any shipping issue with your order, a request needs to be submitted within 20 days from the delivery date or 45 days from the order date to qualify for credit or replacement. Please contact us at <EmailLink/>.
                </p>
            </Section>
            <Section name="Incorrect address">
                <p>
                    Make sure you provide the correct address at checkout because we are not responsible if your order gets delivered to the wrong address.
                </p>
                <p>
                    For orders returned due to an incorrect address provided, we request a re-shipping fee to resend your order.
                    If you contact the final mile carrier after the order has left our warehouse and ask them to forward or redirect your parcel, we are not responsible if that parcel gets lost, stolen, or damaged.
                </p>
            </Section>
            <Section name="Refunds, returns, and exchanges">
                <p>
                    When receiving your package, if there is any issue such as damaged item or incorrect item, please send an email describing the issue and submit a photo of
                    the artwork to <EmailLink/>.
                </p>
                <p>
                    <em>* Please remember not to return the package in any case</em>
                </p>
            </Section>
            <Section name="Other shipping and delivery information">
                <p>
                    <div><em>* Shipping to APO/FPO/DPO Addresses</em></div>
                    <div>We are able to ship orders to APO/FPO/DPO Addresses.</div>
                </p>
                <p>
                    <div><em>* Shipping multiple orders</em></div>
                    <div>Multiple orders placed on the same day may not be combined.</div>
                </p>
                <p>
                    <div><em>* Shipping to multiple locations</em></div>
                    <div>Unfortunately, we cannot split up an order and ship to multiple locations. If you wish to send items to different locations, you will need to place a separate order for each shipping address.</div>
                </p>
                <p>
                    <div><em>* Shipping orders include multiple items</em></div>
                    <div>For logistical reasons, items in the same purchase will sometimes be sent in separate packages. There’s no extra charge for this.</div>
                </p>
                <p>
                    <div><em>* Where do we ship your order from?</em></div>
                    <div>We ship all orders from our factory partners in the US.</div>
                </p>
            </Section>
        </Policy>
    </div>
}