import React, { useEffect, useState, useRef } from 'react';
import { EditImageItems, EditImageType, SelectImage } from './EditPerson';
import { EditSourceRect } from './EditSourceRect';
import { Dropdown, DragAndDrop, DragAndDropButton, DropdownDropdown, DropdownButton, Input } from '../../util/Dropdown';
import { NameText } from '../../util/NameText';
import { Button, ToggleButton } from '../../util/ToggleButton';
import getInfoFromServer, { Copy, isEqual, parseNumber, postInfoToServer } from '../../util/util';
import RenderPopup from '../../popup/Popup';
import AlertBox from '../../popup/AlertBox';
import { ImageFile } from '../../util/ImageFile';
import { ConfirmButton } from '../../util/ConfirmButton';
import Loading from '../../icons/Loading';
import { EditCondition } from '../../EditCondition';
import { Container } from './Edit';

export function EditProduct(props)
{
    const NewEdit = useRef(null);
    const [loading, setLoading] = useState(true);
    const [wasDragged, setWasDragged] = useState(false);

    const [products, setProducts] = useState([]);
    const [productKey, setProductKey] = useState(-1);

    const [productItems, setProductItems] = useState([]);

    useEffect(() => {
        const initialize = async () => {
            const response = await getInfoFromServer('edit/Start/Product');

            setProducts(response.products);
            setProductItems(products.map(x => x.name));
            updateItems(response.products);

            setLoading(false);
        }

        initialize();
    }, []);

    const updateItems = (products) => {
        setProductItems(products.map(x => x.name));        
    }
    
    const onProductChange = (key) => {
        setProductKey(key);
    }

    const onNameBlur = (name) =>
    {
        const temp = Copy(products);

        temp[productKey].name = name;

        setProducts(temp);
        setProductItems(temp.map(x => x.name));
    }

    const onDescriptionBlur = (description) => {
        const temp = Copy(products);

        temp[productKey].description = description;

        setProducts(temp);
    }

    const onSubdescriptionBlur = (subdescription) => {
        const temp = Copy(products);

        temp[productKey].subdescription = subdescription;

        setProducts(temp);
    }
    
    const handleImageFile = (src) => {
        const temp = Copy(products);

        temp[productKey].thumbnail = src;

        setProducts(temp);
    }

    const onWidthChange = (value) => {
        const temp = Copy(products);

        temp[productKey].size.width = value;

        setProducts(temp);
    }

    const onHeightChange = (value) => {
        const temp = Copy(products);

        temp[productKey].size.height = value;

        setProducts(temp);
    }

    const onDragChange = (items, newKey, newIndex) => {
        setProducts(items);
        setProductKey(newIndex);
        setWasDragged(true);
        updateItems(items);
    }

    const handlePriceChange = (items) => {
        const temp = Copy(products);
        temp[productKey].materials = items;

        setProducts(temp);
    }

    const onConditionChange = (items) => {
        const temp = Copy(products);

        temp[productKey].conditions = items;

        setProducts(temp);

        return temp[productKey];
    }

    const updateConditions = (temp) => {
        const personIdIndex = 0;
        const numPeopleIndex = 1;
        const personTypeIndex = 2;
        const xIndex = 6;
        const yIndex = 7;
        
        for (var i = 0; i < temp.conditions.length; i++) {
            const condition = temp.conditions[i];
            for (var j = 0; j < condition.propertiesToChange.length; j++) {
                const property = condition.propertiesToChange[j];
                const index = property.propertyStack.findIndex(x => x === "SetSpecialCase");

                if (index > -1) {
                    const toggleId = parseNumber(property.propertyStack[1]);
                    const numPeople = property.values[numPeopleIndex];

                    const person = temp.toggleItems[toggleId].allPeople[numPeople][parseInt(property.values[personIdIndex])].possiblePersonTypes.find(x => x.personType === property.values[personTypeIndex]);

                    const xDif = person.location.x - parseInt(property.defaultValue[xIndex]);
                    const yDif = person.location.y - parseInt(property.defaultValue[yIndex]);

                    property.defaultValue[xIndex] = person.location.x.toString();
                    property.defaultValue[yIndex] = person.location.y.toString();

                    property.values[xIndex] = (xDif + parseInt(property.values[xIndex])).toString();
                    property.values[yIndex] = (yDif + parseInt(property.values[yIndex])).toString();
                }
            }
        }

        return temp.conditions;
    }

    const onToggleItemChange = (items) => {
        const temp = Copy(products);
        temp[productKey].toggleItems = items;

        temp[productKey].conditions = updateConditions(temp[productKey]);

        setProducts(temp);
    }

    const onToggleClick = (mode) => {
        if (mode === "new") {
            const newProduct = {
                name: "New Product", description: "", subDescription: "", materials: products[0].materials,
                thumbnail: "", key: products.length + 1, size: { width: 2400, height: 3000 }, conditions: [],
                toggleItems: []
            }

            const temp = Copy(products);
            temp.push(newProduct);
            setProducts(temp);
            setProductKey(temp.length - 1);
        }
    }

    const post = (value, isDebug, personType, personKey, numPeople, toggleId, conditions) =>
    {
        const temp = Copy(product);
        temp.toggleItems = value;
        if (conditions) {
            temp.conditions = conditions;
        }

        temp.conditions = updateConditions(temp);

        return postInfoToServer('edit/PersonLocation', {
            isDebug: isDebug, productItem: temp,
            personType: personType, personKey: personKey, toggleId: toggleId,
            numPeople: numPeople
        });
    }

    const onDelete = async (e) => {
        NewEdit.current.reset();
        setLoading(true);

        const response = await postInfoToServer('edit/Delete/Product', {
            key: products[productKey].key
        });

        setProductKey(-1);
        setProducts(response.products);
        updateItems(response.products);
        setLoading(false);
    }

    const onSubmit = async (e) =>
    {
        NewEdit.current.reset();
        setLoading(true);

        //If we changed the order of the products or materials, we need to submit all of them
        const _products = wasDragged ? products : [products[productKey]];

        const response = await postInfoToServer('edit/Submit/Product', {
            products: _products, productKey: products[productKey].key
        });

        setWasDragged(false);
        setProducts(response.products);
        setLoading(false);
    }

    const onOverwrite = (materials) => {
        const temp = Copy(products);

        for (var i = 0; i < products.length; i++) {
            temp[i].materials = materials;
        }

        setProducts(temp);
        setWasDragged(true);
    }
    
    const product = productKey > -1 ? products[productKey] : null;

    const name = productKey > -1 ? product.name : "";
    const description = productKey > -1 ? product.description : "";
    const subdescription = productKey > -1 ? product.subdescription : "";
    const imageUrl = productKey > -1 ? product.thumbnail : "";
    const width = productKey > -1 ? product.size.width : 0;
    const height = productKey > -1 ? product.size.height : 0;
    const toggleItems = productKey > -1 ? product.toggleItems : []

    const prices = productKey > -1 ? product.materials : [];

    if (loading) return <Loading/>

    return <>
        <h5>Edit Product Item</h5>
        <ToggleButton ref={NewEdit} onClick={onToggleClick}>
            <Button id='new' name="New" />
            <Button id='edit' name="Edit">
                <Dropdown value={productKey} items={productItems} onChange={onProductChange}>
                    Select Product
                </Dropdown>
            </Button>
            {productKey > -1 && <Container>
                <NameText name='Name' value={name} onBlur={onNameBlur} />
                <Input.div type='textbox' value={description} onBlur={onDescriptionBlur}>
                    <span className="item-label">Description</span>
                </Input.div>
                <Input.div type='textbox' value={subdescription} onBlur={onSubdescriptionBlur}>
                    <span className="item-label">Subdescription</span>
                </Input.div>
                <ImageFile value={imageUrl} onChange={handleImageFile}>
                    Thumbnail
                </ImageFile>
                <Input.div type='number' value={width} onChange={onWidthChange}>
                    Canvas Width
                </Input.div>
                <Input.div type='number' value={height} onChange={onHeightChange}>
                    Canvas Height
                </Input.div>
                <DragAndDrop label="Product Layer" items={products} value={product.key} onChange={onDragChange} onClick={onProductChange} />
                <EditMaterial items={prices} onChange={handlePriceChange} onOverwrite={onOverwrite} />
                <EditCondition items={product.conditions} productItem={product} onChange={onConditionChange} post={post} />
                <EditToggleItems items={toggleItems} onChange={onToggleItemChange} post={post} />
                <ConfirmButton onConfirm={onDelete} msg="Are you sure you want to delete this product?">Delete</ConfirmButton>
                <ConfirmButton onConfirm={onSubmit} msg="Are you sure you want to submit this product?">Submit</ConfirmButton>
            </Container>}            
        </ToggleButton>
        </>
}

function EditToggleItems(props)
{
    const [index, setIndex] = useState(-1);

    const onToggleSelect = (value) => {
        setIndex(value);
    }

    const onToggleButtonClick = (mode) =>
    {
        if (mode === "new")
        {
            const temp = Copy(props.items);
            const newToggle = {
                name: "New Toggle Item", orderKey: props.items.length > 0 ? props.items[props.items.length - 1].orderKey + 1 : 0,
                allPeople: {}
            }

            temp.push(newToggle);

            props.onChange(temp);
            setIndex(temp.length - 1);
        }
    }

    const onNameBlur = (value) => {
        const temp = Copy(props.items);
        temp[index].name = value;

        props.onChange(temp);
    }

    const onLayerChange = (items, newKey, newIndex) => {
        props.onChange(items);
        setIndex(newIndex);
    }

    const onPossiblePeopleChange = (items) => {
        const temp = Copy(props.items);
        temp[index].allPeople = items;

        props.onChange(temp);
    }

    
    const post = (value, isDebug, personType, personKey, numPeople) => {
        const temp = Copy(props.items);
        temp[index].allPeople = value;

        return props.post(temp, isDebug, personType, personKey, numPeople, index);
    }

    const curr = index > -1 ? props.items[index] : {};

    return <Container name="Edit Toggle Items">        
            <ToggleButton onClick={onToggleButtonClick}>
                <Button id='new' name="New" />
                <Button id='edit' name="Edit">
                    <Dropdown items={props.items.map(x => x.name)} value={index} onChange={onToggleSelect}>
                        Select Toggle Item:
                    </Dropdown>
                </Button>
                {index > -1 && <>
                    <NameText name="Name" value={curr.name} onBlur={onNameBlur} />
                    <DragAndDrop label="Toggle Layer" items={props.items} value={curr.orderKey} _key="orderKey" onChange={onLayerChange} onClick={onToggleSelect} />
                    <EditPossiblePeople items={curr.allPeople} onChange={onPossiblePeopleChange} post={post} />
                    </>}
            </ToggleButton>
        </Container>
}

function EditPossiblePeople(props) {
    const [selectedKey, setSelectedKey] = useState(-1);

    useEffect(() => {
        const keys = Object.keys(props.items);
        if (selectedKey > keys.length - 1) {
            setSelectedKey(keys.length - 1);
        }        
    }, [props.items]);

    const getMaxKey = (items, name) => {
        return items.sort((a, b) => b[name] - a[name])[0][name];
    }

    const onToggleClick = (mode) => {
        if (mode === 'new') {
            let temp = Copy(props.items);
            const keys = Object.keys(temp);

            if (keys.length > 0) {
                const newNumber = Number(keys[keys.length - 1]) + 1;

                const newValue = Copy(temp[newNumber - 1]);
                const addItem = Copy(newValue[newValue.length - 1]);

                //Get the max order key and key and make this new one one more
                addItem.orderKey = getMaxKey(newValue, 'orderKey') + 1;
                addItem.key = getMaxKey(newValue, 'key') + 1;

                newValue.push(addItem);

                temp = { ...temp, [newNumber]: newValue }
            }
            else {
                temp = { 1: [{ name: "New Person", possiblePersonTypes: [], orderKey: 0, key: 0 }] }
            }


            props.onChange(temp);
            setSelectedKey(Object.keys(temp).length - 1);
        }
    }

    const onSelect = (key) => {
        setSelectedKey(key);
    }

    const onPositionBlur = (num) =>
    {
        if (props.items[num] !== undefined) return;
        let temp = Copy(props.items);

        const curr = temp[selectItems[selectedKey]];
        const length = curr.length;

        //If we are going down, delete some items
        if (length > num)
        {
            for (var i = 0; i < length - num; i++) {
                curr.splice(curr.length - 1, 1);
            }
        }
        //If we are going up, add some items
        else if (length < num) {
            for (var i = 0; i < num - length; i++) {
                const next = Copy(curr[curr.length - 1]);
                next.orderKey = getMaxKey(curr, 'orderKey') + 1;
                next.key = getMaxKey(curr, 'key') + 1;

                curr.push(next);
            }
        }

        delete temp[selectItems[selectedKey]];

        

        temp = { ...temp, [num]: curr };

        props.onChange(temp);
        setSelectedKey(Object.keys(temp).findIndex(x => num === Number(x)));
    }

    const onPersonItemChange = (items) => {
        const temp = Copy(props.items);

        temp[positionIndex] = items;

        props.onChange(temp);
    }

    const onEditLocation = (e) => {

        const value = {
            sourceBox: { width: 1, height: 1, x: 0, y: 0 }
        }

        RenderPopup(<EditSourceRect onChange={onLocationSubmit} value={value} post={postAll} dismisable float/>);
    }

    const onLocationSubmit = (value) => {
        const temp = Copy(props.items);
        const people = temp[positionIndex];
        for (var i = 0; i < people.length; i++) {
            for (var j = 0; j < people[i].possiblePersonTypes.length; j++) {
                const personType = people[i].possiblePersonTypes[j];

                personType.growX = personType.growX * value.sourceBox.width;
                personType.growY = personType.growY * value.sourceBox.height;
                personType.location.width = Math.round(personType.location.width * value.sourceBox.width);
                personType.location.height = Math.round(personType.location.height * value.sourceBox.height);
                personType.location.x = Math.round(personType.location.x * value.sourceBox.width);
                personType.location.y = Math.round(personType.location.y * value.sourceBox.height);
                personType.location.x += value.sourceBox.x;
                personType.location.y += value.sourceBox.y;
            }
        }

        props.onChange(temp);
    }

    const onDelete = (e) =>
    {
        const temp = Copy(props.items);
        const keys = Object.keys(props.items);

        //Only delete an end point
        if (positionIndex == keys[0] || positionIndex == keys[keys.length - 1]) {
            delete temp[positionIndex];

            props.onChange(temp);
        }
        else {
            RenderPopup(<AlertBox dismisable>You can only delete the lowest or highest number of people</AlertBox>);
        }
    }

    const postAll = (value, isDebug) => {
        const temp = Copy(props.items);
        const people = temp[positionIndex];
        for (var i = 0; i < people.length; i++) {
            for (var j = 0; j < people[i].possiblePersonTypes.length; j++) {
                const personType = people[i].possiblePersonTypes[j];

                personType.growX = personType.growX * value.sourceBox.width;
                personType.growY = personType.growY * value.sourceBox.height;
                personType.location.width = Math.round(personType.location.width * value.sourceBox.width);
                personType.location.height = Math.round(personType.location.height * value.sourceBox.height);
                personType.location.x = Math.round(personType.location.x * value.sourceBox.width);
                personType.location.y = Math.round(personType.location.y * value.sourceBox.height);
                personType.location.x += value.sourceBox.x;
                personType.location.y += value.sourceBox.y;
            }
        }

        return props.post(temp, isDebug, "", -1, positionIndex);
    }


    const post = (value, isDebug, personType, personKey) => {
        const temp = Copy(props.items);
        temp[positionIndex] = value;
        return props.post(temp, isDebug, personType, personKey, positionIndex);
    }
    
    if (selectedKey > Object.keys(props.items).length - 1) {
        return <></>
    }

    const selectItems = Object.keys(props.items).map(x => Number(x));
    const positionIndex = selectedKey > -1 ? selectItems[selectedKey] : -1;
    const min = selectedKey > -1 && selectItems.length > 0 && selectItems[0] > 1 ? (selectItems.length === 1 ? 1 : (selectedKey > 0 ? selectItems[0] : positionIndex + 1) - 1) : positionIndex;
    const max = selectedKey > -1 && selectItems.length > 0 ? (selectItems.length === 1 ? 8 : (selectedKey < selectItems.length - 1 ? selectItems[selectItems.length - 1] : positionIndex - 1) + 1) : 1;
    const curr = selectedKey > -1 ? props.items[positionIndex] : [];
    //curr.sort((a, b) => a.orderKey - b.orderKey);

    return <Container name="Edit Possible People">
            <ToggleButton onClick={onToggleClick}>
                <Button id='new' name="New">
                
                </Button>
                <Button id='edit' name="Edit">
                    <Dropdown value={selectedKey} items={selectItems} onChange={onSelect}>
                        Select Number of People
                    </Dropdown>
                </Button>
                {selectedKey > -1 && <><Input type="number" from={min} to={max} value={positionIndex} onBlur={onPositionBlur}>
                    Position Index
                </Input>
                    <div>
                        <button onClick={onEditLocation}>Edit Location</button>
                        <button onClick={onDelete}>Delete</button>
                    </div>
                    <EditTogglePersonItem items={curr} onChange={onPersonItemChange} post={post} />
                        </>}
            </ToggleButton>
        </Container>
}

function EditTogglePersonItem(props)
{
    const [index, setIndex] = useState(-1);

    useEffect(() => {
        if (index > props.items.length - 1) {
            setIndex(props.items.length - 1);
        }
    }, [props.items]);

    const onSelect = (value) => {
        setIndex(value);
    }

    const onDragSelect = (value, key) => {
        //the drag selects return the key or order key depending on the drag
        onSelect(props.items.findIndex(x => x[key] === value))
    }

    const onNameChange = (value) => {
        const temp = Copy(props.items);
        temp[index].name = value;
        props.onChange(temp);
    }

    const onMergeLayerChange = (items, newKey, newIndex) => {
        setIndex(newIndex);
        props.onChange(items);
    }

    const onOrderLayerChange = (items, newKey, newIndex) => {
        setIndex(newIndex);
        props.onChange(items);
    }

    const onPersonTypeChange = (items) => {
        const temp = Copy(props.items);
        temp[index].possiblePersonTypes = items;
        props.onChange(temp);
    }

    const post = (value, isDebug, personType) => {
        const temp = Copy(props.items);
        temp[index].possiblePersonTypes = value;
        const personKey = temp[index].orderKey;

        return props.post(temp, isDebug, personType, personKey);
    }

    if (index > props.items.length - 1) {
        return <></>
    }

    const curr = index > -1 ? props.items[index] : {};

    return <Container name="Edit Person">
        <Dropdown items={props.items.map(x => x.name)} value={index} onChange={onSelect}>
            Select Person Item: 
        </Dropdown>
        {index > -1 && <>
            <NameText name="Name" value={curr.name} onBlur={onNameChange} />
            <DragAndDrop label="Person Merge Layer" items={props.items} value={curr.orderKey} _key="orderKey" onChange={onMergeLayerChange} onClick={onDragSelect} />
            <DragAndDrop label="Person Order Layer" items={props.items} value={curr.key} _key="key" onChange={onOrderLayerChange} onClick={onDragSelect} />
            <EditPersonType items={curr.possiblePersonTypes} onChange={onPersonTypeChange} post={post} />
        </>}
    </Container>
}

function EditPersonType(props)
{
    const [index, setIndex] = useState(-1);
    const [people, setPeople] = useState([]);
    //const [conditions, setConditions] = useState([{ name: "Special Arm", properties: [{ propertyStack: ["CustomizeItem", "ToggleOptions[1]", "NumPeople"], value: "4" }, { propertyStack: ["CustomizeItem", "ToggleOptions[0]"], value: "2" }], operators: [{ operator: "And" }] }]);

    useEffect(() => {
        const initialize = async () => {
            const response = await getInfoFromServer('edit/Start/Person');

            setPeople(response.people);
        }

        initialize();
    }, []);

    useEffect(() => {
        if (index > props.items.length - 1) {
            setIndex(props.items.length - 1);
        }
    }, [props.items]);

    const onSelect = (value) => {
        setIndex(value);
    }

    const onPersonSelect = (e) => {
        const id = Number(e.target.id);
        const temp = Copy(props.items);
        temp[index].personId = id;
        temp[index].possibleSelection = people.find(x => x.id === id).allItems.map(x => x.imageId);

        const size = people.find(x => x.id === id).sourceBox;
        temp[index].location.width = size.width;
        temp[index].location.height = size.height;
        temp[index].mergedLast = [];
        temp[index].imageTypes = [];
        temp[index].specialCases = [];
 
        props.onChange(temp);
    }

    const onPersonTypeChange = (value) => {
        const temp = Copy(props.items);
        temp[index].personType = value;

        props.onChange(temp);
    }

    const onImageSelect = (e) => {
        const id = Number(e.target.id);
        const temp = Copy(props.items);

        if (e.target.checked) {
            temp[index].possibleSelection.push(id);
        }
        else {
            temp[index].possibleSelection.splice(temp[index].possibleSelection.findIndex(x => x === id), 1);
        }
        

        props.onChange(temp);
    }

    const onToggleClick = (mode) => {
        if (mode === 'new')
        {
            const temp = Copy(props.items);
            let newPersonType = {}

            if (props.items.length > 0) {
                newPersonType = props.items[props.items.length - 1];
                newPersonType.key += 1;
                newPersonType.orderKey += 1;
            }
            else {
                const person = people.find(x => x.id === 1);

                newPersonType = {
                    personId: 1, personType: "Daughter", possibleSelection: people.find(x => x.id === 1).allItems.map(x => x.imageId),
                    mergedLast: [], specialCases: [], canOmit: false,
                    imageTypes: [], location: { x: 0, y: 0, width: person.sourceBox.width, height: person.sourceBox.height }, flipX: false, flipY: false, rotationAngle: 0,
                    key: 0, orderKey: 0, growX: 1, growY: 1
                }
            }

            temp.push(newPersonType);

            props.onChange(temp);
            setIndex(temp.length - 1);
        }
    }

    
    const onMergedLastChange = (items) => {
        const temp = Copy(props.items);

        temp[index].mergedLast = items;

        props.onChange(temp);
    }

    const onImageTypeChange = (items, _index) =>
    {
        const temp = Copy(props.items);

        //If we are deleting this type
        if (_index === -1) {
            //loop through the special cases and find the one that has been deleted (ind === -1)
            for (var i = 0; i < temp[index].specialCases.length; i++) {
                const ind = items.findIndex(x => x.key === temp[index].specialCases[i].key);

                if (ind === -1) {
                    temp[index].specialCases.splice(i, 1);
                    break;
                }
            }
        }
        else
        {
            const typeIndex = temp[index].specialCases.findIndex(x => x.key === items[_index].key);
            if (typeIndex !== -1) {
                temp[index].specialCases[typeIndex] = items[_index];
            }
            else {
                temp[index].specialCases.push(items[_index]);
            }
        }

        props.onChange(temp);
    }

    const postType = (items, isDebug) => {
        const temp = Copy(props.items);

        temp[index].specialCases = items;

        return props.post(temp, isDebug, temp[index].personType);
    }

    const onDelete = (e) => {
        if (props.items.length > 1) {
            const temp = Copy(props.items);
            temp.splice(index, 1);

            props.onChange(temp);
            setIndex(-1);
        }
    }

    if (index > props.items.length - 1) return <></>

    const curr = index > -1 ? props.items[index] : {}
    const person = index > -1 ? people.find(x => x.id === curr.personId) : {}
    const allImages = index > -1 ? person.allItems.map(x => { return { id: x.imageId, name: `${x.name}-${x.imageId}` } }) : [];
    const imageTypes = index > -1 ? person.imageTypes.map(x => curr.specialCases.findIndex(y => x.key === y.key) !== -1 ? curr.specialCases.find(y => x.key === y.key) : x) : {};
    return <Container name="Edit Person Type">
        <ToggleButton onClick={onToggleClick}>
            <Button id='new' name="New"/>
            <Button id='edit' name="Edit">
                <Dropdown value={index} items={props.items.map(x => x.personType)} onChange={onSelect}>
                    Select Person Type Item
                </Dropdown>
            </Button>
            {index > -1 && <><NameText name="Person Type" value={curr.personType} onBlur={onPersonTypeChange} />
                <EditPersonLocation items={props.items} index={index} onChange={props.onChange} post={props.post}/>
                <EditMergedLast items={curr.mergedLast} allItems={allImages.map(x => { return { id: x.id, name: x.name } })} onChange={onMergedLastChange} />
                <EditImageType items={imageTypes} onChange={onImageTypeChange} post={postType} />
                <SelectImage title="Select Person" items={people.map(x => { return { id: x.id, name: x.name } })} currAllImageKeys={[curr.personId]} onChange={onPersonSelect}/>
               
                <SelectImage title="Select Image" items={allImages} currAllImageKeys={curr.possibleSelection} onChange={onImageSelect}/>                
                <button onClick={onDelete}>Delete</button>
            </>}
        </ToggleButton>
    </Container>
}

export function EditPersonLocation(props) {

    const post = (value, isDebug) => {
        const index = props.index;
        const temp = Copy(props.items);
        temp[index].location.x = value.sourceBox.x;
        temp[index].location.y = value.sourceBox.y;
        temp[index].growX = value.sourceBox.width;
        temp[index].growY = value.sourceBox.height;

        const changeX = temp[index].growX / props.items[index].growX;
        const changeY = temp[index].growY / props.items[index].growY;

        temp[index].location.width *= changeX;
        temp[index].location.height *= changeY;

        temp[index].flipX = value.flipX;
        temp[index].flipY = value.flipY;
        temp[index].rotationAngle = value.rotationAngle;

        return props.post(temp, isDebug, temp[index].personType);
    }

    const onLocationSubmit = (value) => {
        const index = props.index;
        const temp = Copy(props.items);
        temp[index].location.x = value.sourceBox.x;
        temp[index].location.y = value.sourceBox.y;
        temp[index].growX = value.sourceBox.width;
        temp[index].growY = value.sourceBox.height;

        const changeX = temp[index].growX / props.items[index].growX;
        const changeY = temp[index].growY / props.items[index].growY;

        temp[index].location.width *= changeX;
        temp[index].location.height *= changeY;

        temp[index].flipX = value.flipX;
        temp[index].flipY = value.flipY;
        temp[index].rotationAngle = value.rotationAngle;

        props.onChange(temp);
    }

    const onEditLocation = (e) => {
        const sourceBox = { width: curr.growX, height: curr.growY, x: curr.location.x, y: curr.location.y }
        const value = {
            sourceBox: sourceBox, flipX: curr.flipX, flipY: curr.flipY, rotationAngle: curr.rotationAngle
        }

        RenderPopup(<EditSourceRect onChange={onLocationSubmit} value={value} post={post} dismisable />);
    }

    const curr = props.index > -1 ? props.items[props.index] : {};

    return <button onClick={onEditLocation}>Edit Location</button>
}

function EditMergedLast(props) {
    const onNew = (value) => {
        const temp = Copy(props.items);

        const newItem = props.allItems.find(x => x.name === value);

        if (!newItem) {
            throw new Error("Cannot find the merged last image with name " + value);
        }

        temp.push(newItem.id);

        props.onChange(temp);
    }

    const onDelete = (value) => {
        const temp = Copy(props.items);

        const newItem = props.allItems.find(x => x.name === value);
        if (!newItem) {
            throw new Error("Cannot find the merged last image with name " + value);
        }

        const indexToDelete = props.items.findIndex(x => x === newItem.id);
        if (indexToDelete < -1) {
            throw new Error("Cannot delete the merged last image " + value);
        }

        temp.splice(indexToDelete, 1);

        props.onChange(temp);
    }

    const itemNames = props.items.map(x => {
        const item = props.allItems.find(y => x === y.id);

        if (!item) {
            throw new Error("Cannot map merged last list");
        }

        return item.name;
    });

    return <div>
        <DropdownDropdown items={itemNames} allItems={props.allItems.map(x => x.name)} onNew={onNew} onDelete={onDelete}>
            Select Merged Last:
        </DropdownDropdown>
        </div>
}

function EditMaterial(props)
{
    const [material, setMaterial] = useState(-1);
    const [size, setSize] = useState(-1);
    const [price, setPrice] = useState(0);

    const materials = Object.getOwnPropertyNames(props.items);
    const sizes = material !== -1 && materials.includes(material) ? Object.getOwnPropertyNames(props.items[material]) : [];

    useEffect(() => {
        if (!materials.includes(material)) {
            setMaterial(-1);
        }
        if (!sizes.includes(size)) {
            setSize(-1);
        }
    }, [props.items]);

    const onMaterialChange = (key, items) =>
    {
        const material = key === -1 ? key : items !== undefined ? items[key] : materials[key];

        if (items !== undefined)
        {
            let _prices = {}

            for (var i = 0; i < items.length; i++)
            {
                //If we are editing, we want to grab the curr size
                //If we are adding or deleting, we want to make a new size
                const currMaterial = items.length === materials.length && !materials.includes(material) ? materials[i] : items[i];

                const sizes = Object.getOwnPropertyNames(props.items).findIndex(x => x === currMaterial) !== -1 ?
                    props.items[currMaterial] : { None: 0 }
                _prices = { ..._prices, [items[i]]: sizes }
            }
            
            props.onChange(_prices);
        }

        setMaterial(material);
        setSize(-1);
    }

    const onSizeChange = (key, items) =>
    {
        if (material === -1) return;

        const size = key === -1 ? key : items !== undefined ? items[key] : sizes[key];
        const temp = Copy(props.items);

        if (items !== undefined) {
            

            let _sizes = {}

            //Builds the sizes dictionary
            for (var i = 0; i < items.length; i++)
            {
                //If we are editing, we want to grab the curr price
                //If we are adding or deleting, we want to make a new price
                const currMaterial = items.length === sizes.length && !sizes.includes(size) ? sizes[i] : items[i];

                //Gets the price 
                const price = Object.getOwnPropertyNames(props.items[material]).findIndex(x => x === currMaterial) !== -1 ?
                    props.items[material][currMaterial] : 0;

                _sizes = { ..._sizes, [items[i]]: price }
            }
            
            temp[material] = _sizes;

            props.onChange(temp);
        }

        setSize(size);
        setPrice(temp[material][size]);
    }

    const onPriceBlur = (price) =>
    {
        const temp = Copy(props.items);

        temp[material][size] = price;

        props.onChange(temp);
        setPrice(price);
    }

    const onOverwrite = (e) => {
        props.onOverwrite(props.items);
        RenderPopup(<AlertBox dismisable>Applied</AlertBox>);
    }

    const materialValue = materials.findIndex(x => x === material);
    const sizeValue = sizes.findIndex(x => x === size);
    return <>
        <h5>Edit Materials and Prices</h5>
        <ConfirmButton onConfirm={onOverwrite} msg="Are you sure you want to apply these materials to all of the products?">
            Apply to All Products
        </ConfirmButton>
        <DragButton items={materials} value={materialValue} onChange={onMaterialChange}>
            Material
                </DragButton>
        {material !== -1 && <>
            <DragButton items={sizes} value={sizeValue} onChange={onSizeChange}>
                Size
                    </DragButton>
            {size !== -1 && <div>
                <Input type='number' value={price} onBlur={onPriceBlur}>
                    Price
                        </Input></div>}</>}

                                    
        </>
}

function DragButton(props) {
    const onChange = (items, newKey, newIndex) => {
        const _items = items.map(x => x.name);
        props.onChange(newIndex, _items);
    }

    const onEdit = (items, newKey, newIndex) => {
        onChange(items, newKey, newIndex);
    }

    const onNew = (newItem) => {
        const _items = Copy(items);
        _items.push(newItem);
        onChange(_items, -1, _items.length - 1);
    }

    const onDelete = (items, index, val) => {

        onChange(items, -1, -1);
    }

    const items = props.items.map((x, i) => { return { name: x, key: i } });
    return <DragAndDropButton items={items} value={props.value} onChange={onChange} onEdit={onEdit} onDelete={onDelete} onNew={onNew}>
        {props.children}
    </DragAndDropButton>
}