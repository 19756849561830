import React, { useState } from 'react';
import { Dropdown } from './util/Dropdown';

export function CustomFunctions(props) {
    const [allFunctions, setAllFunctions] = useState([]);
    const [allConditions, setAllConditions] = useState([]);

    const addFunction = (e) => {
        const temp = [...allFunctions];

        temp.push(<CustomFunction />);

        setAllFunctions(temp);
    }

    const addCondition = (e) => {
        const temp = [...allConditions];

        temp.push(<CustomCondition objects={props.objects} onChange={props.onChange}/>);

        setAllConditions(temp);
    }

    return <div>
        <div>
        {allFunctions}
        <button onClick={addFunction}>Add Function</button>
        </div>

        <div>
            {allConditions}
            <button onClick={addCondition}>Add Condition</button>
        </div>
    </div>
}


function CustomFunction(props) {
    const functions = ["Dynamic Image"];

    return <div>
        Hello World
        </div>
}

function CustomCondition(props) {
    const [indexCondition, setIndexCondition] = useState(-1);
    const [indexObject, setIndexObject] = useState(-1);
    const [indexProperty, setIndexProperty] = useState(-1);
    const [indexValue, setIndexValue] = useState(-1);


    const onConditionSelect = (value) => {
        setIndexCondition(value);

        if (indexValue > -1)
            props.onChange(conditions[value], indexObject, indexProperty, indexValue);
    }

    const onObjectSelect = (value) => {
        setIndexObject(value);

        if (indexValue > -1)
            props.onChange(conditions[indexCondition], value, indexProperty, indexValue);
    }

    const onPropertyChange = (value) => {
        setIndexProperty(value);

        if (indexValue > -1)
            props.onChange(conditions[indexCondition], indexObject, value, indexValue);
    }

    const onValueChange = (value) => {
        setIndexValue(value);

        props.onChange(conditions[indexCondition], indexObject, indexProperty, value);
    }

    const conditions = ["Show"];

    const properties = indexObject > -1 ? props.objects[indexObject].properties.map(x => x.name) : []
    const values = indexProperty > -1 ? properties[indexProperty].values : []

    return <div>
        <Dropdown items={conditions} value={indexCondition} onChange={onConditionSelect}>
            Select Condition:
        </Dropdown>
        When
        {indexCondition > -1 && <><Dropdown items={props.objects.map(x => x.name)} value={indexObject} onChange={onObjectSelect} />
            {indexObject > -1 && <><Dropdown items={properties} value={indexProperty} onChange={onPropertyChange} />
        Equals
        {indexProperty > -1 && <Dropdown items={values} value={indexValue} onChange={onValueChange} />}</>}</>}
        </div>
}