import React, { useState } from 'react';

export function ImageFile(props)
{
    const disabled = (props.value === "") ? " disabled" : "";
    const [files, setFiles] = useState([]);

    const getBase64 = (file) =>
    {
        return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
                resolve(e.target.result);
        }
            reader.onerror = reject;

        reader.readAsDataURL(file);
        });
    }

    const handleFile = async (e) =>
    {
        const _files = e.target.files;
        const temp = [];
        const id = !isNaN(props.id) ? Number(props.id) : props.id;

        for (var i = 0; i < _files.length; i++)
        {
            await getBase64(_files[i]).then((result) =>
            {
                temp.push({ name: _files[i].name, image: result });
            });
        }

        if (props.multiple)
            props.onChange(temp, id);
        else
            props.onChange(temp[0].image, temp[0].name, id);
    }


    return <>
        <label>
            {props.children}
            <input type="file" onChange={handleFile} accept={props.accept} multiple={props.multiple} />
        </label>
        {props.value && (props.accept.includes('image') || props.accept.includes('.png') || props.accept.includes('.jpg') ?
            <img className={"" + disabled} src={props.value} width="100px" height="100px" /> : <p style={{ fontFamily: props.value }}>A B C D a b c d</p>) }

    </>
}

ImageFile.defaultProps = {
    multiple: false, accept: "image/png"
}
