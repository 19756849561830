import React, { useEffect, useState, useRef } from 'react'
import Loading from '../../icons/Loading';
import { DragAndDrop, Dropdown } from '../../util/Dropdown';
import { ImageFile } from '../../util/ImageFile';
import getInfoFromServer, { Copy, postInfoToServer } from '../../util/util';
import { NameText } from '../../util/NameText';
import { ToggleButton, Button } from '../../util/ToggleButton';
import RenderPopup from '../../popup/Popup';
import AlertBox from '../../popup/AlertBox';

export function EditColor(props)
{
    const NewEdit = useRef(null);
    const [colors, setColors] = useState([]);
    const [colorKey, setColorKey] = useState(-1);
    const [loading, setLoading] = useState(true);
    
    const onColorSelect = (key) => {
        setColorKey(key);
    }

    const onNameBlur = (value) => {
        const temp = Copy(colors);
        temp[colorKey].color = value;
        setColors(temp);        
    }

    const onImageFile = (src) => {
        const temp = Copy(colors);
        temp[colorKey].thumbnailUrl = src;
        setColors(temp);
    }

    const onColorDrag = (items, newKey, newIndex) =>
    {
        setColors(items);
        setColorKey(newIndex);        
    }

    const onToggleClick = (mode) => {
        if (mode === 'new') {
            const colorsSortedByKey = Copy(colors).sort((a, b) => a.key - b.key);
            const colorsSortedById = Copy(colors).sort((a, b) => a.id - b.id);

            const newKey = colorsSortedByKey[colorsSortedByKey.length - 1].key + 1;
            const newId = colorsSortedById[colorsSortedById.length - 1].id + 1;

            const newColor = {
                color: 'None', thumbnailUrl: '', key: newKey, id: newId
            }

            const temp = Copy(colors);
            temp.push(newColor);
            setColors(temp);
            setColorKey(temp.length - 1);
        }
    }

    const onRemoveThumbnail = (e) => {
        const temp = Copy(colors);
        temp[colorKey].thumbnailUrl = "";

        setColors(temp);
    }

    const onDelete = async (e) => {
        setLoading(true);
        const response = await postInfoToServer('edit/Delete/Color', {
            id: colors[colorKey].id
        });

        setColorKey(-1);
        setColors(response.colors);        
        setLoading(false);
    }

    const onSubmit = async (e) => {
        setLoading(true);
        const response = await postInfoToServer('edit/Submit/Color', {
            colors: colors, id: colors[colorKey].id
        });

        if (response.error) {
            RenderPopup(<AlertBox dismisable>{response.message}</AlertBox>);
            setColorKey(-1);
            setColors(response.colors);
        }

        setLoading(false);
    }

    useEffect(() => {
        const initialize = async () => {
            const response = await getInfoFromServer('edit/Start/Color');

            setColors(response.colors);
            setLoading(false);
        }

        initialize();

    }, []);

    const color = colorKey > -1 ? colors[colorKey] : null;
    const colorName = colorKey > -1 ? color.color : "";
    const colorUrl = colorKey > -1 ? color.thumbnailUrl : "";
    const key = colorKey > -1 ? color.key : -1;

    if (loading) return <Loading />

    return <>
        <h5>Edit Color</h5>
        <ToggleButton ref={NewEdit} onClick={onToggleClick}>
            <Button id='new' name='New' />
            <Button id='edit' name='Edit'>
                <Dropdown value={colorKey} items={colors.map(x => x.color)} onChange={onColorSelect}>
                    Select Color
                </Dropdown>
            </Button>
            {colorKey > -1 && <>
                <NameText name="Color" value={colorName} onBlur={onNameBlur} />
                <ImageFile value={colorUrl} onChange={onImageFile}>
                    Color Url
                </ImageFile>
                <div>
                    <button onClick={onRemoveThumbnail}>Remove Thumbnail</button>
                </div>
                <DragAndDrop label='Color Layer' value={key} items={colors} onChange={onColorDrag} onClick={onColorSelect}
                    name='thumbnailUrl' displayImage
                />
                <button onClick={onDelete}>Delete</button>
                <button onClick={onSubmit}>Submit</button>
            </>}
        </ToggleButton>
        </>
}