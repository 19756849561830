import React, { Component, useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { Copy } from "../../util/util";
import { NameText } from '../../util/NameText';
import './Customize.css';
import { Dropdown, Input } from '../../util/Dropdown';
import { ActiveImageClick } from './ActiveImageClick';
import RenderPopup from '../../popup/Popup';
import getInfoFromServer, { CopyText, postInfoToServer } from "../../util/util";
import AlertBox from '../../popup/AlertBox';
import { SetCartNumItems } from '../../icons/CartIcon';
import CopyTextButton from '../../util/CopyTextButton';
import { FindImageDownload } from '../FindImage';
import Loading from '../../icons/Loading';

const MySessinId = React.createContext();

export class Customize extends Component {
    static contextType = MySessinId;
    static displayName = Customize.name;
    static imageControllerPath = 'image';

    constructor(props) {
        super(props);

        this.state = {
            sessionId: "",

            materialKey: -1,
            sizeKey: -1,
            price: -1,

            toggleItems: [],

            description: "",
            subdescription: "",
            productType: "",
            activeImage: null,

            formLoading: true,
            loading: true,
            enabled: true,
            authenticated: false,

            saved: true,
            edit: false
        }
        document.title = this.props.match.params.name;

        this.materialHandler = this.materialHandler.bind(this);
        this.onActiveImageClick = this.onActiveImageClick.bind(this);
        this.preventDefault = this.preventDefault.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChildChange = this.onChildChange.bind(this);

    }

    onSubmit() {

        this.setState({ edit: true, saved: true });
    }

    onActiveImageClick(e)
    {
        if (this.state.authenticated) {
            const popupElement = <ActiveImageClick sessionId={this.state.sessionId} />;

            RenderPopup(popupElement);
        }
    }

    preventDefault(event) {
        event.preventDefault();
    }

    componentDidMount() {
        window.addEventListener("contextmenu", this.preventDefault);
        this.initialize();
    }

    componentWillUnmount() {
        window.removeEventListener("contextmenu", this.preventDefault);

        //We do not want it to "Exit" if it did not even mount properly
        if (this.state.sessionId != "")
            Customize.GetInfo("Exit", this.state.sessionId);
    }

    async materialHandler(materialKey, sizeKey) {
        const result = await Customize.PostInfo("Material/" + materialKey + '/' + sizeKey, {}, this.state.sessionId);
        this.setState({ price: result.price, saved: false });
        window.dataLayer.push({ 'ov': result.price });
    }

    async onChildChange(args) {
        //If it is not loading (the person clicked an item)
        //Set the saved to false i.e. they need to save
        //If loading is true that means we are just mounting and the user has not clicked anything
        this.setState({
            saved: this.state.loading,
            enabled: false,
            loading: true
        });

        const result = await Customize.GetInfo('Active', this.state.sessionId);

        this.setState({
            activeImage: result.activeImage, loading: false, enabled: true
        });
    }

    render() {
        if (this.state.formLoading) return <Loading />
        
        return <>
            <div className="customize-container">
                <Prompt when={!this.state.saved} message='Are you sure you want to leave with unsaved changes?' />
                <MySessinId.Provider value={this.state.sessionId}>
                    <Panel.left>
                        <Sticky>
                            <ActiveImage loading={this.state.loading}
                                image={this.state.activeImage}
                                onClick={this.onActiveImageClick}
                                zoom={this.state.authenticated}
                                alt={this.state.description} />
                            <p className="subtext">*This is a low quality preview. You will receive a higher resolution image after purchase.</p>
                        </Sticky>
                    </Panel.left>
                    <Panel.right>
                        <DisplayDescription description={this.state.description} subdescription={this.state.subdescription} />
                        <DisplayPrice price={this.state.price}/>
                        <Material onChange={this.materialHandler} materialKey={this.state.materialKey}
                                sizeKey={this.state.sizeKey} />
                        <PersonalizeDesign onChange={this.onChildChange} toggleItems={this.state.toggleItems} sessionId={this.state.sessionId} enabled={this.state.enabled} />                        
                        <Submit onClick={this.onSubmit} edit={this.state.edit} admin={this.state.authenticated} />
                    </Panel.right>
                </MySessinId.Provider>

            </div>
        </>
    }

    async initialize() {
        const id = this.props.match.params.id;
        const name = this.props.match.params.name;

        const result = await Customize.GetInfo('Start/' + name, id);

        if (this.props.id !== undefined) {
            this.setState({ edit: true, saved: true });
        }

        document.getElementsByTagName("META")[5].content = result.description;

        this.setState({
            formLoading: false,
            sessionId: result.sessionId,
            description: result.description,
            subdescription: result.subdescription,
            price: result.price,
            activeImage: result.activeImage,
            materialKey: result.materialKey === "" ? -1 : result.materialKey,
            sizeKey: result.sizeKey === "-1" ? -1 : result.sizeKey,
            authenticated: result.isAuthenticated,
            toggleItems: result.toggleItems,
            productType: name
        });
    }

    static async GetInfo(value, sessionId = "none") {
        const data = await getInfoFromServer(Customize.imageControllerPath + '/' + sessionId + '/' + value);
        return data;
    }

    static async PostInfo(value, obj, sessionId = "none") {
        const data = await postInfoToServer(Customize.imageControllerPath + '/' + sessionId + '/' + value, obj);
        return data;
    }
}

class Material extends Component {
    static contextType = MySessinId;

    constructor(props, context) {
        super(props);

        this.state = {
            material: [],
            size: [],
            materialKey: -1,
            sizeKey: -1
        }

        this.materialChange = this.materialChange.bind(this);
        this.sizeChange = this.sizeChange.bind(this);
    }

    componentDidMount() {
        this.getMaterials();
    }

    render() {
        if (this.state.material.length == 0)
            return < div > loading...</div >
        let content = () => {
            //If the user hasn't picked a material yet 
            //or if the size is "None", don't display the size dropdown
            if (this.state.materialKey === -1 || (this.state.size[this.state.materialKey][this.state.sizeKey] !== undefined && this.state.size[this.state.materialKey][this.state.sizeKey].includes("None"))) return <div></div>

            return (
                <Dropdown value={this.state.sizeKey} items={this.state.size[this.state.materialKey]}
                    onChange={this.sizeChange} >
                    <span className="item-label">Size</span>
                </Dropdown>
            );
        }

        return (
            <div className="item" >
                <Dropdown value={this.state.materialKey} items={this.state.material}
                    onChange={this.materialChange} >
                    <span className="item-label">Select Product:</span>
                </Dropdown>
                {content()}

            </div>
        );
    }

    materialChange(value) {
        //value = Object.keys(this.state.material)[value];
        //Everytime the material is changed, just make it so the user has to reselect the size
        this.setState({ materialKey: value, sizeKey: -1 });

        //We call props so that an api call can be made saying that the size has not been set yet
        //If the user has selected something like digital where a size is not needed, then this call is also
        //needed
        this.props.onChange(value, -1);
    }

    sizeChange(value) {
        this.setState({ sizeKey: value });

        this.props.onChange(this.state.materialKey, value);
    }


    //Gets a list of objects that are needed for building and assigns it to this.state.images
    async getMaterials() {
        const result = await Customize.GetInfo("Material", this.context);

        //When size key = "-1" with quotes, it messes things up
        const sizeKey = this.props.sizeKey === "-1" ? -1 : this.props.sizeKey;

        this.setState({
            material: result.materials, size: result.sizes, materialKey: this.props.materialKey, sizeKey: sizeKey
        });
    }
}

function DisplayPrice(props) {
    return <>
        {props.price !== -1 && <span className="price">${props.price.toFixed(2)}</span>}
        </>
}

function DisplayDescription(props) {
    return <>
        <h1 id="tabelLabel" >{props.description}</h1>
        <p>{props.subdescription}</p>
        </>
}

function Panel(props) {
    return <>
        <span className={`panel panel-${props.direction}`}>
            {props.children}
        </span>
        </>
}

Panel.right = (props) => {
    return <Panel direction="right">
        {props.children}
        </Panel>
}

Panel.left = (props) => {
    return <Panel direction="left">
        {props.children}
    </Panel>
}

function Sticky(props) {
    return <div style={{ position: "sticky", top: "10px" }}>
            {props.children}
        </div>
}

class ActiveImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scroll: 0
        };

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.onClick)
            this.props.onClick();
    }

    render() {
        const zoom = this.props.zoom ? " zoom" : "";
        return (
            <div className="active-image_outer-wrapper">
                <div ref="image" className="active-image box-wrapper">
                    <div className="active-image_inner-wrapper">
                        {!this.props.image ? <div className="active-image-loading">creating...</div> :
                            <img className={"active-image-self" + zoom}
                                src={this.props.image}
                                onClick={this.onClick}
                                alt={this.props.alt}
                                //title="Low Quality Version"
                            />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

class Submit extends Component {
    static contextType = MySessinId;

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);


        this.state = {
            hitSubmit: false,
            edit: props.edit
        };
    }

    onDownloadClick(e) {
        FindImageDownload(this.context);
    }

    async onConfirm(value) {
        if (!value) return;

        const waitMessage = this.props.edit ? "Please wait while we update your item." : "Please wait while we add your item to your cart."

        RenderPopup(<AlertBox type="none">{waitMessage}</AlertBox>)


        const result = await Customize.PostInfo("Submit", {}, this.context);

        //The server returns null if the user has not picked a material and size
        if (!result.hasSelectedProduct) {
            RenderPopup(<AlertBox error dismisable>Please select a product before continuing.</AlertBox>);
            this.setState({
                hitSubmit: false
            });
            return;
        }


        if (!result.hasSelectedSize) {
            RenderPopup(<AlertBox error dismisable>Please select a size before continuing.</AlertBox>);
            this.setState({
                hitSubmit: false
            });
            return;
        }
        // Pinterest add to cart event
        window.dataLayer.push({ 'oq': result.numItems })
        // MDJ TODO need real order id, or is this it??
        window.dataLayer.push({ 'oid': this.context })
        window.dataLayer.push({ 'event': 'addcart-click' })
        SetCartNumItems(result.numItems);

        const adminMessage = <>
            <div>Item key:  <CopyTextButton value={this.context} /></div>
            <div><button onClick={this.onDownloadClick}>Download Image</button></div>
        </>;
        const doneMessage = this.props.edit ? "Your item has been updated. " : "Your item has been added to the Cart.  Select the cart icon at the top to begin checkout. ";
        RenderPopup(<AlertBox dismisable>
            {doneMessage}
            {this.props.admin && adminMessage}
        </AlertBox>);

        this.props.onClick();
        this.setState({
            hitSubmit: true
        });

    }

    onClick(e) {
        e.preventDefault();

        this.onConfirm(true);
    }

    render() {
        const text = (this.props.edit) ? "Update item in cart" : "Add to cart";
        return (
            <div className="group-item">
            <div>

            <button className="button-two" type="button" onClick={this.onClick} >{text}</button>
        </div>
        </div>);
    }
}

class PersonalizeDesign extends Component
{
    constructor(props) {
        super(props);

        this.onPersonChange = this.onPersonChange.bind(this);              
    }

    async onPersonChange()
    {
        await this.props.onChange();
    }

    render()
    {
        return <>
            {this.props.toggleItems.length > 0 && <div className="design-wrapper box-wrapper">
                    <h4 className="design-title">Personalize Design</h4>
                    <div className="design-item">                        
                    {this.props.toggleItems.map(toggle => <Person toggle={toggle}
                            onChange={this.onPersonChange}
                           partEnabled={this.props.enabled}
                            sessionId={this.props.sessionId}
                        />)}
                    </div>

                </div>}
        </>
    }
}

function PeopleSize(props) {
    const onSelect = (value) => {
        props.onChange(value);
    }

    return <div>
        <Dropdown className="item-label" items={props.items} value={props.value} onChange={onSelect}>
            {props.label}
        </Dropdown>
    </div>
}

class Person extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            activeImage: null,

            toggle: {},            

            partClicked: false,
            
            peopleKey: -1,            
        };

        this.typeHandler = this.typeHandler.bind(this);
        this.partHandler = this.partHandler.bind(this);
        this.peopleHandler = this.peopleHandler.bind(this);
        this.handleNumPeople = this.handleNumPeople.bind(this);
    }

    componentDidMount()
    {
        this.setState({
            toggle: this.props.toggle,
            peopleKey: 0, 
        });
    }    

    async peopleHandler(key)
    {
        this.setState({
            peopleKey: key
        });
    }

    //Is called when the user changes a part is changed. 
    //Passes in information about the selected part to 'data': [type of image][key of image]

    async typeHandler(selectedType)
    {
        const result = await Customize.GetInfo(this.state.toggle.id + '/' + this.state.toggle.people[this.state.peopleKey].key + '/' + selectedType, this.props.sessionId);

        this.setState({
            toggle: result.toggleItem,            
        });

        this.props.onChange();
    }

    async partHandler(subType, colorIndex, type, key, value)
    {
        const imageType = type;
        const imageKey = key;
        const toggleNumber = this.state.toggle.id;
        const personNumber = this.state.toggle.people[this.state.peopleKey].key;
        
        this.setState({
            partClicked: true
        });
        
        const result = await Customize.PostInfo("UpdateImage/" + toggleNumber + '/' + personNumber, {
            imageType: imageType, key: imageKey, value: value, imageSubType: subType, imageSubTypeIndex: colorIndex
        }, this.props.sessionId);

        this.setState({
            toggle: result.toggleItem,            
        });

        await this.props.onChange();

        this.setState({
            partClicked: false
        });

    }

    async handleNumPeople(value) {
        const response = await Customize.PostInfo('PostNumPeople', { toggleId: this.state.toggle.id, numPeople: this.state.toggle.personCountRange[value] }, this.props.sessionId);

        this.setState({
            toggle: response.toggleItem,
            peopleKey: 0, 
        });

        await this.props.onChange();
    }
        
    render()
    {
        if (this.state.toggle.people === undefined) return <></>

        const people = this.state.toggle.people;
        const person = people[this.state.peopleKey];
        const peopleType = person.possibleTypes;
        const numPeople = people.length;
        const numPeopleType = Object.keys(peopleType).length;
        const numPeopleRange = this.state.toggle.personCountRange;
        const title = numPeople === 1 ? people[0].name : "Person";
        const images = person.images
        const typeKey = person.personTypeKey;        

        //Only continue is the uer has selected an amount of people
        if (numPeople === 0) return <></>

        const enabled = !(this.state.peopleKey === -1);

        //Disable the person type dropdwon if there are only two people with two people types
        const enableTypeDropdown = !(numPeopleType === 1);

        const personSizeLabel = numPeopleRange.length > 1 && numPeopleRange[1] === 3 ? "Select Number of People/Pets" : "Select Number of People";

        return (
            <div>
                
                <h4 className="item-label">{title}</h4>
                {numPeopleRange.length > 1 && <>
                    <PeopleSize items={numPeopleRange} value={numPeopleRange.findIndex(x => x === numPeople)} onChange={this.handleNumPeople} label={personSizeLabel} />
                </>}
                {numPeople > 1 && <Dropdown value={this.state.peopleKey} items={people.map(x => x.name)}
                    onChange={this.peopleHandler}>
                    <span className="item-label">Customize Person:</span>
                </Dropdown>}
                     
                <div style={{ display: (enabled) ? "block" : "none" }}>                    
                    <PersonType onTypeChange={this.typeHandler} onPartChange={this.partHandler}
                        images={images} typeKey={typeKey}
                        personKey={this.state.personKey} peopleType={peopleType}
                        enabled={this.props.partEnabled} dropdownEnabled={enableTypeDropdown}
                        
                    />
                </div>
        </div>);
    }

   
}

function PersonType(props)
{
    const disabled = (props.dropdownEnabled) ? "" : " disabled";

    //If there is no person type drop down, don't have a gap
    const marginTop = (props.dropdownEnabled) ? "10px" : "0px";

    return (
        <div style={{ marginTop: marginTop } }>
            <Dropdown className={"" + disabled} value={props.typeKey} items={props.peopleType} onChange={props.onTypeChange} >
                <span className="item-label">Type of Person</span>
            </Dropdown>
            <ImageTypes images={props.images} onChange={props.onPartChange}
                show={!(props.typeKey === -1)} enabled={props.enabled} />
            
        </div>);

}

function ImageTypes(props) {
    return <>
        {props.images.map(image => <>
            
            {<SubTypes image={image} show={props.show} enabled={props.enabled} onChange={props.onChange}/>}
        </>)}
        </>
}

function SubTypes(props) {
    const findSelectedSubIndex = (subTypes) => {
        const index = subTypes.findIndex(subType => subType.images.findIndex(image => image.interactables.findIndex(i => i.value === "selected") !== -1) !== -1);

        return index;
    }

    useEffect(() => {
        if (index === -1) {
            setIndex(findSelectedSubIndex(props.image.subTypes));
        }
    }, [props.image]);

    const [index, setIndex] = useState(findSelectedSubIndex(props.image.subTypes));

    const onSelect = (value) => {
        setIndex(value);
    }

    const onChange = (subType, type, key, value) => {
        props.onChange(subType, subTypeIndex, type, key, value);
    }

    const bypass = props.image.subTypes.length === 1;
    const subTypeIndex = bypass ? 0 : index;
    const name = props.image.type.name;
    return <>
        {!bypass && <div className="group-item"><span className="item-label">{name}</span>
            <Dropdown items={props.image.subTypes.map(x => x.type.name)} value={index} onChange={onSelect} />
        </div>}
        {(bypass || index > -1) && <Parts subType={props.image.subTypes[subTypeIndex]}
            onChange={onChange} subTypeName={name} bypass={bypass}
            show={props.show} enabled={props.enabled} />}
        </>
}

function Parts(props)
{
    if (!props.show) return <></>
    
    return (
        <div className="group-item">
            {props.subType.images.map((image, i) =>

                <DisplayPart id={image.type.key} item={image}
                    onChange={props.onChange} subTypeName={props.subTypeName}
                    enabled={props.enabled} bypass={props.bypass}/>)}
           
        </div>);
}


class Thumbnail extends Component
{
    constructor(props) {
        super(props)

        this.onClick = this.onClick.bind(this);
        this.onHover = this.onHover.bind(this);
        this.getStyle = this.getStyle.bind(this);
    }

    onHover(a, event)
    {
        
    }

    onClick(event)
    {
        event.preventDefault();
        if (this.props.enabled)
            this.props.onClick(event.target.title, Number(event.target.id));
    }

    getStyle(value) {
        return (value === "selected") ? " selected" : "";
    }

    render()
    {
        const image = Copy(this.props.image);
        const hasOptions = image.options !== undefined;

        if (!hasOptions) {
            image.options = [{ name: image.name, thumbnail: image.thumbnail }];
        }
        
        let selected = image.value === "selected" ? " selected" : "";
        const quote = (image.imageType.name === "Quote") ? " quote" : "";

        return <div style={{ display: hasOptions ? "block" : "inline" }}>
            {hasOptions ? image.name : <></>}
            {image.options.map(option => {
                selected = hasOptions ? (option.name === image.value ? " selected" : "") : selected;
                return <a className={"part-item selectable " + selected + quote + (this.props.className ? this.props.className : "")} onMouseEnter={(e) => { this.onHover("on", e) }} onMouseLeave={(e) => { this.onHover("off", e) }}
                    href="#" onClick={this.onClick}>
                    <img src={option.thumbnail} alt={option.name} id={this.props.id} title={option.name} width="100%" height="100%" />
                </a>
            })}
           
            
        </div>;
    }
}

class DisplayPart extends Component {
    constructor(props)
    {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleDropdown = this.handleDropdown.bind(this);
        this.handleCheckBox = this.handleCheckBox.bind(this);
    }

    handleChange(value, newKey) {

        const newValue = this.props.item.interactables[newKey].key;
        if (this.props.onChange && this.props.enabled) {
            //Passes in the image type key and image key
            this.props.onChange(this.props.id, this.props.item.interactables[newKey].typeKey, newValue, value);
        }
    }

    handleDropdown(value, newKey) {
        if (this.props.enabled)
            this.handleChange(this.props.item.interactables[newKey].options[value].name, newKey);
    }

    handleCheckBox(value, newKey) {
        if (this.props.enabled)
            this.handleChange(value ? "checked" : "unchecked", newKey);
    }

    
    findIndex(key)
    {
        for (var i = 0; i < this.props.items.length; i++)
        {
            if (this.props.item.interactables[i].key === key) return i;
        }
        throw new DOMException("Cannot find image with key " + key);
    }

    render() {

        const images = this.props.item.interactables;
        const name = this.props.item.name;
        const enabled = (this.props.enabled) ? "" : " not-allowed";


        return (<div className={"item"} >
            {(this.props.subTypeName !== name || this.props.bypass) && <span className="item-label">{name} </span>}
            {images.map((image, i) => {
                return image.type === "clickable" ? <Thumbnail className={enabled} id={i} image={image} onClick={this.handleChange} enabled={this.props.enabled} /> :
                    image.type === "multitext" ? <Input.div type="textbox" className={enabled} id={i} value={image.value} onBlur={this.handleChange} /> :
                        image.type === "text" ? <NameText className={enabled} id={i} value={image.value} onBlur={this.handleChange} /> :
                        image.type === "dropdown" ? <div className={"font-select" + enabled}><span className="inline_text inline_label">{image.name}</span><span className="inline_text inline_dropdown"><Dropdown id={i} value={image.value} items={image.options.map(x => x.name)} onChange={this.handleDropdown} /></span></div> :
                            image.type === "checkbox" ? <Input.div className={enabled} id={i} value={image.value === "checked"} type="checkbox" onChange={this.handleCheckBox} enabled={this.props.enabled}><span className="inline_text">{image.name}</span></Input.div>
                                : <div>invalid type</div>
            })}
        </div>);
    }
}


