import React from 'react'; 
import axios from 'axios';
import RenderPopup from '../popup/Popup';
import ErrorPopup from '../popup/ErrorPopup';


//null returns ""
export default async function getInfoFromServer(info, obj)
{
    return axios({
        method: 'get',
        url: info,
        params: obj,
        headers: { 'content-type': 'application/json' }
    }).then(handleData).catch(handleError);
}

export async function postInfoToServer(call, obj)
{
    return axios({
        method: 'post',
        url: call,
        data: obj,
        headers: { 'content-type': 'application/json' }
    }).then(handleData).catch(handleError);
}

export async function putInfoToServer(call, obj) {
    return axios({
        method: 'put',
        url: call,
        data: obj,
        headers: { 'content-type': 'application/json' }
    }).then(handleData).catch(handleError);
}


export function isEqual(a, b)
{
    //If it is not an object, just compare and return
    if (!isObject(a) || !isObject(b))
    {
        if (isObject(a) || isObject(b)) return false;

        return (a === b);
    }

    var isAArray = Array.isArray(a);
    var isBArray = Array.isArray(b);

    //Handles if one or both are arrays
    if (isAArray || isBArray)
    {
        if (!isAArray || !isBArray || a.length !== b.length) {
            return false;
        }
        for (var i = 0; i < a.length; i++) {
            if (!isEqual(a[i], b[i])) {
                return false;
            }
        }

        return true;
    }

    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length != bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (!isEqual(a[propName],b[propName]))
        {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}

export function Copy(obj)
{
    return JSON.parse(JSON.stringify(obj));
}

export function CopyText(value) {
    navigator.clipboard.writeText(value);
}

export function CopyTextButton(props) {
    return <button onClick={this.onCopyText}>{this.context}</button>
}

export const appendScript = (scriptToAppend) =>
{
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    document.body.appendChild(script);
}

export const removeScript = (scriptToremove) =>
{
    let allsuspects = document.getElementsByTagName("script");
    for (let i = allsuspects.length; i >= 0; i--)
    {
        if (allsuspects[i] && allsuspects[i].getAttribute("src") !== null
            && allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`) !== -1)
        {
            allsuspects[i].parentNode.removeChild(allsuspects[i])
        }
    }
}

export function parseNumber(sIn, leftChar = "[", rightChar = "]"){
    if (sIn === null || sIn === undefined) {
        return null;
    }

    const num = parseInt(sIn.slice(sIn.indexOf(leftChar) + 1, sIn.indexOf(rightChar)));

    return isNaN(num) ? null : num;
}

function isObject(obj) {
    if (obj === null) return false;
    return (typeof obj === "object");
}

function handleError(err)
{
    const error = err.response.data.responseStatus !== undefined ? err.response.data.responseStatus :
        {
            stackTrace: "",
            message: err.response.data,
            Info: err.response.data
        };

    //If we got the error response, create a popup
    if (error != "" && error.message != "")
    {
        let ErrorObject = <ErrorPopup error={error.stackTrace} message={error.message} dismisable/>

        RenderPopup(ErrorObject);

        if (error.Info) {
            throw Error(error.Info);
        }
    }
}

function handleData(request) {
    return request.data
}