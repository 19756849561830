import React, { Component, useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Order } from './Order';
import "./Home.css";
import { Logo } from '../icons/Logo';
import getInfoFromServer from '../util/util';
import Loading from '../icons/Loading';

export function Home(props)
{
    const [loading, setLoading] = useState(true);
    const [home, setHome] = useState(null);

    useEffect(() => {
        const initialize = async () => {
            const response = await getInfoFromServer('/edit/Pages');

            const home = response.pages.find(x => x.name === 'Home');

            if (!home) {
                throw DOMException("Cannot find home page");
            }

            setHome(home);
            setLoading(false);
        }

        initialize();
    }, []);

    if (loading) return <Loading/>

    return <>
            <div className="banner-container">
                <img className="inside" src={home.imageUrl} />
            </div>
            
            <div className="info-container">
                <Logo className="logo" />
                    <div className="info">                   
                    <ReactMarkdown>{home.content}</ReactMarkdown>
                        {/*<p>Welcome to my creative corner where you can use my art palette to create your own custom work of art!</p>*/}
                        {/*<p>What a perfect way to celebrate those special moments that make life more meaningful.</p>*/}
                        {/*<p>For more personalization options please visit my <a href="https://www.etsy.com/shop/AJsPalette" target="_blank">Etsy Shop</a>.</p>*/}
                        <div className="profile">
                            <h6 className="text-gray center">Artist Profile</h6>
                            <Link className="text-black" to="/info">
                                <ProfilePicture width="60px"/>
                                <p className="center"> Amy Jones </p>
                            </Link>
                        </div>
                    </div>
                </div>
            
            <Order />
            

      </>
}

export function ProfilePicture(props)
{
    return (
        <img className="profile-image" src="https://artofjoycdn.blob.core.windows.net/images/ProfilePicture.jpg" width={props.width} />
       );
}
