import React from 'react';
import './ErrorPopup.css';

export default function ErrorPopup(props)
{
    const defaultMessage = <><p>Oops, it looks like something went wrong. Please go back or
        refresh this page! If this problem persists, please call technical support</p>
        <p> (385) - 221 - 6203</p>
    </>

    //If props defines a message, desplay that, otherwise desplay the generic message
    const message = (props.message) ? <p className="red text-align-center">{props.message}</p> : defaultMessage;

    //If props defines an error, display that. Otherwise nothing
    const error = (props.error) ? <p>{props.error}</p> : <></>;

    return <div className="error_popup-container">
      
            <div className="red text-align-center">
                {message}
            </div>
            {error}
      
        </div>

   
}