import React, { useEffect, useState, useRef } from 'react'
import Loading from '../../icons/Loading';
import { Dropdown, DropdownDropdown } from '../../util/Dropdown';
import getInfoFromServer, { Copy, postInfoToServer } from '../../util/util';
import { NameText } from '../../util/NameText';
import { Button, ToggleButton } from '../../util/ToggleButton';
import { SelectImage } from './EditPerson';


export function EditCoupledItem(props)
{
    const [items, setItems] = useState([]);
    const [images, setImages] = useState([]);
    const [currIndex, setCurrIndex] = useState(-1);
    const [loading, setLoading] = useState(true);
    const toggleButton = useRef(null);

    const onToggleClick = (mode) =>
    {
        if (mode === 'new')
        {
            const temp = Copy(items);
            
            const newItem = {
                id: items[items.length - 1].id + 1, name: "New Item",
                imageKeys: []                
            }
            temp.push(newItem);
            setItems(temp);
            setCurrIndex(temp.length - 1);
        }        
    }

    const onEditDropdown = (value) => {
        setCurrIndex(value);
    }

    const onNameBlur = (value) => {
        const temp = Copy(items);

        temp[currIndex].name = value;

        setItems(temp);
    }

    const onLooksLikeChange = (index) => {
        const temp = Copy(items);

        const name = temp[currIndex].name;
        const id = temp[currIndex].id;
        temp[currIndex] = items[index];
        temp[currIndex].name = name;
        temp[currIndex].id = id;

        setItems(temp);
    }

    const onImageAdd = (e) =>
    {
        let temp = Copy(items);

        const id = parseInt(e.target.id);
        const checked = e.target.checked;
        const img = images.find(x => x.id === id);

        //If we are adding this image
        if (checked) {
            temp[currIndex].imageKeys = { ...temp[currIndex].imageKeys, [id]: img.urls };
        }
        //If we are removing this image
        else {
            delete temp[currIndex].imageKeys[id];
        }

        setItems(temp);
    }

    const onNewImageValue = (newValue, id) => {
        let temp = Copy(items);

        const id2 = images.find(x => x.id === id).urls.findIndex(x => x.includes(newValue));
        const currValues = temp[currIndex].imageKeys[id][id2];

        //only add this to the list if we do not already have it
        if (currValues.findIndex(x => x === newValue) === -1) {
            temp[currIndex].imageKeys[id][id2].push(newValue);
        }
        
        setItems(temp);

    }

    const onDeleteImageValue = (toDelete, id) => {
        let temp = Copy(items);

        const id2 = images.find(x => x.id === id).urls.findIndex(x => x.includes(toDelete));
        const currValues = temp[currIndex].imageKeys[id][id2];
        

        temp[currIndex].imageKeys[id][id2].splice(currValues.findIndex(x => x === toDelete), 1);

        setItems(temp);
    }

    const onDelete = (e) => {
        const response = postInfoToServer('edit/Delete/Coupled', {
            id: items[currIndex].id
        });

        setItems(response.coupledItems);
    }

    const onSubmit = async (e) =>
    {
        setLoading(true);

        await postInfoToServer('edit/Submit/Coupled', {
            items: items, id: items[currIndex].id
        });
        
        setLoading(false);
    }

    useEffect(() => {
        const initialize = async () => {
            const response = await getInfoFromServer('edit/Start/Coupled');

            setItems(response.coupledItems);
            setImages(response.images);
            setLoading(false);
        }

        initialize();
    }, []);

    const item = currIndex > -1 ? items[currIndex] : {}

    if (loading) return <Loading/>
    return <div>
        <h5>Edit Coupled Item</h5>
        <ToggleButton onClick={onToggleClick} ref={toggleButton}>
            <Button id='new' name="New" />
            <Button id='edit' name="Edit">
                <Dropdown value={currIndex} items={items.map(x => x.name)} onChange={onEditDropdown}>
                    Select Coupled Item
                </Dropdown>
            </Button>
            {currIndex > -1 && <>
                <NameText name="Name" value={item.name} onBlur={onNameBlur} />
                <LooksLike items={items.map(x => x.name)} onChange={onLooksLikeChange}/>
                <SelectImage items={images} currAllImageKeys={Object.keys(item.imageKeys).map(x => Number(x))} onChange={onImageAdd}>                    
                    {(curr, index) => curr.urls.map((x, i) => <>
                        <DropdownDropdown id={curr.id} items={item.imageKeys[curr.id][i]} allItems={x} onNew={onNewImageValue} onDelete={onDeleteImageValue}>
                            Possible Values:
                        </DropdownDropdown></>
                    )}
                </SelectImage>
                <button onClick={onDelete}>Delete</button>
                <button onClick={onSubmit}>Submit</button>
            </>}
                
        </ToggleButton>
        </div>
}

const LooksLike = (props) => {
    const [value, setValue] = useState("");

    const onSelect = (i) => {
        setValue(i);        
        props.onChange(i);
    }
    return <div>
        <Dropdown items={props.items} value={value} onChange={onSelect}>
            Looks Like
        </Dropdown>
        </div>
}