import React from 'react';
import AlertBox from '../popup/AlertBox';
import RenderPopup from '../popup/Popup';

export function ConfirmButton(props)
{
    const onPopupClick = (confirmed) => {
        if (confirmed)
            props.onConfirm();
    }

    const onClick = (e) => {
        RenderPopup(<AlertBox type="YesNo" onClick={onPopupClick}>{props.msg}</AlertBox>);
    }
    return <button onClick={onClick}>{props.children}</button>
}