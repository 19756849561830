import React, { useEffect, useState }from 'react';
import { Input } from '../util/Dropdown';
import getInfoFromServer, { postInfoToServer } from '../util/util';
import axios from 'axios';
import './Login.css';
import Loading from '../icons/Loading';
import { RenderAdmin } from '../page_layout/NavMenu';


export function Login(props) {
    const [loading, setLoading] = useState(true);
    const [login, setLogin] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    const [create, setCreate] = useState(false);
    const [loginInfo, setLoginInfo] = useState(null);
    const [name, setName] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [msg, setMsg] = useState("");

    useEffect(() => {
        const initialize = async () => {
            axios.get('auth').then(response => {
                setLoginInfo(response.data);
                setLogin(false);
                setLoggedIn(true);
                setLoading(false);

                //Render admin
                if (response.data.roles.includes("Admin")) {
                    RenderAdmin();
                }
                else {
                    RenderAdmin(false);
                }
            }).catch(error => {
                var i = 0;
                setLoading(false);
            });


        }

        initialize();
    }, []);

    const Login = async (provider, params) =>
    {
        setLoading(true);

        Logout(false);

        axios.post('auth/' + provider, params).then(response =>
        {
            window.location.reload();
        }).catch(error => {
            setMsg(error.response.data.responseStatus.message)
            setLoading(false);
        });

        
    }

    const onNameBlur = (value) => {
        setName(value);
    }
    const onUserNameBlur = (value) => {
        setUserName(value);
    }

    const onPasswordBlur = (value) => {
        setPassword(value);
    }

    const onLogin = async (e) => {
        e.preventDefault();
        Login('credentials', { userName: userName, password: password });
        //setToken(response);
    }

    const Logout = async (reload) => {
        const repsonse = await getInfoFromServer('auth/logout');

        if (reload)
            window.location.reload();
    }

    const onCreateClick = (e) => {
        setCreate(true);
        setLogin(false);
    }

    const onCreate = async (e) => {
        e.preventDefault();
        const response = await postInfoToServer('register', {
            firstName: name, userName: userName, password: password,
            confirmPassword: password
        });

        //setMsg(response);

        //If nothing is returned that, we have succeeded
        const isLogin = response === "";
        setLogin(isLogin);
        setCreate(!isLogin);
    }

    if (loading) {
        return <Loading />
    }
    return <>
        <div className='login-wrapper'>
            {login && <LoginProfile onSubmit={onLogin} onUserName={onUserNameBlur} onPassword={onPasswordBlur} onCreateClick={onCreateClick} msg={msg} />}
            {loggedIn && <UserInfo auth={loginInfo} onLogout={Logout}/>}
            {create && <CreateProfile onSubmit={onCreate} onName={onNameBlur} onUserName={onUserNameBlur} onPassword={onPasswordBlur} msg={msg} />}
        </div>

    </>
}

function LoginProfile(props) {
    return <>        
        <form className="payment" onSubmit={props.onSubmit}>
            <h5 className="login-header">Sign in to continue</h5>
            <fieldset className="form-group">
                <Form id="username" type="text" placeholder="User Name" required
                    autoComplete="username"
                    onChange={props.onUserName}>
                    Username
                </Form>
                <Form id="password" type="password" placeholder="Password" required
                    autoComplete="password"
                    onChange={props.onPassword}>
                    Password
                </Form>
                

            </fieldset>
            {props.msg !== "" && <div className="error-message">{props.msg}</div>}
            <div className='submit-button_container'>                
                <button className='submit-button button-two' type='submit'>Continue</button>                
            </div>
        </form>       
        {/*<div>
            <a className="button" href="auth/google">
                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" aria-hidden="true" focusable="false">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <path d="M15.68,8.18181818 C15.68,7.61454546 15.6290909,7.06909091 15.5345454,6.54545454 L8,6.54545454 L8,9.64 L12.3054546,9.64 C12.12,10.64 11.5563636,11.4872727 10.7090909,12.0545454 L10.7090909,14.0618182 L13.2945454,14.0618182 C14.8072727,12.6690909 15.68,10.6181818 15.68,8.18181818 L15.68,8.18181818 Z" id="Shape" fill="#4285F4" fill-rule="nonzero"></path>
                        <path d="M8,16 C10.16,16 11.9709091,15.2836364 13.2945454,14.0618182 L10.7090909,12.0545454 C9.99272729,12.5345454 9.07636364,12.8181818 8,12.8181818 C5.91636364,12.8181818 4.15272727,11.4109091 3.52363636,9.52 L0.850909091,9.52 L0.850909091,11.5927273 C2.16727273,14.2072727 4.87272727,16 8,16 L8,16 Z" id="Shape" fill="#34A853" fill-rule="nonzero"></path>
                        <path d="M3.52363636,9.52 C3.36363636,9.04 3.27272727,8.52727273 3.27272727,8 C3.27272727,7.47272727 3.36363636,6.96 3.52363636,6.48 L3.52363636,4.40727273 L0.850909091,4.40727273 C0.309090909,5.48727273 0,6.70909091 0,8 C0,9.29090907 0.309090909,10.5127273 0.850909091,11.5927273 L3.52363636,9.52 L3.52363636,9.52 Z" id="Shape" fill="#FBBC05" fill-rule="nonzero"></path>
                        <path d="M8,3.18181818 C9.17454542,3.18181818 10.2290909,3.58545454 11.0581818,4.37818182 L13.3527273,2.08363636 C11.9672727,0.792727273 10.1563636,0 8,0 C4.87272727,0 2.16727273,1.79272727 0.850909091,4.40727273 L3.52363636,6.48 C4.15272727,4.58909091 5.91636364,3.18181818 8,3.18181818 L8,3.18181818 Z" id="Shape" fill="#EA4335" fill-rule="nonzero"></path>
                        <polygon id="Shape" points="0 0 16 0 16 16 0 16"></polygon>
                    </g>
                </svg>
                <span className="google-signin">Sign in with Google</span>
            </a>
        </div>
        <div>
            <button onClick={props.onCreateClick}>Create New Profile</button>
        </div>*/}
    </>
}

function UserInfo(props) {
    const onLogout = (e) => {
        props.onLogout(true);
    }

    return <div>
        <h1>Welcome, {props.auth.displayName}</h1>
        <button className="button-two" onClick={onLogout}>Logout</button>
        </div>
}

function CreateProfile(props) {
    return <>
        <h1>Create New Profile</h1>
        <form onSubmit={props.onSubmit}>
            <Input.div onBlur={props.onName}>
                Name:
            </Input.div>
            <Input.div onBlur={props.onUserName}>
                User Name:
            </Input.div>
            <Input.div type='password' onBlur={props.onPassword}>
                Password:
            </Input.div>
            <div>
                <button type='submit'>Submit</button>
                {props.msg !== "" && <div>{props.msg}</div>}
            </div>
        </form>
    </>

}

function Form(props)
{
    const [value, setValue] = useState(props.value);

    const onChange = (e) => {
        setValue(e.target.value);

        props.onChange && props.onChange(e.target.value);
    }

    const onBlur = (e) => {
        props.onBlur && props.onBlur(value);
    }

    return <>
        <div className="form-row">
            <label htmlFor={props.id} className="form-row-label">
                {props.children}
            </label>
            <input
                className="form-row-input payment"
                id={props.id}
                type={props.type}
                placeholder={props.placeholder}
                required={props.required}
                autoComplete={props.autoComplete}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />
        </div>
    </>

}
