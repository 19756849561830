import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Loading from '../../icons/Loading';
import { ConfirmButton } from '../../util/ConfirmButton';
import { Dropdown, Input } from '../../util/Dropdown';
import { ImageFile } from '../../util/ImageFile';
import { NameText } from '../../util/NameText';
import { Button, ToggleButton, ToggleExpand } from '../../util/ToggleButton';
import getInfoFromServer, { Copy, postInfoToServer, putInfoToServer } from '../../util/util';

export default function EditPage(props) {
    const [loading, setLoading] = useState(true);
    const [pages, setPages] = useState([]);
    const [index, setIndex] = useState(-1);

    useEffect(() => {
        const initialize = async () => {
            const response = await getInfoFromServer('edit/Pages');

            setPages(response.pages);
            setLoading(false);
        }

        initialize();
    }, []);

    const onToggleClick = (mode) => {
        if (mode === 'new') {
            const items = Copy(pages);
            const id = items.sort((a, b) => b.id - a.id)[0].id + 1;

            items.push({ id: id, content: "Enter text", imageUrl: "", name: "New Page", isNew: true });

            setIndex(items.length - 1);
            setPages(items);
        }
    }

    const onPageSelect = (index) => {
        setIndex(index);
    }

    const onNameBlur = (value) => {
        const items = Copy(pages);
        items[index].name = value;

        setPages(items);
    }

    const onContentBlur = (value) => {
        const items = Copy(pages);
        items[index].content = value;

        setPages(items);
    }

    const onImageFile = async (src) => {
        const items = Copy(pages);
        items[index].imageUrl = src;

        setPages(items);
    }

    const onSubmit = async () => {
        setLoading(true);

        //Send put request to the server to update page
        const curr = pages[index];

        let response;
        if (curr.isNew) {
            response = await postInfoToServer('edit/Pages', {
                page: curr
            });
        } else {
            response = await putInfoToServer('edit/Pages/' + curr.id, {
                page: curr
            });
        }

        //Update the local copy
        const items = Copy(pages);
        items[index] = response.page;
        setPages(items);

        setLoading(false);
    }

    const name = index > -1 ? pages[index].name : '';
    const content = index > -1 ? pages[index].content : '';
    const image = index > -1 ? pages[index].imageUrl : '';

    if (loading) return <Loading />

    return <>
        <h5>Edit Pages</h5>
        <ToggleButton onClick={onToggleClick}>
            <Button id="new" name="New"/>
            <Button id="edit" name="Edit">
                <Dropdown value={index} items={pages.map(x => x.name)} onChange={onPageSelect}>
                    Select Page
                </Dropdown>
             </Button>
            {index > -1 && <>
                <NameText value={name} onBlur={onNameBlur}/>
                <span className="item-label">Content</span>
                <ToggleButton mode='edit'>
                    <Button id="edit" name="Edit">
                        <Input.div type='textbox' value={content} onBlur={onContentBlur}>                        
                        </Input.div>
                    </Button>
                    <Button id="preview" name="Preview">
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{content}</ReactMarkdown>
                    </Button>
                </ToggleButton>
                <ImageFile value={image} onChange={onImageFile}>
                    Banner Url
                </ImageFile>
                <div>
                    <ConfirmButton onConfirm={onSubmit} msg="Are you sure you want to update this page?">Update</ConfirmButton>
                </div>
                </>}
    </ToggleButton>
    </>
}