import React, { Component } from 'react';
import { ToggleButton, Button } from '../../util/ToggleButton';
import { Copy } from '../../util/util';
import { Dropdown, Input } from '../../util/Dropdown';
import { ImageFile } from '../../util/ImageFile';
import RenderPopup from '../../popup/Popup';
import AlertBox from '../../popup/AlertBox';
import { Container } from './Edit';

export class ColorField extends Component {
    constructor(props) {
        super(props);
        this.handleAddColorClick = this.handleAddColorClick.bind(this);
        this.handleAddColor = this.handleAddColor.bind(this);
        this.handleRemoveColor = this.handleRemoveColor.bind(this);
        this.handleAddFile = this.handleAddFile.bind(this);
        this.handleAddThumbnail = this.handleAddThumbnail.bind(this);
        this.handleToggleButtonClick = this.handleToggleButtonClick.bind(this);
        this.handleAddColorThumbnail = this.handleAddColorThumbnail.bind(this);

        this.state = {
            colorChildren: this.updateChildrenColor(this.props.items)   
        }
    }

    handleAddColorClick(e) {
        const color = this.props.colors[0].color;
        const thumbnail = "";
        this.handleAddColor(this.props.items.length, color, thumbnail);
    }

    updateChildrenColor(colors) {
        let children = [];
        const button = <button onClick={this.handleAddColorClick} > Add Color</ button>
        for (var i = 0; i < colors.length; i++)
        {            
            const addColorButton = < AddColor onAdd={this.handleAddColor} onRemove={this.handleRemoveColor} id={i}
                name={colors[i].name} thumbnail={colors[i].thumbnail} image={colors[i].image} isThumbnail={colors[i].isThumbnail} onThumbnailAdd = { this.handleAddThumbnail }
                items={this.props.colors} onColorEdit={this.handleAddColorThumbnail}
            />
            children = children.concat(addColorButton);
        }
        return children.concat(button);
    }

    handleAddColor(index, color, value, isThumbnail) {
        let newColors = Copy(this.props.items);

        //If we are adding
        if (index > this.props.items.length - 1) {
            newColors = newColors.concat([{
                __type: "Etsy.ServiceModel.CoreObjects.Color, ServiceModel", name: color,
                thumbnail: value, image: "", isThumbnail: false
            }]);
        }
        //if we are updating
        else {
            newColors[index] = {
                __type: "Etsy.ServiceModel.CoreObjects.Color, ServiceModel",
                name: color, thumbnail: value, image: newColors[index].image,
                isThumbnail: isThumbnail
            };
        }
        this.props.onChange(newColors);
    }

    handleAddColorThumbnail(index, color, thumbnail)
    {
        let newColors = Copy(this.props.colors);
        const colors = Object.getOwnPropertyNames(this.props.colors);

        //If we are adding a color
        if (index > colors.length - 1) {
            newColors = { ...newColors, [color]: thumbnail };
        }
        //If we are updating a color
        else
        {
            newColors = {};
            for (var i = 0; i < colors.length; i++)
            {
                //If color === null, then we want to delete it
                if (i === index && color === null) continue;

                const newColor = (i !== index) ? colors[i] : color;
                const newThumbnail = (i !== index) ? this.props.colors[newColor] : thumbnail;
                newColors = {
                    ...newColors, [newColor]: newThumbnail
                };
            }

            //If we delete all of the color, create a default "New Color"
            if (Object.getOwnPropertyNames(newColors).length === 0)
            {
                newColors = { ["New Color"]: "" };
            } 
        }
        this.props.onColorChange(newColors);
    }

    handleRemoveColor(index) {
        let newColors = Copy(this.props.items);
        newColors.splice(index, 1);

        if (newColors.length === 0) {
            newColors = [{
                __type: "Etsy.ServiceModel.CoreObjects.Color, ServiceModel",
                name: "None", thumbnail: "", image: null, isThumbnail: false
            }];
        }
        this.props.onChange(newColors);
    }

    handleAddFile(src)
    {
        const newColors = Copy(this.props.items);
        newColors[0].thumbnail = src;

        this.props.onChange(newColors);
        this.setState({
            colors: newColors
        });
    }

    handleAddThumbnail(src, name, i)
    {
        if (i === undefined) i = 0;

        const newColors = Copy(this.props.items);
        newColors[i].image = src;

        this.props.onChange(newColors);
        this.setState({ colors: newColors });
    }

    handleToggleButtonClick(mode)
    {
        if (mode === "yes") {
            //If they select yes, we don't want it to say "None" We want it to be whatever the first color is
            if (this.props.items.length === 1 && this.props.items[0].name === "None") {
                const temp = Copy(this.props.items);
                temp[0].name = this.props.colors[0].color;

                this.props.onChange(temp);
            }
        }
        else if (mode === "no") {
            if (this.props.items.length >= 1) {
                const temp = Copy(this.props.items[0]);
                temp.name = "None";

                this.props.onChange([temp]);
            }
        }
    }

    render()
    {
        const colorChildren = this.updateChildrenColor(this.props.items);
        let mode = "";

        //Force it to be selected if the item has a color or not
        if (this.props.items[0].thumbnail !== "") {
            if (this.props.items[0].name === "None" && this.props.items.length === 1) {
                mode = "no";
            }
            else {
                mode = "yes";
            }
        }

        return <Container name="Edit Colors">
            <span>Group by Color</span>
            <ToggleButton mode={mode} onClick={this.handleToggleButtonClick}>
                <Button name="Yes" id="yes">

                    {colorChildren.map((child) => {
                        return <>{child}</>
                    })}
                </Button>
                <Button name="No" id="no">
                    <ImageFile id="0" value={this.props.items[0].thumbnail} onChange={this.handleAddFile}>
                        Find Image
                    </ImageFile>
                    {/*<ImageFile id="0" value={this.props.items[0].image} onChange={this.handleAddThumbnail}>*/}
                    {/*    Find Thumbnail*/}
                    {/*</ImageFile>*/}
                        
                </Button>

            </ToggleButton>
        </Container>
    }
}

function AddColor(props)
{
    let index = props.items.findIndex(x => x.color === props.name);
    if (index < 0) {
        index = 0;
        props.onAdd(props.id, props.items[index].color, props.thumbnail, props.isThumbnail);
    }

    const onAdd = (value) =>
    {
        /*
         * Get call to find out if the color is valid
         */

        props.onAdd(props.id, props.items[value].color, props.thumbnail, props.isThumbnail);        
    }

    const onEditColor = (index, color, thumbnail) =>
    {
        props.onColorEdit(index, color, thumbnail);

        //If we are deleting a color, adjust the value
        if (color === null) index--;
        if (index < 0) index = 0;

        props.onAdd(props.id, color, props.thumbnail, props.isThumbnail);        
    }

    const onIsThumbnailChange = (value) =>
    {
        props.onAdd(props.id, props.name, props.thumbnail, value);
    }

    const handleFile = (src) =>
    {
        props.onAdd(props.id, props.name, src, props.isThumbnail);
    }

    const handleRemove = (e) => 
    {
        props.onRemove(props.id);
      
    }
   
    const color = (props.name) ? props.name : "";

    return <>
        <ColorPicker name={color} items={props.items} value={index} thumbnail={props.colorThumbnail}
            onAdd={onAdd} onEdit={onEditColor} />
        <Input.div type="checkbox" value={props.isThumbnail} onChange={onIsThumbnailChange}>
            Same as Thumbnail
        </Input.div>
        <ImageFile id={props.id} value={props.thumbnail} onChange={handleFile}>
            Find Image
        </ImageFile>        
        <button onClick={handleRemove} > {"Remove " + color}</button>
    </>
    
}

function ColorPicker(props)
{
    const handleChange = (value) =>
    {
        props.onAdd(value);
    }
    
    const length = props.items.length;

    let items = {};
    for (var i = 0; i < length; i++) {
        items = {
            ...items, [i]: props.items[i].color
        }
    }

    return (
        <>
            <div>
                <Dropdown items={items} value={props.value} onChange={handleChange} >
                    <span>Pick Color</span>
                </Dropdown>
            </div>
    </>);

}

