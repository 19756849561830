import React, { Component } from 'react'
import { Dropdown, Input } from '../../util/Dropdown';
import { isEqual, Copy } from '../../util/util';

export class EditSourceRect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeImage: "",
            value: this.props.value,
            loading: false,
            keepAspect: true,
            //Keeps track of how long the user has been holding down a key
            counter: 0,
            //and will increment it more depending on that
            increment: 25,

            hitSubmit: false,

            isDebug: false
        };

        this.handleUpClick = this.handleUpClick.bind(this);
        this.handleDownClick = this.handleDownClick.bind(this);
        this.handleRightClick = this.handleRightClick.bind(this);
        this.handleLeftClick = this.handleLeftClick.bind(this);
        this.onDown = this.onDown.bind(this);
        this.onUp = this.onUp.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onDebugCheck = this.onDebugCheck.bind(this);
    }

    componentDidMount() {
        this.changeBox(this.props.value);

        window.addEventListener("keydown", this.onDown);
        window.addEventListener("keyup", this.onUp);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.onDown);
        window.removeEventListener("keyup", this.onUp);
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (!isEqual(prevProps.value, this.props.value)) {
            this.setState({ value: this.props.value });

            this.changeBox(this.props.value);
        }
    }

    onDown(e) {
        const key = e.keyCode;

        if (key === 37) {
            this.handleLeftClick();
        }
        else if (key === 38) {
            this.handleUpClick();
        }
        else if (key === 39) {
            this.handleRightClick();
        }
        else if (key === 40) {
            this.handleDownClick();
        }

        this.setState({ counter: this.state.counter + 1 });
    }

    onUp(e) {
        //If we hold down for a long time, sometimes it does not update all that we have changed, so 
        //do that now
        if (this.state.counter > 1) {
            this.changeBox(this.state.value, false);
        }

        this.setState({ counter: 0, increment: 25 });
    }

    handleUpClick(e, value) {
        const sourceBox = this.state.value.sourceBox;

        //If the user defined a value, lets just change it to that vlaue
        if (value !== undefined) {
            value += this.state.increment;
        }
        else {
            value = this.state.value.sourceBox.y;
        }

        this.handleChange(sourceBox.x, value - this.state.increment, sourceBox.width, sourceBox.height);
    }

    handleDownClick(e, value) {
        const sourceBox = this.state.value.sourceBox;

        //If the user defined a value, lets just change it to that vlaue
        if (value !== undefined) value -= this.state.increment;
        else value = this.state.value.sourceBox.y;

        this.handleChange(sourceBox.x, value + this.state.increment, sourceBox.width, sourceBox.height);
    }

    handleRightClick(e, value) {
        const sourceBox = this.state.value.sourceBox;

        //If the user defined a value, lets just change it to that vlaue
        if (value !== undefined) value -= this.state.increment;
        else value = this.state.value.sourceBox.x;

        this.handleChange(value + this.state.increment, sourceBox.y, sourceBox.width, sourceBox.height);
    }

    handleLeftClick(e, value) {
        const sourceBox = this.state.value.sourceBox;

        //If the user defined a value, lets just change it to that vlaue
        if (value !== undefined) value += this.state.increment;
        else value = this.state.value.sourceBox.x;

        this.handleChange(value - this.state.increment, sourceBox.y, sourceBox.width, sourceBox.height);
    }

    handleWidth(value)
    {
        const sourceBox = this.state.value.sourceBox;
        let height = sourceBox.height;

        if (this.state.keepAspect) {
            const ratio = sourceBox.height / sourceBox.width;

            if (!this.props.float)
                height = Math.round(ratio * value);
            else
                height = ratio * value;
        }

        this.handleChange(sourceBox.x, sourceBox.y, value, height);
    }

    handleHeight(value) {
        const sourceBox = this.state.value.sourceBox;
        let width = sourceBox.width;

        if (this.state.keepAspect) {
            const ratio = sourceBox.width / sourceBox.height;

            if (!this.props.float)
                width = Math.round(ratio * value);
            else
                width = ratio * value;
        }

        this.handleChange(sourceBox.x, sourceBox.y, width, value);
    }

    handlePersonWidth(value) {
        const sourceBox = this.state.value.sourceBox;
        const size = this.state.value.size;

        this.handleChange(sourceBox.x, sourceBox.y, sourceBox.width, sourceBox.height, value, size.height);
    }

    handlePersonHeight(value) {
        const sourceBox = this.state.value.sourceBox;
        const size = this.state.value.size;

        this.handleChange(sourceBox.x, sourceBox.y, sourceBox.width, sourceBox.height, size.width, value);
    }

    handleGrow(value) {
        const sourceBox = this.state.value.sourceBox;
        this.handleChange(sourceBox.x, sourceBox.y, null, null, value);
    }

    handleChange(x, y, width, height, personWidth, personHeight) {        
        const temp = Copy(this.state.value);

        temp.sourceBox.x = x;
        temp.sourceBox.y = y;

        if (width)
            temp.sourceBox.width = width;
        if (height)
            temp.sourceBox.height = height;

        if (personWidth)
            temp.size.width = personWidth;
        if (personHeight)
            temp.size.height = personHeight;

        const increment = this.state.counter > 10 ? 75 : this.state.increment;

        this.setState({ value: temp, increment: increment, hitSubmit: false });

        this.changeBox(temp);
    }

    onDebugCheck(e)
    {
        this.setState({ isDebug: e.target.checked });

        this.changeBox(this.state.value);
    }

    handleFlipXChange(value) {
        const temp = Copy(this.state.value);
        temp.flipX = value;

        this.setState({ value: temp });

        this.changeBox(temp);
    }

    handleFlipYChange(value) {
        const temp = Copy(this.state.value);
        temp.flipY = value;

        this.setState({ value: temp });

        this.changeBox(temp);
    }

    handleRotation(value) {
        const temp = Copy(this.state.value);
        temp.rotationAngle = value;

        this.setState({ value: temp });

        this.changeBox(temp);
    }

    handleBlendChange(value) {
        const temp = Copy(this.state.value);
        temp.blendMode = value;

        this.setState({ value: temp });
        this.changeBox(temp);
    }

    handleSubmit(e) {
        this.props.onChange(this.state.value);

        this.setState({ hitSubmit: true })
    }

    
    render() {
        if (this.state.value === null || this.state.value.sourceBox === undefined) return <div className="edit-source_wrapper">loading...</div>

        return <div className="edit-source_wrapper">
            <div className="edit-active_image">
                {this.state.activeImage !== "" && <img src={this.state.activeImage} alt="active image"/>}
            </div>
            <input type='checkbox' checked={this.state.isDebug} onChange={this.onDebugCheck} />
            <div className="edit-edit_source_wrapper">                     
                <div className="edit-input_source_wrapper">
                    <div className="edit-row">
                        <Input type="number" value={this.state.value.sourceBox.x} onBlur={(value) => this.handleRightClick(null, value)}>
                            X
                        </Input>

                        <Input type="number" value={this.state.value.sourceBox.y} onBlur={(value) => this.handleUpClick(null, value)}>
                            Y
                        </Input>
                    </div>
                    {this.state.value.sourceBox.grow === undefined && <div className="edit-row">
                        <Input type="number" value={this.state.value.sourceBox.width} onBlur={(value) => this.handleWidth(value)}>
                            Width
                        </Input>
                        <Input type="number" value={this.state.value.sourceBox.height} onBlur={(value) => this.handleHeight(value)}>
                            Height
                        </Input>
                        <Input type='checkbox' value={this.state.keepAspect} onChange={(value) => this.setState({ keepAspect: value })}>
                            Keep Asp Ratio
                        </Input>
                    </div>}
                    {this.state.value.size !== undefined && <div className="edit-row">
                        <Input type="number" value={this.state.value.size.width} onBlur={(value) => this.handlePersonWidth(value)}>
                            Person Width
                        </Input>
                        <Input type="number" value={this.state.value.size.height} onBlur={(value) => this.handlePersonHeight(value)}>
                            Person Height
                        </Input>
                    </div>}
                    {this.state.value.sourceBox.grow !== undefined && <div className="edit-row">
                        <Input type="number" value={this.state.value.sourceBox.grow} onBlur={(value) => this.handleGrow(value)}>
                            Grow
                        </Input>
                    </div>}
                    {this.state.value.flipX !== undefined && <div className="edit-row">
                        <Input type='checkbox' value={this.state.value.flipX} onChange={(checked) => this.handleFlipXChange(checked)}>
                            Flip X
                        </Input>
                        <Input type='checkbox' value={this.state.value.flipY} onChange={(checked) => this.handleFlipYChange(checked)}>
                            Flip Y
                        </Input>
                        <Input type="number" value={this.state.value.rotationAngle} onBlur={(value) => this.handleRotation(value)}>
                            Rotation Angle
                        </Input>
                    </div>}
                    {this.state.value.blends !== undefined && <div className="edit-row">
                        <Dropdown items={this.state.value.blends} value={this.state.value.blendMode} onChange={(value) => this.handleBlendChange(value)}>
                            Blend Mode
                        </Dropdown>
                        </div>}
                    <div className='edit-row'>
                        <button onClick={this.handleSubmit} disabled={this.state.hitSubmit} > Submit</button>
                    </div>
                </div>
                <div className="edit-move_buttons_wrapper">
                    <div className="edit-move_buttons">
                        <div className="edit-move_buttons_row">
                            <div className="edit-move_button_wrapper">
                                <button className="edit-move_button" onClick={this.handleUpClick}>↑</button>
                            </div>
                        </div>
                        <div className="edit-move_buttons_row">
                            <div className="edit-move_button_wrapper">
                                <button className="edit-move_button" onClick={this.handleLeftClick}>←</button>
                                <button className="edit-move_button" onClick={this.handleRightClick}>→</button>
                            </div>
                        </div>
                        <div className="edit-move_buttons_row">
                            <div className="edit-move_button_wrapper">
                                <button className="edit-move_button" onClick={this.handleDownClick}>↓</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    }

    async changeBox(value, waitForLoading = true) {
        if (waitForLoading && this.state.loading) return;

        if (value === null) return;

        this.setState({ loading: true });

        const response = await this.props.post(value, this.state.isDebug);

        this.setState({
            activeImage: response.activeImage, loading: false
        });
    }
}

EditSourceRect.defaultProps = {
    float: false
}