import React from 'react';

export default function Form(props)
{
    const onSubmit = (e) => {
        if (props.preventDefault) {
            e.preventDefault();
        }

        if (props.onSubmit)
            props.onSubmit();
    }

    const className = props.className ? props.className : "";
    return <form className={`${className} payment form`} onSubmit={onSubmit} >
        {props.children}
    </form>
}

export function Fieldset(props) {
    const children = React.Children.map(props.children, child => {
        return child.props.field ? React.cloneElement(child, { ...child.props, className: `${child.props.className ? child.props.className : ""} form-row` }) : child;
    });

    const className = props.className ? props.className : "";
    return <fieldset className={`${className} form-group`} style={props.style}>
        {children}
    </fieldset>
}

export function Field({ children,
    className,
    id,
    type,
    placeholder,
    required,
    autoComplete,
    value,
    onChange,
    field = true}) {
    return <div className={`${className ? className : ""}`}>
        
            <label htmlFor={id} className="form-row-label">
                {children}
            </label>
            <input
                className="form-row-input payment"
                id={id}
                type={type}
                placeholder={placeholder}
                required={required}
                autoComplete={autoComplete}
                value={value}
                onChange={onChange}
            />
        
    </div>

}

Field.defaultProps = {
    field: true
}

export function FieldDisplay(props) {
    return <div className={`${props.className ? props.className : ""}`}>

        <label className="form-row-label">
            {props.name}
        </label>
        <label className="form-row-label" style={{ textAlign: "right", marginBottom: "0", paddingRight: "15px" }}>
            {props.children}
        </label>
    </div>
}

FieldDisplay.defaultProps = {
    field: true
}

export function Checkbox(props) {
    return <div className={`${props.className ? props.className : ""}`}>
        <label className="form-row-label">
            {props.children}
        </label>
        <input className="form-row-input-checkbox" type="checkbox" checked={props.checked} onChange={props.onChange} />
        </div>
}

Checkbox.defaultProps = {
    field: true
}

export function Header(props) {
    return <h4 className="form-row-label" style = {{ marginLeft: "15px", paddingTop: "0px" }}>{props.children}</h4>
}

