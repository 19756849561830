import React, { useEffect, useState } from 'react';
import { ColorField } from './ColorField';
import { Dropdown, DropdownDropdown, DropdownButton, Input } from '../../util/Dropdown';
import { NameText } from '../../util/NameText';
import { Button, ToggleButton, ToggleExpand } from '../../util/ToggleButton';
import getInfoFromServer, { Copy, isEqual, postInfoToServer } from '../../util/util';
import RenderPopup from '../../popup/Popup';
import AlertBox from '../../popup/AlertBox';
import { ImageFile } from '../../util/ImageFile';
import { ConfirmButton } from '../../util/ConfirmButton';
import Loading from '../../icons/Loading';

const getImagesFromFile = (files, colors, initialImages, initialIsSameAsThumbnail) => {
    const temp = initialImages !== undefined ? initialImages : [];
    const sameAsThumbnail = initialIsSameAsThumbnail !== undefined ? initialIsSameAsThumbnail : false;

    for (var i = 0; i < files.length; i++) {
        const curr = files[i];
        let indexOfColor = curr.name.lastIndexOf('-');

        let color = indexOfColor !== -1 ? curr.name.slice(indexOfColor + 1, curr.name.length - 4) : "None";

        if (colors !== undefined && colors !== null) {
            const _colors = colors.map(x => x.color);
            if (!_colors.includes(color)) {
                indexOfColor = -1;
                color = "None";
            }
        }

        const name = indexOfColor !== -1 ? curr.name.slice(0, indexOfColor) : curr.name.slice(0, -4);

        const thumbnail = curr.image;

        const type = "Etsy.ServiceModel.CoreObjects.Color, ServiceModel";

        const index = temp.findIndex(x => x.name === name);
        const newColor = {
            __type: type,
            name: color, thumbnail: thumbnail, isThumbnail: sameAsThumbnail
        }

        if (index === -1) {
            temp.push({ name: name, colors: [newColor], id: -1 });
        }
        else {
            temp[index].colors = [...temp[index].colors, newColor];
        }          
    }

    return temp;
}

export function EditImages(props)
{
    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState(0);

    
    const UploadColors = async (items) => {
        setLoading(true);
        let count = 0;
        let currCount = 1;

        for (var i = 0; i < items.length; i++) {
            count += items[i].colors.length;
        }

        for (var i = 0; i < items.length; i++) {
            const name = items[i].name;
            let id = items[i].id;
            for (var j = 0; j < items[i].colors.length; j++) {
                const color = items[i].colors[j];
                const status = (currCount * 1.0 / count) * 100
                setLoadingStatus(status);
                setLoadingMsg(`Uploading ${name}-${color.name}`);

                const response = await postInfoToServer("edit/Submit/ImageColor", {
                    name: name, id: id, color: color
                });

                if (response.hasError) {
                    RenderPopup(<AlertBox dismissable><h4>Invalid Submission</h4><p>{response.message}</p></AlertBox>);
                    setLoading(false);
                    return;
                }

                id = response.id;

                currCount++;
            }
        }

        setLoading(false);
    }

    

    if (loading) return <Loading message={loadingMsg} status={loadingStatus} />

    return <div>
        <h2>Edit Images</h2>

        <ToggleButton>
            <Button id="new" name="New">
                <New onSubmit={UploadColors}/>
            </Button>
            <Button id="edit" name="Edit">
                <Edit onSubmit={UploadColors} />
            </Button>            
        </ToggleButton>
    </div>
}

function Edit(props) {
    const [images, setImages] = useState([]);
    const [index, setIndex] = useState(-1);
    const [colors, setColors] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const initialize = async () => {
            const response = await getInfoFromServer("edit/Start/Image");

            setImages(response.images);
            setColors(response.colors);
            setLoading(false);
        }

        initialize();
    }, []);

    const onSelect = (value) => {
        setIndex(value);
    }

    const onNameBlur = (value) => {
        const temp = Copy(images);
        temp[index].name = value;

        setImages(temp);
    }

    const onColorChange = (colors) => {
        let canSubmit = true;
        let numNone = 0;
        const temp = Copy(images);


        //We can only submit if the user has entered in a file for every image and thumbnail
        for (var i = 0; i < colors.length; i++) {
            if (colors[i].thumbnail === "") {
                canSubmit = false;
                break;
            }

            if (colors[i].name === "None") {
                numNone++;
            }

            if (numNone > 1) {
                canSubmit = false;
                break;
            }
        }

        temp[index].colors = colors;
        setImages(temp);        
    }

    const onStartChange = (files) => {
        const temp = Copy(images);

        const _images = getImagesFromFile(files);

        if (temp[index].colors[0].thumbnail === "") {
            temp[index].colors = _images[0].colors;
        }
        else {
            temp[index].colors = temp[index].colors.concat(_images[0].colors);
        }

        setImages(temp);
    }

    const onSubmit = async (e) => {
        await props.onSubmit([curr]);

        const response = await postInfoToServer("edit/Submit/Images", {
            image: curr
        });

        if (response.hasError) {
            RenderPopup(<AlertBox dismissable><h4>Invalid Submission</h4><p>{response.message}</p></AlertBox>);
        }

        setLoading(false);
        setIndex(-1);
        setImages(response.images);
    }

    const onDelete = async (e) => {
        setLoading(true);
        const response = await postInfoToServer("edit/Delete/" + curr.id);

        setLoading(false);
        setIndex(-1);
        setImages(response.images);
    }

    if (loading) return <Loading/>

    const curr = index > -1 ? images[index] : {}

    return <>
        <Dropdown value={index} items={images.map(x => x.name)} onChange={onSelect}>
            <span>Select an Image to edit</span>
        </Dropdown>
        {index > -1 && <div>
            <ImageFile onChange={onStartChange} multiple>
                Add color to image
            </ImageFile>
            <NameText name="Name" value={curr.name} onBlur={onNameBlur} />
            <ColorField onChange={onColorChange} items={curr.colors} colors={colors} />
            <ConfirmButton onConfirm={onDelete} msg="Are you sure you want to delete this image?">Delete</ConfirmButton>
            <ConfirmButton onConfirm={onSubmit} msg="Are you sure you want to submit this image?">Submit</ConfirmButton>
        </div>}
        </>
}

function New(props) {
    const [loading, setLoading] = useState(true);
    const [images, setImages] = useState([]);
    const [colors, setColors] = useState([]);
    const [sameAsThumbnail, setSameAsThumbnail] = useState(false);

    useEffect(() => {
        const initialize = async () => {
            const response = await getInfoFromServer('edit/Start/Color');

            setColors(response.colors);
            setLoading(false);
        }

        initialize();
    }, []);

    const onFolderSelect = (files, isNew) => {
        let temp = isNew ? [] : Copy(images);
        const items = getImagesFromFile(files, null, temp, sameAsThumbnail);

        setImages(items);
    }

    const onSameAsThumbnailChange = (value) => {
        setSameAsThumbnail(value);
    }

    const onImageRemove = (id) => {
        const temp = Copy(images);

        temp.splice(id, 1);

        setImages(temp);
    }

    const onImageChange = (items) => {
        setImages(items);
    }

    const onSubmit = async (e) =>
    {
        await props.onSubmit(images);
    }

    if (loading) return <Loading />

    return <div>
        <h5>Select Images to Upload</h5>
        {images.length === 0 && <Input.div type="checkbox" value={sameAsThumbnail} onChange={onSameAsThumbnailChange}>
            Same as Thumbnail
        </Input.div>}
        <StartImage onChange={onFolderSelect} />
        {images.length > 0 && <div>
            <ListImages items={images} onChange={onImageChange} onRemove={onImageRemove} colors={colors} sameAsThumbnail={sameAsThumbnail}/>
            <div>
                <ConfirmButton onConfirm={onSubmit} msg="Are you sure you want to submit these images?">Submit</ConfirmButton>
            </div>
        </div>}

    </div>

}

function ListImages(props) {
    const onRemove = (id) => {
        props.onRemove(id);
    }

    const onChange = (item, id) => {
        const temp = Copy(props.items);

        temp[id] = item;

        props.onChange(temp);
    }

    return <>
        {props.items.map((item, i) => <ListImage id={i} item={item} onChange={onChange} onRemove={onRemove} colors={props.colors} sameAsThumbnail={props.sameAsThumbnail} />)}
    </>
}

function ListImage(props)
{
    const [sameAsThumbnail, setSameAsThumbnail] = useState(props.sameAsThumbnail);
    const onRemove = (e) => {
        props.onRemove(props.id);
    }

    const onColorChange = (colors) => {
        const temp = Copy(props.item);

        temp.colors = colors;

        props.onChange(temp, props.id);
    }

    const onNameBlur = (value) => {
        const temp = Copy(props.item);

        temp.name = value;

        props.onChange(temp, props.id);
    }

    const onSameAsThumbnailChange = (value) => {
        setSameAsThumbnail(value);
        const temp = Copy(props.item);

        for (var i = 0; i < temp.colors.length; i++) {
            temp.colors[i].isThumbnail = value;
        }

        props.onChange(temp, props.id);
    }

    return <div>
        <h5>{props.item.name}</h5>
        <NameText value={props.item.name} onBlur={onNameBlur} />
        <Input.div type="checkbox" value={sameAsThumbnail} onChange={onSameAsThumbnailChange}>
            Same as Thumbnail
        </Input.div>
        <button onClick={onRemove}>Remove</button>
        <ToggleExpand>
            <Button id="expand" before="Show" after="Don't Show">
                <ColorField items={props.item.colors} colors={props.colors} onChange={onColorChange} />
            </Button>
        </ToggleExpand>        
    </div>
}

function StartImage(props)
{
    const [isNew, setIsNew] = useState(true);

    const onToggleClick = (state) => {
        setIsNew(state === "new");
    }

    const onChange = (files) => {
        props.onChange(files, isNew);
    }

    return <div>
            <ToggleButton onClick={onToggleClick} mode="new">
                <Button id="new" name="New"/>
                <Button id="add" name="Add"/>
            </ToggleButton>
            <ImageFile onChange={onChange} multiple>
                Select Image to start
            </ImageFile>
        </div>
}

function ImageTypeToggle(props) {
    return <div>
        <ToggleButton mode={props.type.includes("Text") ? "text" : "image"} onClick={props.onClick}>
            <Button id="image" name="Image">
                <ColorField onChange={props.onColorChange} items={props.urls} colors={props.colors} />            
            </Button>
            <Button id="text" name="Text">
                <FontField items={props.urls} fonts={props.fonts} onChange={props.onFontChange}
                    colors={props.fontColors} onColorChange={props.onFontColorChange} />
            </Button>
        </ToggleButton>
        </div>
}

function FontField(props)
{
    const [key, setKey] = useState(-1);
    const [colorKey, setColorKey] = useState(-1);

    const onFontDropdown = (value) => {
        setKey(value);
    }

    const onFontDelete = (value) =>
    {
        const temp = Copy(props.items);
        const index = temp.findIndex(x => value === x.name);

        temp.splice(index, 1);
        props.onChange(temp);
    }

    const onFontSelect = (value) =>
    {
        const temp = Copy(props.items);
        const newFont = props.fonts.find(x => x.name === value);
        temp.push(newFont);
        props.onChange(temp);
    }

    const onColorChange = async (i, colors) =>
    {
        //if we are either adding or deleting
        if (colors !== undefined)
        {            
            props.onColorChange(colors);
        }
        else {
            setColorKey(i);
        }
    }

    return <div>
        
        <DropdownDropdown items={props.items.map(x => x.name)} allItems={props.fonts.map(x => x.name)} onChange={onFontDropdown} onNew={onFontSelect} onDelete={onFontDelete}>
            Add Fonts:
        </DropdownDropdown>
        <DropdownButton value={colorKey} items={props.colors} onChange={onColorChange}>
            Add Colors:
        </DropdownButton>
        </div>
}
