import React, { Component, useEffect, useState } from 'react'
import { NameText } from '../../util/NameText';
import { Button, ToggleButton } from '../../util/ToggleButton';
import RenderPopup from '../../popup/Popup';
import { ConfirmButton } from '../../util/ConfirmButton';
import Loading from '../../icons/Loading';
import { EditSourceRect } from './EditSourceRect';
import { DragAndDrop, DragAndDropButton, Dropdown, Input } from '../../util/Dropdown';
import getInfoFromServer, { Copy, isEqual, postInfoToServer } from '../../util/util';
import { CustomFunctions } from '../../CustomFunctions';
import { Container } from './Edit';

export default function EditPerson(props)
{
    const [index, setIndex] = useState(-1);
    const [people, setPeople] = useState([]);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    const [selectedTypes, setSelectedTypes] = useState([]);
    const [blends, setBlends] = useState([]);

    useEffect(() => {
        const initialize = async () => {
            const response = await getInfoFromServer('edit/Start/Person');

            setPeople(response.people);
            setImages(response.images);
            setBlends(response.blends);
            setLoading(false);
        }

        initialize();
    }, []);

    useEffect(() => {
        if (index > -1) {
            setSelectedTypes(people[index].imageTypes.map(x => x.key));
        }
    }, [index]);

    const onLooksLikeChange = (person) => {
        const temp = Copy(people);
        const name = temp[index].name;
        const id = temp[index].id;

        temp[index] = person;
        temp[index].name = name;
        temp[index].id = id;

        setPeople(temp);
        setSelectedTypes(temp[index].imageTypes.map(x => x.key));
    }

    const onToggleClick = (mode) =>
    {
        if (mode === 'new') {
            const temp = Copy(people);
            const newPerson = {
                name: "New Person", id: people[people.length - 1].id + 1, sourceBox: { width: 1000, height: 1000 },
                imageTypes: []
            }

            temp.push(newPerson);

            setPeople(temp);
            setIndex(temp.length - 1);
        }
    }

    const onSelect = (value) => {
        setIndex(value);
    }

    const onNameBlur = (value) => {
        const temp = Copy(people);
        temp[index].name = value;

        setPeople(temp);
    }

    const onImageTypeChange = (items, _index, personSize) => {
        const temp = Copy(people);
        temp[index].imageTypes = items;

        if (personSize)
            temp[index].sourceBox = personSize;

        setPeople(temp);
    }

    const post = (items, isDebug, personSize) =>
    {
        const temp = Copy(person);
        temp.imageTypes = items;
        temp.sourceBox = personSize;

        return postInfoToServer('edit/ImageLocation', { person: temp, isDebug: isDebug });
    }

    const postSize = (value, isDebug) =>
    {
        const temp = Copy(person);

        //Loop through all of the items and increment their location
        for (var i = 0; i < temp.imageTypes.length; i++) {
            if (selectedTypes.findIndex(x => x === temp.imageTypes[i].key) !== -1)
            {
                for (var j = 0; j < temp.imageTypes[i].subTypes.length; j++)
                {
                    for (var k = 0; k < temp.imageTypes[i].subTypes[j].items.length; k++)
                    {                        
                        const item = temp.imageTypes[i].subTypes[j].items[k];

                        item.location.width = Math.round(item.location.width * value.sourceBox.width);
                        item.location.height = Math.round(item.location.height * value.sourceBox.height);
                        item.location.x = Math.round(item.location.x * value.sourceBox.width);
                        item.location.y = Math.round(item.location.y * value.sourceBox.height);
                        item.location.x += value.sourceBox.x;
                        item.location.y += value.sourceBox.y;
                        item.flipX = value.flipX;
                        item.flipX = value.flipY;
                        item.rotationAngle += value.rotationAngle;
                    }
                }
            }
        }

        return post(temp.imageTypes, isDebug, {
            width: value.size.width, height: value.size.height
        });
    }

    const onEditSize = (e) =>
    {
        const value = {
            sourceBox: {
                x: 0, y: 0,
                width: 1, height: 1                
            },
            size: { width: person.sourceBox.width, height: person.sourceBox.height },
            flipX: false,
            flipY: false,
            rotationAngle: 0
        }

        RenderPopup(<EditSourceRect onChange={onSizeSubmit} value={value} post={postSize} dismisable />);
    }

    const onSizeSubmit = (value) => {
        const temp = Copy(people);

        //Loop through all of the items and increment their location
        for (var i = 0; i < temp[index].imageTypes.length; i++) {
            if (selectedTypes.findIndex(x => x === temp[index].imageTypes[i].key) !== -1) {
                for (var j = 0; j < temp[index].imageTypes[i].subTypes.length; j++)
                {
                    for (var k = 0; k < temp[index].imageTypes[i].subTypes[j].items.length; k++) {
                        const item = temp[index].imageTypes[i].subTypes[j].items[k];

                        item.location.width = Math.round(item.location.width * value.sourceBox.width);
                        item.location.height = Math.round(item.location.height * value.sourceBox.height);
                        item.location.x = Math.round(item.location.x * value.sourceBox.width);
                        item.location.y = Math.round(item.location.y * value.sourceBox.height);
                        item.location.x += value.sourceBox.x;
                        item.location.y += value.sourceBox.y;
                        item.flipX = value.flipX;
                        item.flipX = value.flipY;
                        item.rotationAngle += value.rotationAngle;
                    }
                }
            }
        }

        temp[index].sourceBox = {
            width: value.size.width, height: value.size.height
        }

        setPeople(temp);
    }

    const onTypeSelect = (checked, index) => {
        const temp = Copy(selectedTypes);
        const type = person.imageTypes[index];

        if (checked) {
            temp.push(type.key);
        }
        else {
            temp.splice(temp.findIndex(x => x === type.key), 1);
        }

        setSelectedTypes(temp);
    }

    const onDelete = async (e) => {
        const response = await postInfoToServer('edit/Delete/Person', { id: person.id });

        setPeople(response.people);
        setIndex(response.key);
    }

    const onSubmit = async (e) =>
    {
        setLoading(true);
        const response = await postInfoToServer('edit/Submit/Person', { person: person });
        setLoading(false);
    }

    const person = index > -1 ? people[index] : {}

    if (index > -1)
        person.imageTypes.sort((a, b) => a.key - b.key);

    if (loading) return <Loading/>

    return <div>
        <h5>Edit Person</h5>
        <ToggleButton onClick={onToggleClick}>
            <Button id="new" name="New">
                <LooksLike items={people} onChange={onLooksLikeChange}/>
            </Button>
            <Button id="edit" name="Edit">
                <Dropdown value={index} items={people.map(x => x.name)} onChange={onSelect}>
                    <span>Select Person to edit</span>
                </Dropdown>
            </Button>
            {index > -1 && <>
                <NameText name="Name" value={person.name} onBlur={onNameBlur} />
                <SelectItems items={person.imageTypes} keys={selectedTypes} onChange={onTypeSelect} />
                <div><button onClick={onEditSize}>Edit Size</button></div>
                <EditImageType items={person.imageTypes} onChange={onImageTypeChange} post={post} images={images} sourceBox={person.sourceBox} blends={blends}/>
                <ConfirmButton onConfirm={onDelete} msg="Are you sure you want to delete this person?">Delete</ConfirmButton>
                <ConfirmButton onConfirm={onSubmit} msg="Are you sure you want to submit this person?">Submit</ConfirmButton>
            </>}
        </ToggleButton>
    </div>
}

function LooksLike(props)
{
    const [index, setIndex] = useState(-1);

    const onSelect = (value) => {
        setIndex(value);
        props.onChange(props.items[value]);
    }

    return <div>
        <Dropdown items={props.items.map(x => x.name)} value={index} onChange={onSelect}>
            Looks Like:
        </Dropdown>
    </div>
}

export function EditImageType({ hasNew = true, custom = false, ...props })
{
    const [index, setIndex] = useState(-1);

    useEffect(() => {
        if (index > props.items.length - 1) {
            setIndex(props.items.length - 1);
        }
    }, [props.items]);

    const onToggleClick = (mode) => {
        if (mode === 'new') {
            const temp = Copy(props.items);
            const newType = {
                name: "New Type", key: props.items.length > 0 ? props.items[props.items.length - 1].key + 1 : 0,
                subTypes: [], canOmit: false, included: true
            }
            temp.push(newType);

            props.onChange(temp, index);
            setIndex(temp.length - 1);
        }
    }

    const onSelect = (value) => {
        setIndex(value);
    }

    const onNameBlur = (value) => {
        const temp = Copy(props.items);
        temp[index].name = value;

        props.onChange(temp, index);
    }

    const onKeyChange = (items, newKey, newIndex) => {
        setIndex(newIndex);
        props.onChange(items, index);
    }

    const onImageItemsChange = (items, personSize, itemIndex) => {
        const temp = Copy(props.items);
        temp[index].subTypes = items;

        props.onChange(temp, index, personSize, itemIndex);
    }

    const onOmitCheck = (value) => {
        const temp = Copy(props.items);
        temp[index].canOmit = value;

        //If they cannot omit this type, set included to true
        if (!value)
            temp[index].included = true;

        props.onChange(temp, index);
    }

    const onIncludedCheck = (value) => {
        const temp = Copy(props.items);
        temp[index].included = value;

        props.onChange(temp, index);
    }

    const onDelete = (e) => {
        const temp = Copy(props.items);

        temp.splice(index, 1);

        props.onChange(temp, -1);
    }


    const post = (items, isDebug, personSize, itemIndex) =>
    {
        const temp = Copy(props.items);
        temp[index].subTypes = items;
        temp[index].included = true;

        return props.post(temp, isDebug, personSize, index, itemIndex);
    }

    if (index > props.items.length - 1) {
        return <></>;
    }

    const curr = index > -1 ? props.items[index] : {}

    return <Container name="Edit Image Type">
        <ToggleButton onClick={onToggleClick}>
            {hasNew && <Button id="new" name="New" />}
            <Button id="edit" name="Edit">
                <Dropdown value={index} items={props.items.map(x => x.name)} onChange={onSelect}>
                    <span>Select Image Type</span>
                </Dropdown>
            </Button>
            {index > -1 && <div>
                <NameText name="Name" value={curr.name} onBlur={onNameBlur} />
                <DragAndDrop value={curr.key} items={props.items} onChange={onKeyChange} onClick={onSelect} />
                <Input.div type="checkbox" value={curr.canOmit} onChange={onOmitCheck}>Can Omit</Input.div>
                {curr.canOmit && <Input.div type="checkbox" value={curr.included} onChange={onIncludedCheck}>Set Initial Included</Input.div>}
                <div><button onClick={onDelete}>Delete</button></div>
                <EditSubTypeItems items={curr.subTypes} onChange={onImageItemsChange} post={post} images={props.images} sourceBox={props.sourceBox} blends={props.blends} custom={custom} objects={props.objects} />
            </div>}
        </ToggleButton>
    </Container>
}

function EditSubTypeItems(props) {
    const [index, setIndex] = useState(-1);

    useEffect(() => {
        if (props.items.length <= index) {
            setIndex(props.items.length - 1);
        }
    }, [props.items]);

    const onToggleClick = (mode) => {
        if (mode === 'new') {
            const newKey = props.items.length;
            const newSubType = { items: [], key: newKey, name: "New Sub Type" };
            const temp = Copy(props.items);

            temp.push(newSubType);

            props.onChange(temp);
            setIndex(newKey);
        }
    }

    const onSelect = (value) => {
        setIndex(value);
    }

    const onNameBlur = (value) => {
        const temp = Copy(props.items);
        temp[index].name = value;

        props.onChange(temp);
    }

    const onKeyChange = (items, newKey, newIndex) => {
        setIndex(newIndex);
        props.onChange(items);
    }

    const onImageItemsChange = (items, personSize, itemIndex) => {
        const temp = Copy(props.items);
        temp[index].items = items;

        props.onChange(temp, personSize, itemIndex);
    }

    const post = (items, isDebug, personSize, itemIndex) => {
        const temp = Copy(props.items);
        temp[index].items = items;
        
        return props.post(temp, isDebug, personSize, itemIndex);
    }

    if (index > props.items.length - 1) {
        return <div>loading...</div>;
    }

    const curr = index > -1 ? props.items[index] : {};
    return <Container name="Edit Sub Image">
        <ToggleButton onClick={onToggleClick}>
        <Button id='new' name="New" />
        <Button id='edit' name="Edit">
            <Dropdown items={props.items.map(x => x.name)} value={index} onChange={onSelect}>
                Edit Sub Type
            </Dropdown>
        </Button>
        {index > -1 && <>
            <NameText name="Name" value={curr.name} onBlur={onNameBlur} />
            <DragAndDrop value={curr.key} items={props.items} onChange={onKeyChange} onClick={onSelect} />
            <EditImageItems items={curr.items} onChange={onImageItemsChange} post={post} images={props.images} sourceBox={props.sourceBox} blends={props.blends} custom={props.custom} objects={props.objects} />
        </>}
        </ToggleButton>
        </Container>
}

function EditImageItems(props)
{
    const [index, setIndex] = useState(-1);
    const [images, setImages] = useState(props.images ? props.images : []);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const initialize = async () => {
            const response = await getInfoFromServer('edit/Start/Person');

            setImages(response.images);
            setLoading(false);
        }

        if (props.images === undefined)
            initialize();
        else
            setLoading(false);
    }, []);
    useEffect(() => {
        if (index > props.items.length - 1) {
            setIndex(props.items.length - 1);
        }
    }, [props.items]);

    const onToggleClick = (mode) => {
        if (mode === 'new') {
            const temp = Copy(props.items);
            const newImage = {
                name: images.find(x => x.id === 1).name, orderKey: props.items.length > 0 ? props.items[props.items.length - 1].orderKey + 1 : 0,
                id: 1, flipX: false, flipY: false, rotationAngle: 0, location: { width: 500, height: 500, x: 0, y: 0 }
            }
            temp.push(newImage);

            props.onChange(temp);
            setIndex(temp.length - 1);
        }
    }

    const onSelect = (value) => {
        setIndex(value);
    }

    const onNameBlur = (value) => {
        const temp = Copy(props.items);
        temp[index].name = value;

        props.onChange(temp);
    }

    const onKeyChange = (items, newKey, newIndex) => {
        setIndex(newIndex);
        props.onChange(items);
    }

    const onImageSelect = (e) => {
        const checked = e.target.checked;
        const id = Number(e.target.id);
        const temp = Copy(props.items);

        if (checked) {
            const sorted = Copy(props.items).sort((a, b) => a.orderKey - b.orderKey);

            const newImage =
            {                
                name: images.find(x => x.id === id).name, orderKey: sorted.length > 0 ? sorted[sorted.length - 1].orderKey + 1 : 0,
                imageId: id, flipX: false, flipY: false, rotationAngle: 0, location: { width: 500, height: 500, x: 0, y: 0 },
                blendMode: 0, tiedToValue: -1
            }
            temp.push(newImage);            
        }
        else {
            temp.splice(props.items.findIndex(x => x.imageId === id), 1);
        }

        props.onChange(temp);
    }

    const onLocationClick = (e) =>
    {
        let value = {
            sourceBox: curr.location, flipX: curr.flipX, flipY: curr.flipY, rotationAngle: curr.rotationAngle,
            blends: props.blends, blendMode: curr.blendMode
        }

        if (props.sourceBox) {
            value = { ...value, size: props.sourceBox }
        }

        RenderPopup(<EditSourceRect onChange={onLocationSubmit} value={value} post={post} dismisable />);
    }

    const onLocationSubmit = (value) => {
        const temp = Copy(props.items);
        temp[index].location = value.sourceBox;
        temp[index].flipX = value.flipX;
        temp[index].flipY = value.flipY;
        temp[index].rotationAngle = value.rotationAngle;
        temp[index].blendMode = value.blendMode;

        props.onChange(temp, value.size, index);
    }

    const post = (value, isDebug) => {
        const temp = Copy(props.items[index]);
        temp.location = value.sourceBox;
        temp.flipX = value.flipX;
        temp.flipY = value.flipY;
        temp.rotationAngle = value.rotationAngle;
        temp.blendMode = value.blendMode;

        return props.post([temp], isDebug, value.size, index);
    }

    const onTiedToValue = (value) => {
        const temp = Copy(props.items);
        temp[index].tiedToValue = images[value].id;

        props.onChange(temp);
    }

    const onTiedToValueDelete = () => {
        const temp = Copy(props.items);
        temp[index].tiedToValue = 0;

        props.onChange(temp);
    }

    const onBlendChange = (value) => {
        const temp = Copy(props.items);
        temp[index].blendMode = value;

        props.onChange(temp);
    }

    
    if (index > props.items.length - 1 || loading) {
        return <div>loading...</div>;
    }

    const curr = index > -1 ? props.items[index] : {}
    props.items.sort((a, b) => a.orderKey - b.orderKey);

    const dragItems = props.items.map(x => {
        x.name = x.name !== undefined ? x.name : images.find(y => y.id === x.imageId).name;
        return x;
    });
    const currImage = index > -1 ? images.find(x => x.id === curr.id) : {}
    const hasImages = props.images !== undefined;

    return <Container name="Edit Image">
        
            <Dropdown value={index} items={props.items.map(x => images.find(y => y.id === x.imageId).name)} onChange={onSelect}>
                <span>Select Image</span>
            </Dropdown>
            {index > -1 && <div>
            <NameText name="Name" value={curr.name} onBlur={onNameBlur} />
            <button onClick={onLocationClick}>Edit Location</button>
            {props.blends && <Dropdown items={props.blends} value={curr.blendMode} onChange={onBlendChange}>
                Blend Mode
            </Dropdown>}
            <Dropdown items={images.map(x => x.name)} value={images.findIndex(x => x.id === curr.tiedToValue)} onChange={onTiedToValue}>
                Select Tied To Value:
            </Dropdown>
            <div><button onClick={onTiedToValueDelete}>Delete Tied to value</button></div>
            <DragAndDrop label="Order Key" value={curr.orderKey} _key="orderKey" items={dragItems} onChange={onKeyChange} onClick={onSelect} />
            {props.custom && <CustomFunctions objects={props.objects} />}
            </div>}
           

        {hasImages && <SelectImage items={images} currAllImageKeys={props.items.map(x => x.imageId)} onChange={onImageSelect} />}        
    </Container>
}

export function SelectImage(props) {
    const [imageKeys, setImageKeys] = useState(Copy(props.currAllImageKeys));

    useEffect(() => {
        setImageKeys(Copy(props.currAllImageKeys));
    }, [props.currAllImageKeys]);

    return <>
        {props.title && <h5>{props.title}</h5>}
        <ul>
            {props.items.map((img, i) => {
                const index = imageKeys.findIndex(x => x === img.id);

                if (index !== -1) {
                    imageKeys.splice(index, 1);
                }

                return <li>
                    {img.thumbnail && <img src={img.thumbnail} title={img.name} />}
                    <div>
                        <input type="checkbox" checked={index !== -1} onChange={props.onChange} id={img.id} />
                        <span>{img.name}-{img.id}</span>
                    </div>
                    {props.children && index !== -1 && props.children(img, index)}
                </li>
            })}
        </ul>
    </>
}

function SelectItems(props)
{
    const onChange = (e) => {
        props.onChange(e.target.checked, Number(e.target.id));        
    }
    return <ul>
        {props.items.map((item, i) => {
            const index = props.keys.findIndex(x => x === item.key);
            return <li>
                <span>{item.name}</span>
                <input type="checkbox" checked={index !== -1} onChange={onChange} id={i} />
            </li>
        })}
        </ul>
}

