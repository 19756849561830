import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Footer } from './Footer';
import { NavMenu } from './NavMenu';
import { Popup } from '../popup/Popup';
import './Layout.css';

export class Layout extends Component
{
    static displayName = Layout.name;

    constructor(props) {
        super(props);

        this.state = {
            footer: { offsetHeight: 300 }
        }
    }

    render()
    {
        
        const style = { paddingBottom: `${this.state.footer.offsetHeight + 30}px` }

        return (<>
            <Popup id="popup" />
            <div className="wrapper">
                <div style={style}>
                    <NavMenu />
                    <Container>
                        {this.props.children}
                    </Container>
                </div>
                <Footer _ref={(ref) => this.setState({ footer: ref })} />
            </div>
    </>);
  }
}
