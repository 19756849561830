import React, { Component, useState, useRef } from 'react';
import { useEffect } from 'react';
import './ToggleButton.css';

export class ToggleButton extends Component
{
    constructor(props)
    {
        super(props);

        this.onClick = this.onClick.bind(this);

        const propsChildren = React.Children.toArray(this.props.children);

        const numOfButtons = propsChildren.findIndex((x) => x.type !== Button);
        let buttons = (numOfButtons !== -1) ? propsChildren.slice(0, numOfButtons) : propsChildren;
        let children = (numOfButtons !== -1) ? propsChildren.slice(numOfButtons, propsChildren.length) : [];

        this.state = {
            buttons: buttons,
            children: children,
            mode: buttons.findIndex(x => x.props.id === this.props.mode),
            resetMode: false
        };
    }

    reset = ()=>
    {
        this.setState({ mode: -1, resetMode: false });
    }

    onClick(index, id) {


        this.setState({
            mode: Number(index), resetMode: false
        });

        if (this.props.onClick)
            this.props.onClick(id);

    }

    getSnapshotBeforeUpdate(prevProps, prevState)
    {
        //If the mode changes, update
        if (prevProps.mode !== this.props.mode) {
            this.setState({
                mode: React.Children.toArray(this.props.children).findIndex(x => x.props.id === this.props.mode)
            });
        }
    }


    render()
    {
        const propsChildren = React.Children.toArray(this.props.children);

        const numOfButtons = propsChildren.findIndex((x) => x.type !== Button);

        let _children = [];
        let buttons = (numOfButtons !== -1) ? propsChildren.slice(0, numOfButtons) : propsChildren;
        //Updates the children
        if (this.state.mode !== -1 && propsChildren[this.state.mode].props.children) {
            const temp = (propsChildren[this.state.mode].props.children.length > 1) ? [...propsChildren[this.state.mode].props.children] : [propsChildren[this.state.mode].props.children];
            if (numOfButtons === -1) {
                _children = temp;
            }
            else {

                _children = temp.concat(propsChildren.slice(numOfButtons, propsChildren.length));
            }
        }
        else {
            if (numOfButtons !== -1) {
                _children = propsChildren.slice(numOfButtons, propsChildren.length);
            }
        }
        
        //Only reset the mode once
        if (!this.state.resetMode && this.props.show === "false") {
            this.setState({
                mode: -1, resetMode: true
            });
        }

        return <>
            {buttons.map((button, i) => {
                if (buttons.find(element => element.props.id === button.props.id && element !== button)) {
                    throw new DOMException("Each child element must have a unique id");
                }
                if (button.props.onClick) throw new DOMException("Button element cannot have onClick. Put in ToggeleButtons props");
                const selected = (this.state.mode === i);
                const className = (button.props.className) ? button.props.className + ((selected) ? " selected" : "") : ((selected) ? "selected" : "");
                return <span>
                    {React.cloneElement(button, {
                        className: className,
                        id: button.props.id,
                        index: i.toString(),
                        name: button.props.name,
                        selected: selected,
                        onClick: this.onClick

                    })}
                </span>
            })}
            {_children.map((child) => {

                if (this.state.mode !== -1 || this.props.show === "true")
                {
                    //If this child is a button expand and its id is selected, enable it
                    //(show its children)
                    
                    if (child.type === ButtonExpand) {

                        const childIds = child.props.id.split(',');
                        if (childIds.includes(buttons[this.state.mode].props.id)) {
                            return <div>{React.cloneElement(child, { disabled: false })}</div>
                        }
                    }
                    //Otherwise return this child how it is
                    else
                    {
                        return <div>{child}</div>
                    }
                }
                else { return <></> }

            })}
        </>
    }
}

ToggleButton.div = (props) => {
    return <div><ToggleButton {...props} /></div>
}

export function Button(props)
{
    if (!props.id) throw new DOMException("Button element does not have id props");
    
    const onClick = (e) =>
    {
        if (props.onClick)
           props.onClick(props.index, e.target.id);
    }
   
    return <>
        <button {...props} onClick={onClick}>{props.name}</button>

        </>
}

export function ButtonExpand(props)
{
    if (props.id === undefined) throw new DOMException("Must have an id");

    if (props.disabled) return <></>

    return <>
        {props.children}
    </>
}

ButtonExpand.defaultProps = {
    disabled: true
}

export function ToggleExpand(props) {
    const [children, setChildren] = useState(React.Children.toArray(props.children).map(child => {
        return {
            clicked: false, value: React.cloneElement(child, { ...child.props, name: child.props.before })
        }
    }));
    const toggleRef = useRef(null);

    useEffect(() => {
        setChildren(React.Children.toArray(props.children).map((child, i) =>
        {
            const currChild = children[i];
            return {
                clicked: currChild.clicked, value: React.cloneElement(child, { ...child.props, name: currChild.value.props.name })
            }
        }));
    }, [props.children]);

    const onClick = (mode) => {
        const temp = copyChildren();
        const currButton = temp.find(child => child.value.props.id === mode);
        currButton.clicked = !currButton.clicked;

        const newName = currButton.clicked ? currButton.value.props.after : currButton.value.props.before;
        currButton.value = React.cloneElement(currButton.value, { ...currButton.value.props, name: newName });

        if (!currButton.clicked) {
            toggleRef.current.reset();

        }

        setChildren(temp);

        if (props.onClick)
            props.onClick(mode);
    }

    const copyChildren = () => {
        const copy = [];

        for (var i = 0; i < children.length; i++) {
            copy.push({ clicked: children[i].clicked, value: children[i].value });
        }

        return copy;
    }

    return <>
        <ToggleButton onClick={onClick} ref={toggleRef}>
            {children.map(child => child.value)}
        </ToggleButton>
    </>
}