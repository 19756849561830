import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./Home.css";
import { ProfilePicture } from './Home';
import './ProfileInfo.css';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Loading from '../icons/Loading';
import getInfoFromServer from '../util/util';

export function ProfileInfo(props) {
	const [loading, setLoading] = useState(true);
	const [about, setAbout] = useState(null);

	useEffect(() => {
		const initialize = async () => {
			const response = await getInfoFromServer('/edit/Pages');

			const about = response.pages.find(x => x.name === 'About');

			if (!about) {
				throw DOMException("Cannot find home page");
			}

			setAbout(about);
			setLoading(false);
		}

		initialize();
	}, []);

	if (loading) return <Loading />

	
    return (
		<div id="about-page">
			<ReactMarkdown rehypePlugins={[rehypeRaw]}>{about.content}</ReactMarkdown>
        </div>
    );
}
