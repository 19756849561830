import React, { Component } from 'react';
import axios from 'axios';
import DownloadImage from '../util/DownloadImage';
import { Home } from './Home';
import RenderPopup, { ClosePopup } from '../popup/Popup';
import AlertBox from '../popup/AlertBox';
import EmailLink from '../icons/EmailLink';

export class FindImage extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            key: "", 
            material: "",
            size: "",
            message: "",
            loading: false
        }
        this.onChange = this.onChange.bind(this);
        this.onEnter = this.onEnter.bind(this);
    }

    onChange(e)
    {
        const value = e.target.value;

        this.setState({ key: value });

    }

    onEnter(e)
    {
        this.FindImage(this.state.key);
    }

    async FindImage(key, call = "FindImage/")
    {
        this.setState({ loading: true });
        const data = await FindImageDownload(key, call);

        if (data.message !== undefined) {
            this.setState({ message: data.message, material: "", size: "" });
        }
        else if (data.thumbnail !== "") {
            this.setState({ key: "", material: data.material, size: data.size });
        }
        
        this.setState({ loading: false });
    }

    async FindImage64(key)
    {        
        this.FindImage(key, "FindImage64/");        
    }

    getInfo(info)
    {
        return axios.get(info);
    }

    componentDidMount()
    {
        if (this.props.match.params.key !== undefined)
        {
            this.FindImage64(this.props.match.params.key);
        }
    }

    render()
    {
        //If they clicked on a build string url, do not display the text box
        if (this.props.match.params.key !== undefined) return <Home/>

        if (this.state.loading) return <p>loading...</p>
        const message = (this.state.material !== "") ?
            <div>
                <div>{"Material: " + this.state.material}</div>
                <div>{"Size: " + this.state.size}</div>
            </div> : <div className="red">{this.state.message}</div> 
        return <>
            <div>
                <h3>Enter Image Key</h3>
                <input className="inline_text" type="text" value={this.state.key} onChange={this.onChange} />
                <button className="button-two" onClick={this.onEnter}>Enter</button>
            </div>
            {message}
            </>
    }
}

export async function FindImageDownload64(key) {
    const call = "FindImage64/";
    await FindImageDownload(key, call);
}

export async function FindImageDownload(key, call = "FindImage/") {
    RenderPopup(<AlertBox type='none'>Creating and downloading product. This may take some time</AlertBox>);
    return axios.get(call + key).then(response => {
        const data = response.data;

        if (data.thumbnail == "") {
            const popup = <AlertBox type="Ok" dismisable>The product maker is busy, so one or more of the
            digital items could not be downloaded at the moment. Please try again later. If the problem persists, contact <EmailLink/>
            with your name and the downloads that you did not receive and we will get those to you.
                </AlertBox>
            RenderPopup(popup);
        }
        else {
            DownloadImage(data.thumbnail, data.name);
            ClosePopup();
        }

        return response.data;        

    }).catch(err => {
        const error = err.response.data.responseStatus !== undefined ? err.response.data.responseStatus.message : err.response.data === "401" ? "Unauthorized" : err.response.data;
        
        ClosePopup();
        return { message: error };
    });
}

