import React, { Component } from 'react';
import './CartIcon.css';
import getInfoFromServer from '../util/util';

var CartIconRef = null;

export function SetCartNumItems(numItems)
{
    CartIconRef.setState({ numItems: numItems });
}

export default class CartIcon extends Component
{
    constructor(props) {
        super(props);

        this.setRef = element => {
            CartIconRef = element;
        };
    }
     
    render() {
        return <CartIconSelf ref={this.setRef} />
    }
}

class CartIconSelf extends Component
{
    constructor(props)
    {
        super(props);
        
        this.state =
        {
            numItems: 0
        };
    }

    componentDidMount() {
        this.initialize();
    }

    async initialize() {
        const response = await getInfoFromServer('shoppingCart/GetCount');

        this.setState({ numItems: response.count });
    }

    render() {
        return <>
            <span className="cart-icon-badge">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                    <circle cx="9" cy="20" r="2"></circle>
                    <circle cx="16" cy="20" r="2"></circle>
                    <path d="M21,5H5.665L4.978,1.79A1,1,0,0,0,4,1H1A1,1,0,0,0,1,3H3.191L6.022,16.21a0.962,0.962,0,0,0,.064.159,1.015,1.015,0,0,0,.063.155,0.978,0.978,0,0,0,.133.153,1.006,1.006,0,0,0,.088.1,1,1,0,0,0,.185.105,0.975,0.975,0,0,0,.107.06A0.994,0.994,0,0,0,7,17H18a1,1,0,0,0,.958-0.713l3-10A1,1,0,0,0,21,5Zm-2.244,5H16V7h3.656ZM7.819,15l-0.6-3H9v3H7.819ZM11,12h3v3H11V12Zm0-2V7h3v3H11ZM9,7v3H6.82L6.22,7H9Zm8.256,8H16V12h2.156Z">
                    </path>
                </svg>
            </span>
            {this.state.numItems > 0 && <span className="num-icon">{this.state.numItems}</span>}

        </>
    }
}