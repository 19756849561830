import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/page_layout/Layout';
import { Home } from './components/pages/Home';
import { Customize } from './components/pages/customize/Customize';
import { Order } from './components/pages/Order';
import { ProfileInfo } from './components/pages/ProfileInfo';
import { FindImage } from './components/pages/FindImage';
import { ShoppingCart } from './components/pages/shopping_cart/ShoppingCart';
import './custom.css'
import Edit from './components/pages/edit/Edit';
import Admin from './components/pages/Admin';
import { Terms } from './components/pages/Terms';
import { Login } from './components/pages/Login';
import ContactPage from './components/pages/ContactPage';
import { DownloadPage } from './components/pages/DownloadPage';
import { ShippingPolicy } from './components/pages/ShippingPolicy';

export default class App extends Component {
    static displayName = App.name;

    async handleWindowClose() {
        //const response = await getInfoFromServer("image/Close");
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.handleWindowClose);
    }

    componentWillMount() {
        window.removeEventListener('beforeunload', this.handleWindowClose);
    }

  render () {
    return (
        <Layout>
            <Switch>
                <Route exact path='/' component={Home} />
                <Route path='/listings/:name/:id?' component={Customize} />
                <Route path='/listings' component={Order} />
                <Route path='/info' component={ProfileInfo} />
                <Route path='/contact' component={ContactPage}/>
                <Route path='/find/:key?' component={FindImage} />
                <Route path='/cart' component={ShoppingCart} />
                <Route path='/edit' component={Edit} />
                <Route path='/admin' component={Admin} />
                <Route path='/terms' component={Terms} />
                <Route path='/shipping' component={ShippingPolicy}/>
                <Route path='/login' component={Login} />
                <Route path='/downloads' component={DownloadPage} />
            </Switch>
      </Layout>
    );
  }
}
