import React from 'react';
import EmailLink from '../icons/EmailLink';

export default function ContactPage(props)
{
    return <div>
        <h5>Contact</h5>
        <div>
            <ul>
                <li>Name: Amy Jones</li>
                <li>Email: <EmailLink/></li>
                <li>Phone: (801)-645-7860</li>
            </ul>
        </div>
        </div>
}