import 'bootstrap/dist/css/bootstrap.css';
import './custom.css'
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import RenderPopup from './components/popup/Popup';
import AlertBox from './components/popup/AlertBox';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const userConfirmation = (message, callback) =>
{
    const onConfirm = (confirmed) =>
    {
        callback(confirmed);
    }

    RenderPopup(<AlertBox type="YesNo" onClick={onConfirm}>{message}</AlertBox>);
}

ReactDOM.render(
    <BrowserRouter basename={baseUrl} getUserConfirmation={userConfirmation}>
    <App />
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

