import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Form, { Fieldset } from '../util/Form';
import getInfoFromServer from '../util/util';
import { FindImageDownload64 } from './FindImage';

export function DownloadPage(props) {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const initialize = async () => {
            const response = await getInfoFromServer("download/GetItems");

            setItems(response.items);
        }

        initialize();
    }, []);

    if (items.length === 0) {
        return <div>
            <h3>You Have no Digital Downloads</h3>
            <Link className="text-black" to="/listings">Discover something new</Link>
        </div>
    }

    return <DownloadPageSelf items={items}/>
}
function DownloadPageSelf(props) {
    return <>
        <h5>Download Digital Images</h5>
        {props.items.map((item, i) => <DownloadItem id={i} item={item} />)}
    </>
}

function DownloadItem(props) {
    const style = {
        display: "flex",
        justifyContent: "space-between",
        maxWidth: "400px",
        minWidth: "290px"
    }

    return <div className={props.className} style={style}>
        <label>{props.item.name}</label>
        <DownloadImageButton imageKey={props.item.key}/>
    </div>
}

function DownloadImageButton(props) {
    const onDownload = (e) => {

        FindImageDownload64(props.imageKey);
    }
    
     return <button onClick={onDownload}>Download Image</button>    
}