import React, { Component } from 'react'
import EditPerson from './EditPerson'
import './EditImages.css';
import { EditProduct } from './EditProduct';
import { EditColor } from './EditColor';
import { EditFont } from './EditFont';
import { EditCoupledItem } from './EditCoupledItem';
import { EditTextBox } from './EditTextBox';
import { EditImages } from './EditImages';
import { postInfoToServer } from '../../util/util';
import { ToggleButton, Button } from '../../util/ToggleButton';
import UpdateContainers from './UpdateContainers';
import EditPage from './EditPage';


export default class Edit extends Component {
    constructor(props) {
        super(props);

        this.handleToggleClick = this.handleToggleClick.bind(this);
    }


    handleToggleClick(id) {

    }

    componentWillUnmount() {

        postInfoToServer('edit/Exit');
    }

    render()
    {
        return <>
            <ToggleButton onClick={this.handleToggleClick}>
                <Button id="image" name="Image">
                    <EditImages />
                </Button>
                <Button id='text' name="Text Item">
                    <EditTextBox />
                </Button>
                <Button id='coupled' name="Coupled">
                    <EditCoupledItem/>
                </Button>
                <Button id="person" name="Person">
                    <EditPerson/>
                </Button>                
                <Button id='product' name="Product">
                    <EditProduct />
                </Button>
                <Button id='color' name="Color">
                    <EditColor />
                </Button>
                <Button id='font' name="Font">
                    <EditFont />
                </Button>
                <Button id='page' name="Pages">
                    <EditPage />
                </Button>
                <Button id='update' name="Update CDN">
                    <UpdateContainers />
                </Button>                
            </ToggleButton>
            </>
    }
}

export function Container(props) {
    return <div class="border container-box">
        {props.name && <h5 class="item-label">{props.name}</h5>}
        {props.children}
        </div>
}