import React, { useState, useEffect } from 'react'
import { EditImageType } from './pages/edit/EditPerson';
import { EditPersonLocation } from './pages/edit/EditProduct';
import { EditSourceRect } from './pages/edit/EditSourceRect';
import RenderPopup from './popup/Popup';
import { Dropdown, Input } from './util/Dropdown';
import ErrorMessage from './util/ErrorMessage';
import { NameText } from './util/NameText';
import { Button, ToggleButton } from './util/ToggleButton';
import getInfoFromServer, { Copy, parseNumber, postInfoToServer } from './util/util';

export function EditCondition(props) {
    const [index, setIndex] = useState(-1);
    const [allProperties, setAllProperties] = useState([]);
    const [allPropertiesToChange, setAllPropertiesToChange] = useState([]);
    const [allOperators, setAllOperators] = useState([]);

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    const initialize = async (item, conditionNum) => {
        const response = await postInfoToServer("edit/condition", { productItem: item, numPeople: parseNumPeople(conditionNum, item.conditions) });

        if (response !== "") {
            setAllProperties(response.properties);
            setAllPropertiesToChange(response.propertiesToChange);
            setAllOperators(response.operators);
            setErrorMsg(null);
        }
        else {
            setErrorMsg("There is a problem with the conditions. Please fix");
        }
        setLoading(false);
    }

    useEffect(() => {
        initialize(props.productItem, -1);
    }, []);

    const onSelect = async (value) => {
        await initialize(props.productItem, value);
        setIndex(value);
    }

    const onToggleClick = async (mode) => {
        if (mode === "new") {
            const temp = Copy(props.items);
            const newCondition = {
                name: "New Condition", properties: [], operators: [], parenthesis: [], propertiesToChange: []
            };

            temp.push(newCondition);

            props.onChange(temp);


            const copyProduct = Copy(props.productItem);
            copyProduct.conditions = temp;
            await initialize(copyProduct, temp.length - 1);
            setIndex(temp.length - 1);
        }
    }

    const onNameChange = (value) => {
        const temp = Copy(props.items);

        temp[index].name = value;

        props.onChange(temp);
    }

    const onPropertyChange = (properties, operators, parenthesis) => {
        const temp = Copy(props.items);

        temp[index].properties = properties;
        temp[index].operators = operators;
        temp[index].parenthesis = parenthesis;

        const product = props.onChange(temp);

        const tempProduct = Copy(product);
        tempProduct.conditions = [temp[index]];

        initialize(tempProduct, 0);
    }

    const onResultChange = (results) => {
        const temp = Copy(props.items);

        temp[index].propertiesToChange = results;

        props.onChange(temp);
    }

    const onDelete = (e) => {
        const temp = Copy(props.items);

        temp.splice(index, 1);

        setIndex(index - 1);
        props.onChange(temp);
    }

    const parseNumPeople = (conditionId, items = null) => {
        if (conditionId < 0) {
            return null;
        }

        items = items ? items : props.items;
        const properties = items[conditionId].properties;

        for (var i = 0; i < properties.length; i++) {
            if (properties[i].propertyStack.includes("NumPeople") && !isNaN(properties[i].values[0])) {
                return parseInt(properties[i].values[0]);
            }
        }

        return null;
    }

    const parsePersonId = (conditionId, propertyId) => {
        //Gets the person id, the number between Person[3] for example. It is always the 3rd element in property to change
        return parseNumber(props.items[conditionId].propertiesToChange[propertyId].propertyStack[2]);
    }

    const parsePersonTypeName = (conditionId, personId) => {
        const properties = props.items[conditionId].properties;

        let personType = null;
        for (var i = 0; i < properties.length; i++) {
            const currPerson = parseNumber(properties[i].propertyStack[2]);

            if (currPerson === personId && properties[i].propertyStack[3] === "PersonType") {
                personType = properties[i].values[0];
                break;
            }
        }

        //Return the index of the person type
        return personType;
    }

    const parsePersonType = (toggleId, numPeople, personId, conditionId, personTypeName = null) => {
        if (personTypeName === null) {
            personTypeName = parsePersonTypeName(conditionId, personId);
        }

        if (personId === null || numPeople === null) {
            return -1;
        }

        const person = props.productItem.toggleItems[toggleId].allPeople[numPeople][personId];

        if (person === undefined) {
            return -1;
        }

        return person.possiblePersonTypes.findIndex(x => x.personType === personTypeName);
    }

    const postImage = (items, conditionId, propertyId, toggleId, isDebug, values, defaultValues) => {
        const temp = Copy(props.productItem.toggleItems);
        const numPeople = parseNumPeople(conditionId);

        const personId = parsePersonId(conditionId, propertyId);
        const personIndex = temp[toggleId].allPeople[numPeople].findIndex(x => x.key === personId);
        const personOrderKey = temp[toggleId].allPeople[numPeople][personIndex].orderKey;

        const personTypeName = parsePersonTypeName(conditionId, personId);
        const personType = parsePersonType(toggleId, numPeople, personId, conditionId, personTypeName);

        temp[toggleId].allPeople[numPeople][personIndex].possiblePersonTypes[personType].specialCases = items;

        //Update the values and default values
        const tempItems = Copy(props.items);
        tempItems[conditionId].propertiesToChange[propertyId].values = values;
        tempItems[conditionId].propertiesToChange[propertyId].defaultValue = defaultValues;

        return props.post(temp, isDebug, personTypeName, personOrderKey, numPeople, toggleId, tempItems);
    }

    const postPerson = (items, isDebug, personTypeName, personId, toggleId, numPeople, values, defaultValues, conditionId, propertyId) => {
        const temp = Copy(props.productItem.toggleItems);

        const personIndex = temp[toggleId].allPeople[numPeople].findIndex(x => x.key === personId);
        const personOrderKey = temp[toggleId].allPeople[numPeople][personIndex].orderKey;

        temp[toggleId].allPeople[numPeople] = items;

        //Update the values and default values
        const tempItems = Copy(props.items);
        tempItems[conditionId].propertiesToChange[propertyId].values = values;
        tempItems[conditionId].propertiesToChange[propertyId].defaultValue = defaultValues;

        return props.post(temp, isDebug, personTypeName, personOrderKey, numPeople, toggleId, tempItems);
    }

    if (loading) {
        return <div><h5>Edit Conditions</h5><div>loading...</div></div>
    }

    const curr = index > -1 ? props.items[index] : {}
    return <div>
        <h5>Edit Conditions</h5>
        {errorMsg && <ErrorMessage red>{errorMsg}</ErrorMessage>}
        <ToggleButton onClick={onToggleClick}>
            <Button id='new' name="New"/>
            <Button id='edit' name="Edit">
                <Dropdown items={props.items.map(x => x.name)} value={index} onChange={onSelect}>
                    Select Condition:
                </Dropdown>
            </Button>
            {index > -1 && <>
                <NameText name="Name: " value={curr.name} onBlur={onNameChange} />
                <EditConditions id={index} allProperties={allProperties} properties={curr.properties} allOperators={allOperators} operators={curr.operators} parenthesis={curr.parenthesis} onChange={onPropertyChange} product={props.productItem} numPeople={parseNumPeople(index)}/>
                <EditResult id={index} allProperties={allPropertiesToChange} properties={curr.propertiesToChange} onChange={onResultChange} product={props.productItem} postImage={postImage} postPerson={postPerson} numPeople={parseNumPeople(index)} parsePersonType={parsePersonType}/>
                <button onClick={onDelete}>Delete</button>
            </>}
        </ToggleButton>
    </div>
}

function EditConditions(props) {
    return <div>
        <h5>Condition</h5>
        <EditProperties allProperties={props.allProperties} properties={props.properties} allOperators={props.allOperators} operators={props.operators} parenthesis={props.parenthesis} onChange={props.onChange}/>
    </div>
}

function EditResult(props) {
    const postImage = (items, propertyId, toggleId, personId, isDebug, values, defaultValues) => {
        return props.postImage(items, props.id, propertyId, toggleId, isDebug, values, defaultValues);
    }

    const postPerson = (items, isDebug, personTypeName, personId, toggleId, numPeople, propertyId, values, defaultValues) => {
        return props.postPerson(items, isDebug, personTypeName, personId, toggleId, numPeople, values, defaultValues, props.id, propertyId);
    }

    return <div>
        <h5>Result</h5>
        <EditProperties id={props.id} allProperties={props.allProperties} properties={props.properties} onChange={props.onChange} product={props.product} postImage={postImage} postPerson={postPerson} numPeople={props.numPeople} parsePersonType={props.parsePersonType}/>
    </div>
}


function EditProperties(props)
{
    const newProperty = { propertyStack: ["CustomizeItem"], values: [], defaultValue: [] };

    const parsePersonId = (item) => {
        if (props.id !== undefined) {
            const toggleId = parseNumber(item.propertyStack[1]);
            const numPeople = props.numPeople;
            const personId = parseNumber(item.propertyStack[2])
            const personType = props.parsePersonType(toggleId, numPeople, personId, props.id);

            if (toggleId >= 0 && numPeople >= 0 && personId >= 0 && personType >= 0) {
                return props.product.toggleItems[toggleId].allPeople[numPeople].find(x => x.key === personId).possiblePersonTypes[personType].personId;
            }
        }

        return null;
    }

    const showProperties = () => {
        const properties = [];
        for (var propNum = 0; propNum <= (props.operators ? props.operators.length : props.properties.length); propNum++) {
            const item = propNum < props.properties.length ? props.properties[propNum] : newProperty;
            let fullStack = "";

            properties.push(<div style={{ display: "flex", flexWrap: "wrap" }}>
                {props.operators && propNum < props.properties.length && <SelectParenthesis id={propNum} value={props.parenthesis[propNum]} onChange={onParenthesisChange} open />}
                {item.propertyStack.map((stack, stackNum) => {

                    fullStack = fullStack !== "" ? fullStack + "." + stack : stack;
                    const currProperties = props.allProperties[fullStack];

                    //The property stack we show is one above the current stack (this is because CustomizeItem is the first one and we don't show it)
                    if (stackNum < item.propertyStack.length - 1) {
                        const values = stackNum === item.propertyStack.length - 2 && props.allProperties[fullStack + "." + item.propertyStack[stackNum + 1]] === undefined ? currProperties.find(x => x.property === item.propertyStack[stackNum + 1]).possibleValues : undefined

                        const toggleId = parseNumber(item.propertyStack[1]);
                        const personId = parsePersonId(item);
                        //const personKey = parseNumber(item.propertyStack[2]);

                        //const personType = props.parsePersonType ? props.parsePersonType(toggleId, props.numPeople, personKey, props.id) : null;
                        const people = props.product ? props.product.toggleItems[toggleId].allPeople[props.numPeople] : null;

                        //const value = values !== undefined ? values.findIndex(x => x === item.value) : undefined
                        return <AddProperty propNum={propNum} stackNum={stackNum + 1} items={currProperties} property={item.propertyStack[stackNum + 1]} value={item.values} defaultValue={(props.product ? item.defaultValue : null)} values={values} onChange={onPropertyChange} onValueChange={onValueChange} onValuesChange={onValuesChange} toggleId={toggleId} personId={personId} conditionId={props.id} parsePersonType={props.parsePersonType} people={people} postImage={props.postImage} postPerson={props.postPerson}/>
                    }

                    //if we are the last one, check to see if we are able to show any more or if this is it
                    const lastProperties = currProperties;

                    const lastProperty = lastProperties !== undefined ? <AddProperty propNum={propNum} stackNum={stackNum + 1} items={lastProperties} property={-1} onChange={onPropertyChange} /> : <></>

                    return <>{lastProperty}</>
                })}
                {props.operators && propNum < props.properties.length && <SelectParenthesis id={propNum} value={props.parenthesis[propNum]} onChange={onParenthesisChange} close />}
                <Break/>
                {props.operators && <AddOperator propNum={propNum} items={props.allOperators} operator={props.operators[propNum]} onChange={onOperatorChange} />}
            </div>);
        }

        return properties;
    }

    const onParenthesisChange = (parenthesis, propNum, parenthesisNum) => {
        const temp = Copy(props.parenthesis);

        temp[propNum][parenthesisNum] = parenthesis;

        props.onChange(props.properties, props.operators, temp);
    }

    const onPropertyChange = (property, propNum, stackNum) => {
        const temp = Copy(props.properties);
        const tempParen = props.parenthesis ? Copy(props.parenthesis) : [];
        //If we are deleting a property
        if (property === "") {
            temp.splice(propNum, 1);

            const tempOp = Copy(props.operators);
            const operatorNum = propNum < props.operators.length ? propNum : propNum - 1;
            if (props.operators && operatorNum > -1) {
                tempOp.splice(operatorNum, 1);
            }

            const tempParen = Copy(props.parenthesis);
            tempParen.splice(propNum, 1);

            props.onChange(temp, tempOp, tempParen);
            return;
        }
        else {

            let curr = temp[propNum];

            if (curr === undefined) {
                temp.push(newProperty);
                tempParen.push(["", ""]);
                curr = temp[propNum];
            }

            const length = curr.propertyStack.length;

            //If we are adding a property stack
            if (stackNum == length) {
                curr.propertyStack.push(property);
            }
            //If we are editting a property stack
            else {
                curr.propertyStack[stackNum] = property;
                curr.propertyStack.splice(stackNum + 1, length - stackNum - 1);
                
            }
            curr.values = [];
            curr.defaultValue = [];
        }

        props.onChange(temp, props.operators, tempParen);
    }

    const onOperatorChange = (_operator, operatorNum) => {
        const temp = Copy(props.operators);

        const operator = _operator.operator;

        if (operator === "") {
            temp.splice(operatorNum, temp.length - operatorNum);
        }
        else {
            //If we are adding an operator
            if (operatorNum === props.operators.length) {
                temp.push(operator);
            }
            //if we are updating an operator
            else {
                temp[operatorNum] = operator;
            }
        }

        props.onChange(props.properties, temp, props.parenthesis);
    }

    const onValueChange = (value, defaultValue, propNum, paramNum) => {
        const temp = Copy(props.properties);

        const curr = temp[propNum];

        curr.values[paramNum] = value;

        if (defaultValue && defaultValue !== "") {
            curr.defaultValue[paramNum] = defaultValue;
        }
        props.onChange(temp, props.operators, props.parenthesis);
    }

    const onValuesChange = (values, defaultValues, propNum) => {
        const temp = Copy(props.properties);

        const curr = temp[propNum];

        curr.values = values;
        curr.defaultValue = defaultValues;

        props.onChange(temp, props.operators, props.parenthesis);
    }

    const properties = showProperties();
    
    return <div style={{ display: "flex", flexDirection: "column" }}>        
        {properties}
    </div>
}

function SelectParenthesis(props) {
    const onBlur = (value) => {
        props.onChange(value, props.id, parenthesisNum);
    }

    const onLastKey = (value) => {
        if (value !== "(" && value !== ")") {
            return "";
        }

        return value;
    }

    const parenthesisNum = props.close ? 1 : 0;
    return <Input value={props.value[parenthesisNum]} onBlur={onBlur} onLastKey={onLastKey}/>
}


function AddProperty(props)
{
    const onSelect = (value) => {
        props.onChange(props.items[value].property, props.propNum, props.stackNum);
    }

    const onValueSelect = (index, paramId) => {
        props.onValueChange(props.values[paramId].possibleValues[index], props.values[paramId].value, props.propNum, paramId);
    }

    const onDefaultValueSelect = (index, paramId) => {
        props.onValueChange(props.value[paramId], props.values[paramId].possibleValues[index], props.propNum, paramId);
    }
    

    const onValueInput = (value, paramId) => {
        props.onValueChange(value, props.values[paramId].value, props.propNum, paramId);
    }

    const onDefaultValueInput = (value, paramId) => {
        props.onValueChange(props.value[paramId], value, props.propNum, paramId);
    }

    const onDelete = (e) => {
        props.onChange("", props.propNum, props.stackNum);
    }

    const onSpecialCaseChange = (values, defaultValues) =>
    {
        props.onValuesChange(values, defaultValues, props.propNum);
    }

    const postImage = (items, isDebug, values, defaultValues) => {
        return props.postImage(items, props.propNum, props.toggleId, props.personId, isDebug, values, defaultValues);
    }

    const postPerson = (items, isDebug, personTypeName, personKey, values, defaultValues) => {
        const temp = Copy(props.people);

        temp[personKey].possiblePersonTypes = items;

        return props.postPerson(temp, isDebug, personTypeName, personKey, props.toggleId, temp.length, props.propNum, values, defaultValues);
    }

    return <>
        <Dropdown items={props.items.map(x => x.property)} value={props.property} onChange={onSelect} />
        {props.values !== undefined && (props.property === "SetImageSpecialCase" ? <SetImageSpecialCase values={props.value} personId={props.personId} onChange={onSpecialCaseChange} post={postImage} /> : props.property === "SetSpecialCase" ? <SetPersonSpecialCase values={props.value} people={props.people} toggleId={props.toggleId} conditionId={props.conditionId} parsePersonType={props.parsePersonType} onChange={onSpecialCaseChange} post={postPerson}/> :
            <>==<div> {props.values.map((values, i) => values.possibleValues && values.possibleValues.length > 0 ? <Dropdown id={i} items={values.possibleValues} value={values.possibleValues.findIndex(x => x === props.value[i])} onChange={onValueSelect} /> : <Input id={i} value={props.value[i]} onChange={onValueInput} />)}</div>
                {props.defaultValue && <> == <div>{props.values.map((values, i) => values.possibleValues && values.possibleValues.length > 0 ? <Dropdown id={i} items={values.possibleValues} value={values.possibleValues.findIndex(x => x === props.defaultValue[i])} onChange={onDefaultValueSelect} /> : <Input id={i} value={props.defaultValue[i]} onChange={onDefaultValueInput} />)}</div></>}
            <button onClick={onDelete}>Delete</button>
        </>)}

    </>
}

function SetPersonSpecialCase(props) {
    const errorStatement = <>Error. Check your conditions</>;
    const [index, setIndex] = useState(props.values.length === 8 ? parseInt(props.values[0]) : -1);
    const [personType, setPersonType] = useState(props.values.length === 8 ? props.people[parseInt(props.values[0])].possiblePersonTypes.findIndex(x => x.personType === props.values[2]) : -1);

    const onPersonSelect = (value) => {
        setIndex(value);
        setPersonType(props.parsePersonType(props.toggleId, props.people.length, value, props.conditionId));
    }

    const onPersonTypeSelect = (value) => {
        setPersonType(value);
    }

    const onChange = (items) => {
        const item = items[personType];
        const defaultItem = props.people[index].possiblePersonTypes[personType];
        const numPeople = props.people.length;

        const values = [index, numPeople, item.personType, item.flipX, item.flipY, item.rotationAngle, item.location.x, item.location.y];
        const defaultValues = [index, numPeople, defaultItem.personType, defaultItem.flipX, defaultItem.flipY, defaultItem.rotationAngle, defaultItem.location.x, defaultItem.location.y];

        props.onChange(values, defaultValues);
    }

    const post = (items, isDebug, personTypeName) => {
        const item = items[personType];
        const numPeople = props.people.length;

        const values = [index, numPeople, personTypeName, item.flipX, item.flipY, item.rotationAngle, item.location.x, item.location.y];
        
        return props.post(items, isDebug, personTypeName, index, values, values);
    }

    if (props.people === undefined || props.people === null) {
        return errorStatement;
    }

    const personTypes = index > -1 ? Copy(props.people[index].possiblePersonTypes) : {};

    if (index > -1 && personType > -1 && props.values.length === 8) {
        personTypes[personType].flipX = props.values[3] === "true" || props.values[3] === true;
        personTypes[personType].flipY = props.values[4] === "true" || props.values[4] === true;
        personTypes[personType].rotationAngle = parseFloat(props.values[5]);
        personTypes[personType].location.x = parseInt(props.values[6]);
        personTypes[personType].location.y = parseInt(props.values[7]);
    }

    return <>
        <Dropdown items={props.people.map(x => x.name)} value={index} onChange={onPersonSelect} />
        {index > -1 && <>
            <Dropdown items={props.people[index].possiblePersonTypes.map(x => x.personType)} value={personType} onChange={onPersonTypeSelect} />
            {personType > -1 && <>
                <EditPersonLocation items={personTypes} index={personType} onChange={onChange} post={post} />
            </>}
        </>}
        
        </>
}

function SetImageSpecialCase(props)
{
    const errorStatement = <>Error. Check your conditions</>;
    const [loading, setLoading] = useState(true);
    const [people, setPeople] = useState([]);
    
    useEffect(() => {
        const initialize = async () => {
            const response = await getInfoFromServer('edit/Start/Person');

            setPeople(response.people);
            setLoading(false);
        }

        initialize();
    }, []);

    const onChange = (items, typeIndex, personSize, itemIndex) => {
        const item = items[typeIndex].items[itemIndex];
        const defaultItem = imageTypes[typeIndex].items[itemIndex];

        const values = [item.imageId, items[typeIndex].name, item.flipX, item.flipY, item.rotationAngle, item.location.x, item.location.y];
        const defaultValues = [defaultItem.imageId, imageTypes[typeIndex].name, defaultItem.flipX, defaultItem.flipY, defaultItem.rotationAngle, defaultItem.location.x, defaultItem.location.y];
        props.onChange(values, defaultValues);
    }

    const post = (items, isDebug, personSize, typeIndex, itemIndex) => {
        const item = items[typeIndex].items[itemIndex];
        const defaultItem = imageTypes[typeIndex].items[itemIndex];

        const values = [item.imageId, items[typeIndex].name, item.flipX, item.flipY, item.rotationAngle, item.location.x, item.location.y];

        //We make values be the values and default values to make it so that things can move and not stay as the default value
        return props.post(items, isDebug, values, values);
    }

    if (loading) return <>loading...</>

    if (props.personId === null) {
        return errorStatement;
    }

    const imageTypes = people.find(x => x.id === props.personId).imageTypes;

    if (props.values.length === 7) {
        const image = imageTypes.find(x => x.name === props.values[1]).items.find(x => x.imageId === parseInt(props.values[0]));

        if (image === null) {
            return errorStatement;
        }

        image.flipX = props.values[2] === "true" || props.values[2] === true;
        image.flipY = props.values[3] === "true" || props.values[3] === true;
        image.rotationAngle = parseFloat(props.values[4]);
        image.location.x = parseInt(props.values[5]);
        image.location.y = parseInt(props.values[6]);
    }

    return <><Break/><EditImageType items={imageTypes} onChange={onChange} post={post}/></>
}

function AddOperator(props) {
    const onSelect = (value) => {
        props.onChange(props.items[value], props.propNum);
    }

    const value = props.operator ? props.operator : -1;

    return <>
        <Dropdown items={props.items.map(x => x.operator)} value={value} onChange={onSelect}/>
    </>
}

function Break(props) {
    return <div style={{ flexBasis: "100%", height: "0" }}></div>
}
