import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import CartIcon from '../icons/CartIcon';
import './NavMenu.css';
import { useRef } from 'react';
import getInfoFromServer from '../util/util';

var NavElement = null;

export function NavMenu(props)
{
    NavElement = useRef(null);

    return <NavMenuSelf props={props} ref={NavElement} />
}

export function RenderAdmin(admin) {
    admin = admin !== false;
    NavElement.current.RenderAdmin(admin);
}

class NavMenuSelf extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
        collapsed: true,
        admin: false
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
    }

    RenderAdmin(value) {
        value = value !== false;
        this.setState({ admin: value });
    }

    componentDidMount() {
        this.initialize();
    }

    async initialize() {
        const response = await getInfoFromServer('IsAdmin');

        this.RenderAdmin(response.isAdmin);        
    }

  render () {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            <NavbarBrand tag={Link} to="/">AJs Palette</NavbarBrand>
            <CartLink to="/cart"/>
            <Toggler onClick={this.toggleNavbar} />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                </NavItem>  
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/listings">Items</NavLink>
                </NavItem>     
               {/* <NavItem>*/}
               {/*     <NavLink tag={Link} className="text-dark" to="/downloads">Downloads</NavLink>*/}
               {/* </NavItem>*/}
               {this.state.admin && <><NavItem>
                  <NavLink tag={Link} className="text-dark" to="/find">Find Image</NavLink>
                </NavItem> 
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/edit">Edit Image</NavLink>
                </NavItem></>}
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/login">Login</NavLink>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}

function Toggler(props)
{
    return <>
        <div className="toggler">
            <NavbarToggler onClick={props.onClick} className="mr-2" />
        </div>
        </>
}

function CartLink({ to }) {
    return <>
        <NavLink tag={Link} className="button button-three cart-icon text-dark" to={to}>
            <CartIcon />
        </NavLink>
    </>
}