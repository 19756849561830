import React, { Component } from 'react';
import { Customize } from './Customize';
import './ActiveImageClick.css';

export class ActiveImageClick extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            loading: true,
            image: ""
        };

        this.minimize = this.minimize.bind(this);
    }
    
    minimize()
    {
        this.props.minimize();
    }

    componentDidMount()
    {
        this.initialize();
    }

    render()
    {
        //this.initialize();
        const content = (this.state.loading) ? <div className="image-self center-align">loading...</div> : <img className="image-self" src={this.state.image} alt="high res image"/>;
        return <>
            <div onClick={this.minimize} >
                {content}
            </div>
                
            </>
    }

    async initialize()
    {
        const result = await Customize.GetInfo("Active/High", this.props.sessionId);

        this.setState({ image: result.activeImage, loading: false });
    }

}

ActiveImageClick.defaultProps = {
    dismisable: false
}