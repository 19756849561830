import React, { Component } from 'react';

export class NameText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFocused: false, hasSelected: false, value: this.props.value, initialValue: this.props.value
        }

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const value = event.target.value;
        if (this.props.onChange)
            this.props.onChange(value, this.props.id);
        
        this.setState({ value: value });
        
    }

    onBlur(event) {
        let name = this.state.value;
        if (/\S/.test(this.state.value)) {
            if (this.props.onBlur)
                this.props.onBlur(this.state.value, this.props.id);
        }
        else {
            name = this.state.initialValue;
            if (this.props.onChange)
                this.props.onChange(name, this.props.id);

        }
        this.setState({ isFocused: false, value: name });
    }
    onFocus(event)
    {
        //Set isfocused to true and set the initial value to props
        //this is for when the user blurs with entering only whitespace, the
        //name text can revert to the intital value
        this.setState({
            isFocused: true, initialValue: this.props.value
        });
    }
    onKeyPress(event) {
        if (event.key === "Enter") {
            event.target.blur();            
        }
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        //If we are updating because the props changed, change the state to props
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }
    componentDidUpdate() {
        //When it is selected we want it to select (just once, hence has selected)
        if (this.state.isFocused && !this.state.hasSelected) {
            this.refs.input.select();
            this.setState({
                hasSelected: true
            });
        }

        //When it blurs we want it to call the props.onBlur() (just once, hence has selected)
        if (!this.state.isFocused && this.state.hasSelected) {
            this.setState({
                hasSelected: false
            });            
        }
    }

    render() {

        //only display the value if it is focused or if the user wants to show the text не смотря на focus
        const value = (this.state.isFocused || this.props.showText) ? this.state.value : "";
        const checkBoxDisabled = (this.props.enabled === undefined) ? " disabled" : "";

        return (<div className={"input-wrapper" + (this.props.className ? this.props.className : "")}>

            <span className="item-label">{this.props.name}</span>
            <div style={{ display: "inline" }}>
                <input className="input-box" ref="input" type="text" value={value} disabled={this.props.enabled === false}

                    //Erases the name box when the user is no longer typing
                    onBlur={this.onBlur}
                    //Fills the name box with the name of the person once the user starts typing
                    onFocus={this.onFocus}

                    onChange={this.handleChange}
                    onKeyPress={this.onKeyPress}
                />                
                {this.props.children}
            </div>
        </div>);
    }
}

NameText.defaultProps = {
    showText: true
}