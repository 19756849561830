import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom';
import getInfoFromServer, { postInfoToServer } from '../../util/util';
import "./ShoppingCart.css";
import "../Order.css";
import Checkout from './Checkout';
import RenderPopup from '../../popup/Popup';
import AlertBox from '../../popup/AlertBox';
import { SetCartNumItems } from '../../icons/CartIcon';
import { Dropdown } from '../../util/Dropdown';

export class ShoppingCart extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            items: [],
            subTotal: 0,
            shippingPrice: 0,
            maxAmount: 0,
            hasPaid: false,
            isDevelopment: true
        };

        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.handlePayment = this.handlePayment.bind(this);
        this.handleItemRemove = this.handleItemRemove.bind(this);
        this.handleCheckoutClose = this.handleCheckoutClose.bind(this);
    }

    componentDidMount()
    {
        this.initialize();
    }

    async handlePayment(success)
    {               
        if (success) {                                    
            const response = await getInfoFromServer("shoppingCart/SubmitOrder");
            
            SetCartNumItems(response.numItems);

            // Pinterest checkout event
            window.dataLayer.push({ 'ov': this.state.subTotal + this.state.shippingPrice });
            window.dataLayer.push({ 'oq': this.state.items.length })
            window.dataLayer.push({ 'oid': this.state.items[0].id })
            window.dataLayer.push({ 'event': 'checkout' })

            RenderPopup(<AlertBox dismisable>
                Payment was successful!                
                </AlertBox>);
            this.setState({ items: response.items});
        }
        else {
            RenderPopup(<AlertBox type="Ok" dismisable>There was an error in the payment</AlertBox>);
        }


        this.setState({ hadPaid: success });
    }

    async handleAmountChange(itemId, amount)
    {
        const response = await postInfoToServer("shoppingCart/Amount", {
            amount: amount, shoppingItemId: itemId
        });

        this.setState({
            subTotal: response.subTotal, shippingPrice: response.shippingPrice,
            items: response.items, maxAmount: response.maxAmount
        });
    }

    async handleItemRemove(itemId)
    {
        const response = await postInfoToServer("shoppingCart/Remove", { id: itemId });

        SetCartNumItems(response.numItems);

        this.setState({ items: response.items, subTotal: response.subTotal, shippingPrice: response.shippingPrice });
    }

    async handleAddEmail(addEmail)
    {
        const response = await postInfoToServer("shoppingCart/AddEmail", {
            addEmail: addEmail
        });
    }

    async handleCheckoutClose() {
        this.initialize();
    }

    async initialize() {
        const response = await getInfoFromServer("shoppingCart/ShoppingCartItems");

        this.setState({
            items: response.shoppingCartItems, subTotal: response.subTotal, shippingPrice: response.shippingPrice,
            hasPaid: response.hasPaid,
            maxAmount: response.maxAmount, isDevelopment: response.isDevelopment
        });
    }

    render()
    {
        const emptyCart = <div>
            <h3>Your Cart is Empty</h3>
            <Link className="text-black" to="/listings" >Discover something to fill it up</Link>
        </div>

        if (this.state.items.length == 0) return emptyCart;

        return <>
            <div className="cart-heading">
                <h3>You have {this.state.items.length} item(s) in your cart </h3>
            </div>
            <div className="cart-container">
                
                {this.state.items.map(item => <ShoppingItem hasPaid={this.state.hasPaid} item={item}
                    onChange={this.handleAmountChange} onRemove={this.handleItemRemove} amount={this.state.maxAmount} />)}
                
            </div>
            <TotalPrice subTotal={this.state.subTotal} shipping={this.state.shippingPrice} isDevelopment={this.state.isDevelopment} succeeded={this.state.hasPaid} onPaid={this.handlePayment} onCheck={this.handleAddEmail} onClose={this.handleCheckoutClose} />
                </>
    }
}


class ShoppingItem extends Component
{
    constructor(props)
    {
        super(props);

        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.disableEdit = this.disableEdit.bind(this);
    }

    handleAmountChange(value)
    {
        this.props.onChange(this.props.item.id, value);
    }

    handleRemove(confirmed)
    {
        if (!confirmed) return;

        this.props.onRemove(this.props.item.id);
    }

    disableEdit(e)
    {
        if (this.props.hasPaid)
            e.preventDefault()
    }


    render()
    {
        const item = this.props.item;
        const itemUrl = "/listings/" + item.name;
        const itemUrlId = itemUrl + '/' + item.id;

        const maxAmount = this.props.amount + item.amount;

        return <>
            <div className="shopping-item">
                <Link className="item-image_wrapper selectable no-decoration text-black" to={itemUrlId} title={item.name}>
                    <img className="item-image" src={item.preview} />
                </Link>
                <div className="item-info">
                    <div className="item-info-left">
                        <Link className="item-description text-black" to={itemUrl} title={item.description}>
                            {item.description}
                        </Link>
                        <div className="item-text">Finish: {(item.material.itemSize !== 'None' ? item.material.itemSize + " " : "") + item.material.itemMaterial}</div>
                        <Link className="button button-three" to={itemUrlId} onClick={this.disableEdit} > Edit</Link>
                        <button className="button-three" disabled={this.props.hasPaid} onClick={(e) =>
                        {
                            RenderPopup(<AlertBox type="YesNo" onClick={ this.handleRemove } >Are you sure you want to remove this item?</AlertBox>)
                        }}>Remove</button>
                    </div>

                    <div className="item-info-right">
                        <SelectRange className="item-select" value={item.amount} from={1} to={maxAmount} onChange={this.handleAmountChange} disabled={this.props.hasPaid} />
                        <b className="item-price">${item.price.toFixed(2)}</b>
                    </div>
                </div>
                
            </div>
        </>
    }
}

function SelectRange(props) {
    const getItems = (props.items) ? () => props.items : ((props.from && props.to) ? () => {
        var array = [];
        for (var i = props.from; i <= props.to; i++) {
            array.push(i);
        }

        return array;
    } : () => {
        throw new DOMException("Does not implement items or from/to or contains value 0");
    })
    const items = getItems();

    //Find where the value is located in the list
    const value = items.findIndex(x => x == props.value);

    if (value === -1) {
        throw new DOMException("Value " + props.value + " must be in the specified range");
    }

    const onChange = value => {
        if (props.onChange) {
            props.onChange(items[value]);
        }
    }

    return <>
        <Dropdown className={props.className} value={value} items={items} onChange={onChange} disabled={props.disabled}/>        

    </>
}

function TotalPrice(props)
{
    const [addEmail, setAddEmail] = useState(false);

    const handleCheckout = (event) =>
    {
        event.preventDefault();
        RenderPopup(
            <Checkout amount={props.totalPrice} succeeded={props.succeeded} onPaid={props.onPaid} isDevelopment={props.isDevelopment} dismisable onClose={props.onClose}/>
        );
    }

    const handleCheckbox = (e) =>
    {
        props.onCheck(e.target.checked);
        setAddEmail(e.target.checked);
    }


    return <>
        <form className="form-price box-wrapper">
            <h5 className="design-title">
                Totals Summary
            </h5>
            <div className="price-display text-display">
                <span>Item(s) Total: </span>
                <span>${props.subTotal.toFixed(2)}</span>
            </div>
            <div className="price-display text-display">
                <span>Shipping: </span>
                <span>{(props.shipping === 0 ? "*FREE" : `$${props.shipping.toFixed(2)}`)}</span>
            </div>
            <div className="text-display">
                <span>Add my email to the email list</span>
                <input className="checkbox" type="checkbox" checked={addEmail} onChange={handleCheckbox} />
               
            </div>
            <br/>
            <div className="price-display text-display">
                <span>Total: </span>
                <span>${(props.subTotal + props.shipping).toFixed(2)}</span>
            </div>
            {props.shipping === 0 && <div className="text-display subtext">*Shipping charges may change depending on country outside of US</div>}
            {props.succeeded ? <p className="form-text">Payment Successful!</p> : <button className="button-two proceed-button" onClick={handleCheckout} >Proceed To Checkout</button>}
        </form>
        </>
}