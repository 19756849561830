import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import useBlockScroll from '../util/useBlockScroll';
import './Popup.css';


const popupId = 'popup';
const safeDocument = typeof document !== 'undefined' ? document : {};

var myPopups;


export default function RenderPopup(passedPopup, id = popupId)
{
    //ClosePopup();
    //Enables the popup
    const popupElement = GetPopupElement(id);
    popupElement.SetEnabled(true);

    if (passedPopup.props.onClose) {
        popupElement.setState({ onClose: passedPopup.props.onClose });
    }

    if (passedPopup.props.dismisable === true)
    {
        popupElement.EnableCloseButton();
    }

    if (passedPopup.props.noScroll === true) {
        popupElement.DisableScroll();
    }
    
    //Add a minimize function to the popup item
    const myPopup = React.cloneElement(passedPopup,
        { minimize: minimizePopup }
    );
   
    ReactDOM.render(myPopup, GetPopupSelfNode(id));

    return myPopup;
}

export function ClosePopup(id = popupId) {
    minimizePopup(id);
}

function BlockScrollFunc(enabled) {
    const [blockScroll, allowScroll] = useBlockScroll();
    if (enabled) blockScroll();
    else allowScroll();
}

export class Popup extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            enabled: this.props.enabled,
            closeEnabled: false,
            scrollDisabled: false,
            overflowDisabled: false
        };

        myPopups = { ...myPopups, [this.props.id]: this };

        this.onUnload = this.onUnload.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
    }

    onUnload(event)
    {
        minimizePopup(this.props.id);
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.onUnload);
        window.addEventListener("popstate", this.onUnload);
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload);
        window.removeEventListener("popstate", this.onUnload);
    }


    SetEnabled(enabled)
    {
      //  const [blockScroll, allowScroll] = useBlockScroll();

        if (enabled)
        {
            //disables scrolling
            BlockScrollFunc(true);

            //Overrides the overflow
            const html = safeDocument.documentElement;
            const { body } = safeDocument;

            html.style.height = '100%';
            body.style.height = '100%';


            this.refs.popup.style.marginTop = window.scrollY + "px"; 
        }
        else
        {
            //enables scrolling
            BlockScrollFunc(false);

            //Resets the overflow
            const html = safeDocument.documentElement;
            const { body } = safeDocument;

            html.style.height = '';
            body.style.height = '';
        }
        this.setState({ enabled: enabled, closeEnabled: false });
    }

    EnableCloseButton(enable = true)
    {
        this.setState({ closeEnabled: enable });
    }

    DisableScroll(disable = true) {
        this.setState({ scrollDisabled: disable });
    }

    DisableOverflow(disable = true) {
        this.setState({ overflowDisabled: disable });
    }

    onCloseClick(e)
    {
        minimizePopup(this.props.id);
    }
    
    render()
    {
        const disabled = (this.state.enabled) ? "" : " disabled";
        const closeDisabled = (this.state.closeEnabled) ? "" : " disabled";

        return <>
            
            <div className={"fill-screen" + disabled} >
            </div>
            <div className={"popup-outer_container" + disabled} id={this.props.id} ref="popup" >
                <div className="popup-container">
                    <div className="popup-outer_wrapper" onKeyDown={e => {
                       if (this.state.scrollDisabled && (e.keyCode <= 40 && e.keyCode >= 37))
                            e.preventDefault();
                    }}>
                        <div className="popup-self">
                            <div className={"close_wrapper" + closeDisabled}>
                                <button className="close-button" onClick={this.onCloseClick}>
                                    <span>×</span>
                                </button>
                            </div>
                            <div className="popup-root" id={this.props.id + "-root"}>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    }
}

Popup.defaultProps = {
    enabled: false
};

function minimizePopup(id = popupId)
{
    const popupElement = GetPopupElement(id);

    popupElement.EnableCloseButton(false);
    popupElement.DisableScroll(false);
    popupElement.DisableOverflow(false);

    //Disables the popup
    popupElement.SetEnabled(false);

    if (popupElement.state.onClose) {
        popupElement.state.onClose();
        popupElement.setState({ onClose: null });
    }

    ReactDOM.render(<></>, GetPopupSelfNode(id));
}

function GetPopupElement(id)
{
    return myPopups[id];
}

function GetPopupSelfNode(id)
{
    const idSelf = id + "-root";
    return document.getElementById(idSelf)
}



