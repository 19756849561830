import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import getInfoFromServer from "../util/util";
import "./Order.css";

export class Order extends Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            productItems: [],
            isLoading: true
        };        
    }

    componentDidMount()
    {
        this.initialize();
    }

    render()
    {
        let content = (this.state.isLoading) ? <></> : <ul>
            {this.state.productItems.map((item) => <li className="listing-item"><ProductItem item={item} /></li>)}
        </ul>

        return (
            <div className="order-container">
                <h4> Featured customizable art</h4>
                {content}
            </div>);
    }

    async initialize()
    {
        const result = await getInfoFromServer("product/items");
        this.setState({
            productItems: result.items, isLoading: false
        });

        
    }
}

class ProductItem extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            onHover: false
        };

        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    onMouseEnter(e) {
        this.setState({ onHover: true });
    }

    onMouseLeave(e) {
        this.setState({ onHover: false });
    }

    render()
    {
        //If it is a mother's hug type product, go to fetch data
        let url = "/listings/" + this.props.item.name;

        return (

            <Link className="order no-decoration text-black" to={url} title={this.props.item.description} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} >
                <img className={"item-image selectable"} src={this.props.item.thumbnail} alt={this.props.item.description} />
                    <h5 className="description text-truncate">{this.props.item.description}</h5>
                    <h6>{"$" + this.props.item.price.toFixed(2)}</h6>
                </Link>
            );
    }
}