import React, { Component, useEffect, useState, useRef } from 'react';
import { AddNewButton } from './AddNewButton';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './Dropdown.css';
import { Copy } from './util';
import RenderPopup from '../popup/Popup';
import AlertBox from '../popup/AlertBox';

export class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userLoaded: false
        };
        this.handleChange = this.handleChange.bind(this);

    }

    handleChange(event) {
        const eventDefined = event.target !== undefined;
        const value = (eventDefined) ? (!isNaN(event.target.value)) ? Number(event.target.value) : event.target.value : event
        if (this.props.onChange) {
            this.props.onChange(value, this.props.id);
        }

        this.setState({
            userLoaded: eventDefined
        });
    }

    updateItems(_items)
    {
        //Converts the images to a dictionary type list for the dropdown
        let items = {};
        for (var i = 0; i < _items.length; i++) {
            items = {
                ...items, [i]: _items[i]
            };
        }
        return items;
    }


    render()
    {
        //Make this.props.items into a dictionary
        const items = Object.entries(Array.isArray(this.props.items) ? this.updateItems(this.props.items) : this.props.items);

        const propertyIndex = items.findIndex(x => x[1] == this.props.value);

        //if the props.value is an index, make svalue that index. if it is a value at a certain index that isn't a number
        //(this will mess things up), find that index
        const svalue = (this.props.value === -1) ? "" : (propertyIndex === -1 || !isNaN(this.props.value)) ? this.props.value : propertyIndex;

        const children = (Array.isArray(this.props.children)) ? this.props.children : [this.props.children];
        
        return (
            <div className={this.props.className + " input-wrapper"} >
                {children.map((child) => {
                    return <>{child}</>
                })}
                {items.length > 0 && <div className="input-box"><select className={this.props.innerClassName} value={svalue} onChange={this.handleChange}>
                    <option selected disabled hidden style={{ display: "none" }} value=''></option>
                    {items.map(([key, value]) => < option value={key} key={key}> {value}</option>)}
                </select></div>}
            </div>);
    }
}

export function DropdownButton(props)
{
    const onNew = (newType) => {
        const temp = Copy(props.items);
        temp.push(newType);
        props.onChange(temp.findIndex(x => x === newType), temp);
    }

    const onDelete = () =>
    {
        const temp = Copy(props.items);

        //sometimes the index is passed as props.value, sometimes the value is passed
        const index = props.items.findIndex(x => x === props.value) !== -1 ? props.items.findIndex(x => x === props.value) : props.value;
        temp.splice(index, 1);

        props.onChange(temp.findIndex(x => x === props.value), temp);
    }

    const onEdit = (newType) =>
    {
        const temp = Copy(props.items);

        //sometimes the index is passed as props.value, sometimes the value is passed
        const index = props.items.findIndex(x => x === props.value) !== -1 ? props.items.findIndex(x => x === props.value) : props.value;

        temp[index] = newType;
        props.onChange(index, temp);
    }

    return <>
        <Dropdown value={props.value} items={props.items} onChange={props.onChange}>
            {props.children}
        </Dropdown>
        <AddNewButton onNew={onNew} onDelete={onDelete} onEdit={onEdit} length={props.items.length}/>
        </>
}

export function DragAndDropButton(props) {

    const [initialKey, setInitialKey] = useState((props.items.length > 0) ? props.items[0].key : 0);
    const [msg, setMessage] = useState("");
    
    const onNew = (newType) => {
        //The key is either one more than the last item, or whatever the initial key was at the start
        //ex. image type = 1 (not 0)
        const key = (props.items.length > 0) ? props.items[props.items.length - 1].key + 1 : initialKey;
        const newItem = { key: key, name: newType };
        setMessage("");
        if (props.test && !props.test(newItem)) {
            setMessage("The entered data has some invalid characters");
            return;
        }
        props.onNew(newItem);
    }

    const onDelete = () => {
        const index = props.items.findIndex(x => x.key === props.value);
        const items = Copy(props.items);
        items.splice(index, 1);

        //Everything above the deleted item needs to move down one key
        const sliced = items.slice(index, items.length);
        for (var i = 0; i < sliced.length; i++) {
            sliced[i].key -= 1;
        }

        let val = items[0];
        if (!val) val = "";
        props.onDelete(items, index, val);
    }

    const onEdit = (value) => {
        const index = props.items.findIndex(x => x.key === props.value);
        const items = Copy(props.items);
        items[index].name = value;

        props.onEdit(items, items[index].key, index);
    }

    const onDragChange = (items, newKey, newIndex) => {
        props.onChange(items, newKey, newIndex);
    }

    const onDragClick = (value) => {
        props.onChange(props.items, props.items[value].key, value);
    }

    return (
        <div className="dropdown-button-container">

            <DragAndDrop label={props.label} value={props.value} items={props.items} onChange={onDragChange} onClick={onDragClick} />

            <AddNewButton onNew={onNew} onDelete={onDelete} onEdit={props.onEdit ? onEdit : undefined} length={props.items.length} />
            <div className="red">{msg}</div>            
        </div>);
}

export function DragAndDrop(props) {
    const moveItems = (srcIndex, destIndex) => {
        let items = Copy(_items);

        //If it is moving up
        if (srcIndex > destIndex) {
            items[srcIndex][props._key] = _items[destIndex][props._key];

            let sliced = items.slice(destIndex, srcIndex);

            //Push everything above the selected object down
            for (var i = 0; i < sliced.length; i++) {

                sliced[i][props._key] = _items[destIndex + i + 1][props._key];
            }

            if (props.move)
                items = items.slice(0, destIndex).concat(items[srcIndex]).concat(sliced).concat(items.slice(srcIndex + 1, items.length));
        }
        //if it is moving down
        if (srcIndex < destIndex) {
            items[srcIndex][props._key] = _items[destIndex][props._key];

            let sliced = items.slice(srcIndex + 1, destIndex + 1);

            //Push everything above the selected object down
            for (var i = 0; i < sliced.length; i++) {

                sliced[i][props._key] = _items[srcIndex + i][props._key];
            }

            if (props.move)
                items = items.slice(0, srcIndex).concat(sliced).concat(items[srcIndex]).concat(items.slice(destIndex + 1, items.length));
        }

        //Gets the new key
        let newKey = props.value;
        let newIndex = _items.findIndex(x => x[props._key] === props.value);

        if (props.move) {
            //If another item is going from under to over, our selected item goes down one
            if (srcIndex < newIndex && destIndex >= newIndex) {
                newKey = items[newIndex - 1][props._key];
            }
            //If another item is going from over to under, we go up one
            else if (srcIndex > newIndex && destIndex <= newIndex) {
                newKey = items[newIndex + 1][props._key];;
            }
            //If we are moving our selected item, its key is the destination
            else if (srcIndex === newIndex) {
                newKey = items[destIndex][props._key];;
            }

            newIndex = items.findIndex(x => x[props._key] === newKey);
        }

        props.onChange(items, newKey, newIndex, srcIndex, destIndex);
    }

    const changeItems = (srcIndex, destIndex) => {

    }

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const srcIndex = result.source.index;
        const destIndex = result.destination.index;

        moveItems(srcIndex, destIndex);
    };

    const onItemClick = (e) => {
        if (props.onClick) {
            props.onClick(Number(e.currentTarget.id), props._key)
        }
    }

    if (!props.items) return <></>

    const _items = Copy(props.items).sort((a, b) => a[props._key] - b[props._key]);

    return <div>
        <h4>
            {props.label}</h4>
        <DragDropContext onDragEnd={onDragEnd} >
                <Droppable droppableId="layers">
                    {(provided) => (
                        <ul className="layers" {...provided.droppableProps} ref={provided.innerRef} >
                        {_items.map((item, i) =>
                        {
                            const selected = (props.value === item[props._key]) ? " selected" : "";
                            const display = props.displayImage ? <img src={item[props.name]} width="55px" height="55px"/> : item[props.name];

                            return <Draggable draggableId={item[props._key].toString()} index={i} key={item[props._key].toString()}>
                                {(provided) => (
                                    <li className={"" + selected} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                                        <p id={i} onClick={onItemClick}>{display}</p>
                                        </li>)}
                                </Draggable>
                                  
                            }
                            )}
                            {provided.placeholder}
                        </ul>)}
                </Droppable>
            </DragDropContext>
    </div>
}

DragAndDrop.defaultProps = {
    name: 'name', _key: 'key', displayImage: false,
    move: true
}

export function DropdownDropdown(props)
{
    const [value, setValue] = useState(props.value ? props.value : -1);
    const [allItems, setAllItems] = useState(props.allItems);

    useEffect(() => {
        setAllItems(props.allItems);
    }, [props.allItems]);

    const onProductChange = (key) => {
        setValue(key);

        if (props.onChange)
            props.onChange(key, Number(props.id));
    }

    const onNewRect = (value) =>
    {
        //If we add a new item, we need to delete it from the all items list so that
        //we cannot add it again
        const temp = Copy(allItems);
        temp.splice(allItems.findIndex(x => x === value), 1);
        setAllItems(temp);

        props.onNew(value, Number(props.id));
    }


    const onDeleteRect = () =>
    {
        if (allItems.findIndex(x => x === props.items[value]) === -1 && props.allItems.findIndex(x => x === props.items[value]) !== -1) {
            //When we delete an item, add it back to the list so that we can add it again
            const temp = Copy(allItems);
            temp.push(props.allItems[value]);
            setAllItems(temp);
        }

        props.onDelete(props.items[value], Number(props.id));

        setValue(-1);
    }

    return <div className={props.className ? props.className : ""}>
        <Dropdown value={value} items={props.items} onChange={onProductChange}>
            {props.children}
        </Dropdown>
        <AddNewButton items={allItems} onNew={onNewRect} onDelete={onDeleteRect} />
    </div>
}

export function Input(props)
{
    const [value, setValue] = useState(props.value);
    const inputRef = useRef(null);

    //if the user has it of type number, it is still type text
    const type = props.type === "number" ? "text" : props.type;

    const onChange = (e) =>
    {
        if (!props.enabled) return;
        let _value = e.target.value;

        if (props.onLastKey) {
            const lastKey = _value.length > 0 ? _value.charAt(_value.length - 1): "";

            if (lastKey !== "") {
                const newLastKey = props.onLastKey(lastKey);
                _value = _value.slice(0, _value.length - 1) + newLastKey;
            }            
        }

        setValue(_value);

        if (props.onChange) props.onChange(_value, Number(props.id));
    }

    const onChecked = (e) => {
        if (!props.enabled) return;

        const _value = e.target.checked;
        setValue(_value);

        if (props.onChange) props.onChange(_value, Number(props.id));
    }

    const onBlur = (e) =>
    {
        if (!props.enabled) return;

        let _value = value;
        //If they do not type in a number, set it to its initial value and return
        if (props.type === "number")
        {
            if (isNaN(value)) {
                setValue(props.value);
                return;
            }

            if ((props.from || props.to) && (value < props.from || value > props.to)) {
                RenderPopup(<AlertBox dismisable>Number outside the given range</AlertBox>);
                setValue(props.value);
                return;
            }

            _value = Number(value);
        }

        if (props.onBlur)
        {
            //If this value should not be updated, set it to its intitial value
            if (props.onBlur(_value, Number(props.id)) === false) {
                setValue(props.value);
            }
        }
    }

    const onFocus = (e) =>
    {
        if (props.autoFocus)
            inputRef.current.select();

        if (props.onFocus) props.onFocus();
    }

    const onKey = (e) =>
    {

        //If they press enter, blur it
        if (e.keyCode === 13 && type !== "textbox") {
            inputRef.current.blur();
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", onKey);

        return function () {
            window.removeEventListener('keydown', onKey);
        }
    }, []);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    return <span className={(props.className ? props.className : "")}>
        <label>{props.children}</label>
        {type === "checkbox" ? <input ref={inputRef} type={type} checked={value} onChange={onChecked} /> :
            type === "textbox" ? <Textarea className="textarea" value={value} ref={inputRef} onChange={onChange} onBlur={onBlur} onFocus={onFocus}/> :
                <input ref={inputRef} type={type} value={value} onChange={onChange} onBlur={onBlur} onFocus={onFocus} />}
    </span>
}

Input.defaultProps = {
    type: 'text', enabled: true
}

Input.div = (props) => {
    return <div><Input {...props} /></div>
}

function Textarea(props) {
    const numNewLines = props.value !== "" && props.value ? props.value.split("\n").length : 1;
    const height = 1.5 * (numNewLines - 1) + 2;
    const style = { height: height + "rem" };
    return <textarea className="textarea" style={style} value={props.value} ref={props.inputRef} onChange={props.onChange} onBlur={props.onBlur} onFocus={props.onFocus} />
}
