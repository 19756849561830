import React, { useState } from 'react';
import './CopyTextButton.css';
import { Tooltip } from './Tooltip';
export default function CopyTextButton(props) {
    const [copyText, setCopyText] = useState("Copy");

    const onCopy = (e) =>
    {
        e.preventDefault();
        CopyText(props.value);
        setCopyText("Copied");
    }

    const onMouseLeave = (e) => {
        setCopyText("Copy");
    }

    return <Tooltip value={copyText}>
        <button className="button-text" onClick={onCopy} onMouseLeave={onMouseLeave}>
            {props.value}
        </button>
    </Tooltip>
}

export function CopyText(value) {
    navigator.clipboard.writeText(value);
}