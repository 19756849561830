import React, { useEffect, useState }  from 'react';
import getInfoFromServer from '../util/util';

export default function EmailLink(props)
{
    const [email, setEmail] = useState(null);
    useEffect(() => {
        const initialize = async () => {
            const response = await getInfoFromServer('getemail');

            setEmail(response.email);
        }

        initialize();
    }, []);
    return <>
        <a href={"mailto:" + email}>{email}</a>
        </>
}