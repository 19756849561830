import React, { useState } from 'react';
import { Dropdown } from './Dropdown';

export function AddNewButton(props) {
    const [value, setValue] = useState(props.value);
    const [newType, setNewType] = useState(props.value ? props.value : "");
    const [newTypeClicked, setNewTypeClicked] = useState(false);
    const [editTypeClicked, setEditTypeClicked] = useState(false);


    //If the player clicks the button, toggle the state
    const onNewClick = () => {
        //If the user entered in data and clicked submit
        if (newTypeClicked && newType !== "") {


            props.onNew(newType);
            setNewType("");

        }
        setNewTypeClicked(!newTypeClicked);
    };

    const onEditClick = () =>
    {
        if (editTypeClicked && newType !== "") {
            props.onEdit(newType);
            setNewType("");
        }
        setEditTypeClicked(!editTypeClicked);
    }

    const onDeleteClick = () => {
        props.onDelete();
    };

    const handleTextChange = (e) => {
        setNewType(e.target.value);
    };

    const handleDropdown = (key) => {
        setNewType(props.items[key]);
    }

    //If there are no items in the list, make it so that the user has to add a new one
    if (props.length === 0 && !newTypeClicked) setNewTypeClicked(true);
    const disabled = (newTypeClicked || editTypeClicked) ? "" : " disabled";
    const newDisabled = (!editTypeClicked) ? "" : " disabled";
    const editDisabled = (!newTypeClicked && props.onEdit) ? "" : " disabled";
    const deleteDisabled = (!newTypeClicked && !editTypeClicked) ? "" : " disabled";
    return <>
        <button className={"" + newDisabled} onClick={onNewClick}>{newTypeClicked ? "Add" : "New"}</button>
        <button className={"" + editDisabled} onClick={onEditClick}>Edit</button>
        <button className={"" + deleteDisabled} onClick={onDeleteClick}> {"Delete"}</button>
        {(props.items && <Dropdown className={"" + disabled} items={props.items} value={props.items.findIndex(x => x === newType)} onChange={handleDropdown}/>) ||
            <input className={"" + disabled} type="text" value={newType} onChange={handleTextChange} />
        }
    </>
}