import React, { useEffect, useState } from 'react';
import Loading from '../../icons/Loading';
import { DragAndDrop, Dropdown } from '../../util/Dropdown';
import { ImageFile } from '../../util/ImageFile';
import getInfoFromServer, { Copy, postInfoToServer } from '../../util/util';
import { NameText } from '../../util/NameText';
import { Button, ToggleButton } from '../../util/ToggleButton';

export function EditFont(props)
{
    const [key, setKey] = useState(-1);
    const [fonts, setFonts] = useState([]);
    const [loading, setLoading] = useState(true);

    const onToggleClick = (mode) =>
    {        
        if (mode === 'new')
        {
            const temp = Copy(fonts);
            const newFont = {
                name: 'New Font', thumbnail: '', key: fonts.length > 0 ? fonts[fonts.length - 1].key + 1 : 1
            }

            temp.push(newFont);

            setFonts(temp);
            setKey(temp.length - 1);
        }        
    }

    const onFontSelect = (key) => {
        setKey(key);
    }

    const onNameChange = (value) => {
        const temp = Copy(fonts);

        temp[key].name = value;

        setFonts(temp);
    }

    const onFontFileSelect = (value, name) => {
        const temp = Copy(fonts);

        temp[key].thumbnail = value;
        temp[key].name = name.slice(0, -4);

        setFonts(temp);
    }

    const onFontDrag = (items, newKey, newIndex) => {
        setKey(newIndex);
        setFonts(items);
    }

    const onDelete = async (e) => {
        const response = await postInfoToServer('edit/Delete/Font', { key: fonts[key].key });

        setKey(response.fonts[response.fonts.length - 1].key);
        setFonts(response.fonts);
    }

    const onSubmit = async (e) => {
        setLoading(true);
        const response = await postInfoToServer('edit/Submit/Font', { fonts: fonts, key: fonts[key].key });
        setLoading(false);
    }

    useEffect(() => {
        const initialize = async () => {
            const response = await getInfoFromServer("edit/Start/Font");

            setFonts(response.fonts);
            setLoading(false);
        }

        initialize();
    }, []);

    const font = key > -1 ? fonts[key] : {}

    if (loading) return <Loading/>

    return <div>
        <h5>Edit Font</h5>
        <ToggleButton onClick={onToggleClick}>
            <Button id="new" name="New" />
            <Button id="edit" name="Edit">
                <Dropdown value={key} items={fonts.map(x => x.name)} onChange={onFontSelect}>
                    Select Font:
                </Dropdown>
            </Button>
            {key > -1 && <>
                <ImageFile id={key} value={font.name} onChange={onFontFileSelect} accept=".otf,.ttf">
                    Select Font to start: 
                </ImageFile>
                <NameText id={key} name="Name" value={font.name} onBlur={onNameChange} />                
                <DragAndDrop label="Font layer" value={font.key} items={fonts}
                    onChange={onFontDrag} onClick={onFontSelect} />
                <button onClick={onDelete}>Delete</button>
                <button onClick={onSubmit}>Submit</button></>}
        </ToggleButton>
        
        </div>
}