import React from 'react';
import './AlertBox.css';

export default function AlertBox(props)
{
    const onYes = (e) =>
    {
        props.minimize();
        if (props.onClick)
            props.onClick(true);
    }
    const onNo = (e) =>
    {
        props.minimize();
        if (props.onClick)
            props.onClick(false);
    }

    const onClose = (e) =>
    {
        props.minimize();
    }
    const buttonsList = {
        YesNo: <><button className='button-two' onClick={onYes}>Yes</button>
            <button className="button-two" onClick={onNo}>No</button></>,
        Ok: <button className="button-two" onClick={onClose}>Ok</button>,
        none: <></>
    };
    const buttons = buttonsList[props.type];
    const error = (props.error) ? " error" : "";

    const center = (props.center) ? " center-align" : "";
    const left = (props.left) ? " left-align" : "";
    const right = (props.right) ? " right-align" : "";

    return <>
        <div className={"alert_box-container" + error + center + left + right}>
            <div className="alert-children">
                {props.children}
            </div>
            <div className="buttons-container">
                {buttons}

            </div>
        </div>

        </>
}

AlertBox.defaultProps = {
    type: "Ok",
    center: true
}