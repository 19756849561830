import React, { useState, useEffect } from "react";
import { Route, Switch } from 'react-router';
import { Dropdown } from '../../util/Dropdown';
import {
    Elements,
    CardElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import getInfoFromServer, { Copy, postInfoToServer } from "../../util/util";
import './Checkout.css';
import { Terms } from "../Terms";
import Form, { Fieldset, Field, Checkbox, Header } from "../../util/Form";
import ErrorMessage, { WarningMessage } from "../../util/ErrorMessage";

// loadStripe is initialized with your real test publishable API key.
const promiseTest = loadStripe("pk_test_51IxLLVBCZAntMxxgmZGCyQuv6wB77C0ENHlwLA54D0VQFzgHoxOWqgK1Pd7i2ylbJgYb1qPknISno18Gh9fcMe3100W6GtkmEJ");
const promiseLive = loadStripe("pk_live_51IxLLVBCZAntMxxgX980g0CObuw0O5mvUygH0GNCxxPORx3wqCqxbexW3SwIpi2S07abZMv04W3STdBiBuwo0kVl00GHbAQbsz");

export default function Checkout(props)
{
    const promise = props.isDevelopment ? promiseTest : promiseLive;

    return <div className="checkout-wrapper">
    <Elements stripe={promise}>
            <CheckoutForm amount={props.amount} onPaid={props.onPaid} succeeded={props.succeeded} />
        </Elements>
        </div>
}

function CheckoutForm(props)
{
    
    const [billingDetails, setBillingDetails] = useState({ name: "", email: "", phone: "", address: {line1: "", line2: "", city: "", state: "", postal_code: "", country: "US"} });
    const [contact, setContact] = useState(true);
    const [billing, setBilling] = useState(false);
    const [terms, setTerms] = useState(false);
    const [card, setCard] = useState(false);

    useEffect(() => {
        const initialize = async () => {
            const response = await getInfoFromServer('shoppingCart/GetShippingAddress');

            setBillingDetails({
                ...billingDetails, address: { ...response.address, postal_code: response.address.postalCode, postalCode: undefined } });
        }

        initialize();
    }, []);

    const onFieldChange = async (details) =>
    {
        setBillingDetails(details);       
    }

    const onContactNext = (e) =>
    {
        if (e)
            e.preventDefault();
        setContact(false);
        setBilling(true);
    }

    const onTermsNext = (e) => {
        e.preventDefault();
        setTerms(false);
        setCard(true);
    }

    const onBillingNext = async (e) =>
    {
        e.preventDefault();
        setBilling(false);
        setTerms(true);

        const address = billingDetails.shippingAddress ? billingDetails.shippingAddress : billingDetails.address;

        const response = await postInfoToServer('shoppingCart/PostShippingAddress', {
            line1: address.line1,
            line2: address.line2,
            city: address.city,
            state: address.state,
            postalCode: address.postal_code,
            country: address.country
        });
    }

    const onBack = (e) =>
    {
        if (billing) {
            setBilling(false);
            setContact(true);
        }
        else if (terms)
        {
            setTerms(false);
            setBilling(true);
        }
        else if (card)
        {
            setCard(false);
            setTerms(true);
        }
    }

    return <div className="checkout-wrapper">
        {!contact && <BackButton onClick={onBack} />}
        <form className="payment form" id="payment-form" onSubmit={contact ? onContactNext : billing ? onBillingNext : onTermsNext}>
           
            {contact ? <ContactField billingDetails={billingDetails} onChange={onFieldChange} /> :
                billing ? <BillingField billingDetails={billingDetails} onChange={onFieldChange} /> :
                    terms ? <TermsField /> :
                        card ? <CardField onPaid={props.onPaid} billingDetails={billingDetails} /> :
                        ("No State")}
            
            

        </form>
           
        
    </div>
}


function BackButton({ onClick })
{
    return <button className="back-button" onClick={onClick}>
        <svg width="16" height="16" viewBox="0 0 219.151 219.151">
            <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
		c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
		c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
		c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
        </svg>
    </button>
}


function ContactField({ billingDetails, onChange})
{
    const [phone, setPhone] = useState('');
    const [error, setError] = useState(null);

    const onPhoneChange = (e) => {
        let value = e.target.value;
        const length = value.length;

        const lastChar = value.slice(-1);

        //If we enter in something other than a number, don't update that 
        if (isNaN(parseInt(lastChar)) && phone.length < length) {
            value = phone;
        }

        else if (length === 1) {
            //if we are going up
            //"" -> "(1"
            if (phone.length < 1) {
                value = "(" + value;
            }
            //if we are going down
            //"(1" -> ""
            else {
                value = "";
            }
        }

        else if (length === 4) {
            //If we are going up
            //"(12" -> "(123) "
            if (phone.length < 4) {
                value += ") ";
            }
        }

        //Right after ")"
        else if (length === 5) {
            //if we are going down
            //"(123) " -> "(12"
            if (phone.length > 5) {
                value = value.substring(0, 3);
            }
        }

        //Right before "-" in "(123) 456-7890"
        else if (length === 9) {
            //If we are going up
            //"(123) 45" -> "(123) 456-
            if (phone.length < 9) {
                value += "-";
            }
            //if we are going down
            //"(123) 456-" -> "(123) 45"
            else {
                value = value.substring(0, 8);
            }

        }

        //Keeps the string at the desired length
        else if (length > 14) {
            value = value.substring(0, 14);
        }

        onChange({ ...billingDetails, phone: value });
        setPhone(value);
    }

    return <>
        <Fieldset>
            <Field id="name" type="text" placeholder="John Doe" required
                autoComplete="name" value={billingDetails.name}
                onChange={(e) => { onChange({ ...billingDetails, name: e.target.value }) }}>
                Name
                     </Field>
            <Field id="email" type="email" placeholder="johndoe@gmail.com" required
                autoComplete="email" value={billingDetails.email}
                onChange={(e) => {
                    onChange({ ...billingDetails, email: e.target.value })
                }}>
                Email
                    </Field>
            <Field id="phone" type="tel" placeholder="(123) - 456 - 7890" required
                autoComplete="tel" value={billingDetails.phone}
                onChange={onPhoneChange}>
                Phone
                    </Field>
        </Fieldset>
        {error && <ErrorMessage error red>{error}</ErrorMessage>}
        <button className="button-primary" type="submit">Next</button>
        </>
}

function BillingField(props) {
    const [useBilling, setUseBilling] = useState(true);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        const initialize = async () => {
            const response = await getInfoFromServer("shoppingCart/GetCountries");

            setCountries(response.countries);
        }

        initialize();
    }, []);

    const onUseBillingChange = (e) => {
        const checked = e.target.checked;

        setUseBilling(checked);

        const temp = Copy(props.billingDetails);

        //If the shipping and billing is the same, do not have a shippingAddress
        if (checked) {            
            temp.shippingAddress = undefined;            
        }
        //If they are different, we want a shipping address
        else {
            temp.shippingAddress = { line1: "", line2: "", city: "", state: "", postal_code: "", country: "US" };
        }

        props.onChange(temp);
    }

    const onBillingAddressChange = (address) => {
        const temp = Copy(props.billingDetails);

        temp.address = address;

        props.onChange(temp);
    }

    const onShippingAddressChange = (address) => {
        const temp = Copy(props.billingDetails);

        temp.shippingAddress = address;

        props.onChange(temp);
    }

    const useShipping = props.billingDetails.shippingAddress === undefined;
    return <>
        <AddressField address={props.billingDetails.address} countries={countries} onChange={onBillingAddressChange} isShipping={useShipping}>
            Billing Address
        </AddressField>
        <Fieldset>
            <Checkbox form checked={useShipping} onChange={onUseBillingChange}>
                Shipping same as Billing
            </Checkbox>
        </Fieldset>
        {!useShipping ? <AddressField address={props.billingDetails.shippingAddress} countries={countries} onChange={onShippingAddressChange} isShipping>Shipping Address </AddressField> : <></>}



        <button className="button-primary" type="submit">Next</button>

    </>
}
function AddressField(props) {
    const [error, setError] = useState(null);
    const [warning, setWarning] = useState(null);

    useEffect(() => {

        if (!props.isShipping) {
            setError(null);
            setWarning(null);
        }
    }, [props.isShipping]);

    //Converts the list of countries with a valid shipping price to a string with commans and such
    const mapAvailableCountries = (countries) => {

        const toMap = Object.values(countries).filter(x => !props.isShipping || x.shippingPrice >= 0);
        let myString = "";
        for (var i = 0; i < toMap.length; i++) {
            const text = toMap[i].countryName;
            let tempString = text;

            if (i < toMap.length - 2)
                tempString += ", ";
            else if (i === toMap.length - 1 && i !== 0)
                tempString = ` and ${text}`;

            myString += tempString;
        }

        return myString;
    }

    const reduceToNames = (countries) => {
        const temp = Object.entries(countries);

        temp.splice({}, 0, 0);
        return temp.reduce((items, [k, v], i) => {
            if (i > 0)
                items = { ...items, [k]: v.countryName }
            else
                items = { [k]: v.countryName }
            return items;
        })
    }


    const onCountrySelect = (value) => {

        const countryName = props.countries[value].countryName;
        const price = props.countries[value].shippingPrice;
        //if the country is unavailabe
        if (props.isShipping && price < 0) {
            setError(`Sorry, shipping to ${countryName} is not currently supported. Shipping is only supported in ${mapAvailableCountries(props.countries)} for now.`);
            return;
        }

        if (props.isShipping && price > 0) {
            setWarning(`The shipping price for ${countryName} is $${price.toFixed(2)}`);
        }
        else {
            setWarning(null);
        }
        setError(null);

        props.onChange({ ...props.address, country: value }, true)
    }

    return <>
        <Header>{props.children}</Header>
        <Fieldset>
            <Field id="address-line1" type="text" placeholder="Address 1" required
                autoComplete="address-line1" value={props.address.line1}
                onChange={(e) => { props.onChange({...props.address, line1: e.target.value  }, true) }}>
                Address 1
                </Field>
            <Field id="address-line2" type="text" placeholder="Address 2"
                autoComplete="address-line2" value={props.address.line2}
                onChange={(e) => { props.onChange({...props.address, line2: e.target.value } , true) }}>
                Address 2
                </Field>
            <Field id="address-level2" type="text" placeholder="City" required
                autoComplete="address-level2" value={props.address.city}
                onChange={(e) => { props.onChange({...props.address, city: e.target.value }, true) }}>
                City
                </Field>
            <Field id="address-level1" type="text" placeholder="State" required
                autoComplete="address-level1" value={props.address.state}
                onChange={(e) => { props.onChange({...props.address, state: e.target.value } , true) }}>
                State
                </Field>
            <Field id="postal_code" type="text" placeholder="Zip or Postal Code" required
                autoComplete="postal_code" value={props.address.postal_code}
                onChange={(e) => { props.onChange({...props.address, postal_code: e.target.value  }, true) }}>
                Postal Code
                </Field>
            <Dropdown field innerClassName="form-row-input payment" value={props.address.country}
                items={reduceToNames(props.countries)}
                onChange={onCountrySelect}>
                <label htmlFor="country" className="form-row-label">
                    Country
                </label>
            </Dropdown>

        </Fieldset>
        {error && <ErrorMessage red>{error}</ErrorMessage>}
        {warning && <WarningMessage>{warning}</WarningMessage>}
    </>
}

function TermsField(props)
{
    const [accepted, setAccepted] = useState(false);

    const onCheck = (e) => {
        setAccepted(e.target.checked);
    }

    return <>
        <div className="form-terms">
            <Terms/>
        </div>
        <Fieldset>
            <Checkbox checked={accepted} onChange={onCheck}>
                Accept Terms of Service
            </Checkbox>            
        </Fieldset>
        <button className="button-primary" type="submit" disabled={!accepted}> Next</button>
        </>
}

const cardStyle = {
    style: {
        base: {
            color: "#808080",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#567c7a"
            }
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }
};
function CardField(props) {
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [succeeded, setSucceeded] = useState(props.succeeded);
    const [clientSecret, setClientSecret] = useState('');
    const stripe = useStripe();
    const elements = useElements();

    const createStripe = () => {
        const address = props.billingDetails.shippingAddress ? props.billingDetails.shippingAddress : props.billingDetails.address;

        // Create PaymentIntent as soon as the page loads
        return getInfoFromServer("create-payment-intent", {
            receiptEmail: props.billingDetails.email,
            name: props.billingDetails.name,
            phone: props.billingDetails.phone,
            line1: address.line1,
            line2: address.line2,
            city: address.city,
            state: address.state,
            postalCode: address.postal_code,
            country: address.country
        })
            .then(res => {
                return res;
            })
            .then(data => {
                setClientSecret(data.clientSecret);
                return data.clientSecret;
            });
    }

    const setFontSize = (e) =>
    {
        if (window.innerWidth <= 575)
        {
            elements.getElement(CardElement).update({
                style: {
                    base:
                    {
                        fontSize: "14px"
                    }
                }
            });
        }

        
    }

    useEffect(() =>
    {
        createStripe();
        setFontSize();
        window.addEventListener('resize', setFontSize);

        return function unmount() {
            window.removeEventListener('resize', setFontSize);
        }
    }, []);

    const onChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    const onSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);

        const details = props.billingDetails;
        details.shippingAddress = undefined;

        const repsonse = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: details
            }

        });
        if (repsonse.error) {
            setError(`Payment failed ${repsonse.error.message}`);
            setProcessing(false);
        } else {
            setError(null);
            //setProcessing(false);
            setSucceeded(true);
            props.onPaid(repsonse.paymentIntent.status === "succeeded");
        }
    };

    

    return <>
        <Fieldset>
            <div field>
                <CardElement field id="card-element" options={cardStyle} onChange={onChange} />
            </div>
        </Fieldset>
        {error && <ErrorMessage red>{error}</ErrorMessage>}

        <SubmitPayment processing={processing} succeeded={succeeded} disabled={disabled} onPaid={onSubmit} />
    </>
}

function SubmitPayment({ processing, disabled, succeeded, onPaid })
{
    return <>
        <button className="button-primary"
            disabled={processing || disabled || succeeded}
            id="submit"
            onClick={onPaid}
        >
            <span id="button-text">
                {processing ? (
                    <div className="spinner" id="spinner"></div>
                ) : (
                        "Pay now"
                    )}
            </span>
        </button>
        </>
}