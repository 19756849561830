import React, { useEffect } from 'react'
import { useState } from 'react';
import { Input } from '../util/Dropdown'
import { Home } from './Home';
import getInfoFromServer, { postInfoToServer } from '../util/util';
import { RenderAdmin } from '../page_layout/NavMenu';

export default function Admin(props)
{
    useEffect(() => {

        const Authenticate = async () => {
            const response = await getInfoFromServer('IsAdmin');

            RenderAdmin(response.isAdmin)            
        }

        Authenticate();
    }, []);

    

    return <>
        <Home/>
        </>
}

function AdminPopup(props)
{
    const [password, setPassword] = useState('');

    const onPasswordBlur = (value) => {
        setPassword(value);
    }

    const onSubmit = async (e) => {
        const response = await postInfoToServer('admin/' + password);

        if (response.key == "pickle") {
            RenderAdmin();
        }
    }

    return <div className="default">
        <Input onBlur={onPasswordBlur}>
            Password: 
        </Input>
        <button onClick={onSubmit}>Submit</button>
        </div>
}