import React, { useEffect, useState } from 'react';
import Loading from '../../icons/Loading';
import { ConfirmButton } from '../../util/ConfirmButton';
import { Dropdown, DropdownButton, DropdownDropdown, Input } from '../../util/Dropdown';
import getInfoFromServer, { Copy, postInfoToServer } from '../../util/util';
import { NameText } from '../../util/NameText';
import { Button, ToggleButton } from '../../util/ToggleButton';

export function EditTextBox(props) {
    const [items, setItems] = useState([]);
    const [index, setIndex] = useState(-1);
    const [colorIndex, setColorIndex] = useState(-1);
    const [fonts, setFonts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const initialize = async () => {
            const response = await getInfoFromServer('edit/Start/Text');

            setItems(response.items);
            setFonts(response.fonts);
            setLoading(false);
        }

        initialize();
    }, []);

    const onToggleClick = (mode) => {
        if (mode === 'new') {
            const temp = Copy(items);

            const newItem = {
                id: temp[temp.length - 1].id + 1, name: "New Text Item", fontKeys: [1], fontColors: ['Black'],
                maxFontSize: 0, maxNumLines: 0
            }

            temp.push(newItem);

            setItems(temp);
            setIndex(temp.length - 1);
        }
    }

    const onSelect = (value) => {
        setIndex(value);
    }

    const onNameChange = (value) => {
        const temp = Copy(items);
        temp[index].name = value;

        setItems(temp);
    }

    const onNewFont = (value) => {
        const temp = Copy(items);

        const key = fonts.find(x => x.name === value).key;
        temp[index].fontKeys.push(key);

        setItems(temp);
    }

    const onDeleteFont = (value) => {
        const temp = Copy(items);

        const key = fonts.find(x => x.name === value).key;
        temp[index].fontKeys.splice(temp[index].fontKeys.findIndex(x => x === key), 1);

        setItems(temp);
    }

    const onFontColorChange = (i, _items) => {

        if (_items) {
            const temp = Copy(items);
            temp[index].fontColors = _items;

            setItems(temp);
        }

        setColorIndex(i);
    }

    const onFontSizeChange = (value) => {
        const temp = Copy(items);
        temp[index].maxFontSize = value;

        setItems(temp);
    }

    const onNumLinesChange = (value) => {
        const temp = Copy(items);
        temp[index].maxNumLines = value;

        setItems(temp);
    }

    const onDefaultTextChange = (value) => {
        const temp = Copy(items);
        temp[index].defaultText = value;

        setItems(temp);
    }

    const onDelete = async () => {
        setLoading(true);
        const response = await postInfoToServer('edit/Delete/Text', { id: curr.id });

        setIndex(-1);
        setItems(response.items);
        setLoading(false);
    }

    const onSubmit = async () => {
        setLoading(true);
        await postInfoToServer('edit/Submit/Text', { item: curr });
        setLoading(false);
    }

    const curr = index > -1 ? items[index] : {};
    const fontKeys = index > -1 ? curr.fontKeys.map(x => fonts.find(y => y.key === x).name) : [];

    if (loading) return <Loading/>

    return <div>
        <h5>Edit Text Boxes</h5>
        <ToggleButton onClick={onToggleClick}>
            <Button id='new' name="New" />
            {items.length > 0 && <Button id='edit' name="Edit">
                <Dropdown value={index} items={items.map(x => x.name)} onChange={onSelect}>
                    Select Text Box:
                </Dropdown>
            </Button>}
            {index > -1 && <div>
                <NameText name="Name" value={curr.name} onBlur={onNameChange} />
                <DropdownDropdown items={fontKeys} allItems={fonts.map(x => x.name)} onNew={onNewFont} onDelete={onDeleteFont}>
                    Add Font:
                </DropdownDropdown>
                <DropdownButton value={colorIndex} items={curr.fontColors} onChange={onFontColorChange}>
                    Font Colors:
                </DropdownButton>
                <Input.div type="number" from={0} value={curr.maxFontSize} onBlur={onFontSizeChange}>
                    Max Font Size:
                </Input.div>
                <Input.div type="number" from={0} value={curr.maxNumLines} onBlur={onNumLinesChange}>
                    Max Number of Lines:
                </Input.div>
                <Input.div value={curr.defaultText} onBlur={onDefaultTextChange}>
                    Default Text:
                </Input.div>
                <ConfirmButton onConfirm={onDelete} msg="Are you sure you want to delete this text box?">Delete</ConfirmButton>
                <ConfirmButton onConfirm={onSubmit} msg="Are you sure you want to submit this text box?">Submit</ConfirmButton>
            </div>}
        </ToggleButton>
    </div>
}