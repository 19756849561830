import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './Loading.css';
import { css } from "@emotion/react";
import BarLoader from "react-spinners/BarLoader";
import { Logo } from './Logo';

export default function Loading(props) {
    const override = css`
        display: block;
        margin: 0 auto;
        width: 50%;
    `;

    return <div className='loading-container'>
        <Logo />
        {props.message !== null && props.message !== "" && <div className="loading-message">{props.message}</div>}
        {props.status === undefined ? <BarLoader color='#37C2E1' css={override} /> :
            <ProgressBar className="progress-bar-override" variant="bar_color" now={props.status} />}
    </div>
}